import { call, put, takeLatest } from 'redux-saga/effects';
import { queryRequest } from '../../utils/request';
// import { push } from 'react-router-redux';
import { getTransactionUnit, getTransactionUnitById } from '../../api';
import { resetNotification, setNotification } from '../Notification/actions';
import { NotificationTypes } from '../../constantsApp';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './constants';
import * as actions from './actions';

export function* getTransactionSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, getTransactionUnit({ page, size, filter, sort }));
    yield put(actions.getTransactionUnitSuccess(response.searchTransactionUnits));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchTransactionUnits });
  } catch (err) {
    yield put(isEndConnected());
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `Search Transaction Unit failed ${err}`,
      })
    );
    if (cb) cb({ success: false, data: null });
  }
}

export function* getTransactionUnitByIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getTransactionUnitById(payload));
    if (response.getTransactionUnitById) {
      if (cb) cb({ success: true, data: response.getTransactionUnitById });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* viewTransactionsPageSaga() {
  yield takeLatest(types.GET_TRANSACTION_UNIT, getTransactionSaga);
  yield takeLatest(types.GET_TRANSACTION_UNIT_BY_ID, getTransactionUnitByIdSaga);
}
