import { fromJS } from 'immutable';
import * as types from './constants';

export const initialState = fromJS({
  isFetchingSuccess: false,
  transactionUnits: [],
  paramsTransaction: {
    page: 1,
    size: 10,
    filter: {},
    sort: {},
  },
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_TRANSACTION_UNIT_SUCCESS:
      return state
        .set('transactionUnits', action.payload)
        .set('isFetchingSuccess', true);

    case types.SET_TRANSACTION_PARAMS: {
      const { page, size } = action.payload;
      return state
        .setIn(['paramsTransaction', 'size'], size)
        .setIn(['paramsTransaction', 'page'], page);
    }
    default:
      return state;
  }
}
