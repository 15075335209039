import { createSelector } from 'reselect';

const selectInvoicesReducer = state => state.get('invoiceReducer');

const makeErrorMessage = () =>
  createSelector(selectInvoicesReducer, invoices => (invoices ? invoices.get('errorMessage') : null));

const makeGetInvoiceTemplate = () =>
  createSelector(selectInvoicesReducer, invoices =>(invoices ? invoices.get('invoiceTemplates') : null));

export { makeErrorMessage, makeGetInvoiceTemplate };
