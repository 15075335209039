// export const loadState = () => {
//   try {
//     const serializedState = localStorage.getItem('persistState');
//     if (serializedState === null) {
//       return {};
//     }
//     return JSON.parse(serializedState);
//   } catch (err) {
//     return undefined;
//   }
// };
// const whiteList = ['token', 'sidebar'];
// export const saveState = state => {
//   try {
//     const dupState = {};
//     whiteList.map(key => (dupState[key] = state[key]));
//     const serializedState = JSON.stringify(dupState);
//     localStorage.setItem('persistState', serializedState);
//   } catch (err) {
//     console.log('saveState error', err);
//   }
// };

// export function getAccountId() {
//   // const result = decrypt(localStorage.getItem('accountId') ? localStorage.getItem('accountId') : '');
//   const result = localStorage.getItem('accountId') ? localStorage.getItem('accountId') : '';
//   return result;
// }
// export function setAccountId(accountId) {
//   localStorage.setItem('accountId', accountId);
// }

// export function getId() {
//   return localStorage.getItem('id') ? localStorage.getItem('id') : '';
// }
// export function setId(id) {
//   localStorage.setItem('id', id);
// }

// export function getUserName() {
//   const result = localStorage.getItem('userName') ? localStorage.getItem('userName') : '';
//   return result;
// }
// export function setUserName(userName) {
//   localStorage.setItem('userName', userName);
// }

// export function getPassword() {
//   const result = localStorage.getItem('password') ? localStorage.getItem('password') : '';
//   return result;
// }
// export function setPassword(password) {
//   localStorage.setItem('password', password);
// }

// export function getFirstTimeLogin() {
//   return localStorage.getItem('isFirstTimeLogin') ? localStorage.getItem('isFirstTimeLogin') : '';
// }
// export function setFirstTimeLogin(isFirstTimeLogin) {
//   localStorage.setItem('isFirstTimeLogin', isFirstTimeLogin);
// }

// export function getLogoBase64() {
//   return localStorage.getItem('lgB64') || '';
// }

// export function setLogoBase64(logo) {
//   localStorage.setItem('lgB64', logo);
// }

// export function getRounding() {
//   return localStorage.getItem('rounding') || '';
// }

// export function setRounding(rounding) {
//   localStorage.setItem('rounding', rounding);
// }

import CryptoJS from 'crypto-js';

const secret = 'embrix_sEcreT_2o24';

const decrypt = value => {
  try {
    const bytes = CryptoJS.AES.decrypt(`${value}`, secret);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
};

const encrypt = value => {
  try {
    return CryptoJS.AES.encrypt(`${value}`, secret).toString();
  } catch (error) {
    return null;
  }
};

const hash = value => CryptoJS.SHA3(value).toString();

const listLocalKeys = {
  accountId: hash('accountId'),
  id: hash('id'),
  userName: hash('userName'),
  password: hash('password'),
  isFirstTimeLogin: hash('isFirstTimeLogin'),
  lgB64: hash('lgB64'),
  rounding: hash('rounding'),
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('persistState');
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
const whiteList = ['token', 'sidebar'];
export const saveState = state => {
  try {
    const dupState = {};
    whiteList.map(key => (dupState[key] = state[key]));
    const serializedState = JSON.stringify(dupState);
    localStorage.setItem('persistState', serializedState);
  } catch (err) {
    console.log('saveState error', err);
  }
};

export function getAccountId() {
  const result = decrypt(
    localStorage.getItem(listLocalKeys.accountId) ? localStorage.getItem(listLocalKeys.accountId) : ''
  );
  return result;
}
export function setAccountId(accountId) {
  localStorage.setItem(listLocalKeys.accountId, encrypt(accountId));
}

export function getId() {
  return decrypt(localStorage.getItem(listLocalKeys.id) ? localStorage.getItem(listLocalKeys.id) : '');
}
export function setId(id) {
  localStorage.setItem(listLocalKeys.id, encrypt(id));
}

export function getUserName() {
  return decrypt(localStorage.getItem(listLocalKeys.userName) ? localStorage.getItem(listLocalKeys.userName) : '');
}

export function setUserName(userName) {
  localStorage.setItem(listLocalKeys.userName, encrypt(userName));
}

export function getPassword() {
  return decrypt(localStorage.getItem(listLocalKeys.password) ? localStorage.getItem(listLocalKeys.password) : '');
}
export function setPassword(password) {
  localStorage.setItem(listLocalKeys.password, encrypt(password));
}

export function getFirstTimeLogin() {
  return localStorage.getItem(listLocalKeys.isFirstTimeLogin)
    ? localStorage.getItem(listLocalKeys.isFirstTimeLogin)
    : '';
}
export function setFirstTimeLogin(isFirstTimeLogin) {
  localStorage.setItem(listLocalKeys.isFirstTimeLogin, isFirstTimeLogin);
}

export function getLogoBase64() {
  return localStorage.getItem(listLocalKeys.lgB64) || '';
}

export function setLogoBase64(logo) {
  localStorage.setItem(listLocalKeys.lgB64, logo);
}

export function getRounding() {
  return localStorage.getItem(listLocalKeys.rounding) || '';
}

export function setRounding(rounding) {
  localStorage.setItem(listLocalKeys.rounding, rounding);
}
