import gql from 'graphql-tag';
export default ({ page }) => {
  const searchPackages = `searchPackages(page: ${page}, size: 9, filter:{ status: SELLABLE })`;
  return gql`
    query {
      ${searchPackages} {
        id
        name
        description
    }
  }
  `;
};
