/*
 *
 * ViewInvoices constants
 *
 */
export const GET_ACCOUNT_STATEMENT = 'app/customers/GET_ACCOUNT_STATEMENT';
export const GET_AR_OPS_UNIT_SUCCESS = 'app/customers/GET_AR_OPS_UNIT_SUCCESS';
export const SET_AR_OPS_PARAMS = 'app/customers/SET_AR_OPS_PARAMS';

export const GET_ALL_ACCOUNT_STATEMENT = 'app/customers/GET_ALL_ACCOUNT_STATEMENT';
export const GET_ALL_ACCOUNT_STATEMENT_SUCCESS = 'app/customers/GET_ALL_ACCOUNT_STATEMENT_SUCCESS';

export const GET_CLIENT_ACCOUNT_STATEMENT = 'app/customers/GET_CLIENT_ACCOUNT_STATEMENT';

export const GET_ALL_CLIENT_ACCOUNT_STATEMENT = 'app/customers/GET_ALL_CLIENT_ACCOUNT_STATEMENT';
