import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MainHeader } from '../../components/Header';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { PendingBills, OCBillsTable } from '../../components/ViewInvoices';
import { getAccountId } from '../../localStorage';
import { checkPermissionViewBills, checkPermissionViewInvoice } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare, makeGetAccountInfo, makeGetCcpProperty } from '../App/selectors';
import { getObjectFileById, getAccountById } from '../App/actions';
import { TablePagination, AccountHeaderForm, SwitchExport, ButtonExport, ExcelExport } from '../../components/common';
import { makeGetPendingBills, makeGetOCBills } from './selectors';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';
import { getPageTotalCount } from '../../utils/utils';
import { getPendingBill, getOCBill } from './actions';
// import convertJson2Sheet from '../../utils/ExcelHelper/exportExcelAccountStatement';
// import convertJson2Pdf from '../../utils/PdfHelper/exportPdfAccountStatement';
/* eslint-disable react/prefer-stateless-function */

export class ViewInvoicesPage extends React.Component {
  constructor(props) {
    super(props);
    this.filterPendingBills = {
      accountId: getAccountId() || '',
      status: 'PENDING',
      billingProfileId: 'PRIMARY',
    };
    this.filterOCBills = {
      accountId: getAccountId() || '',
      fetchFinalizedInvoice: 'true',
      billingProfileId: 'PRIMARY',
    };
    this.state = {
      accountDetails: {},
      page: 0,
      size: 20,
      totalCount: null,
    };
  }

  componentDidMount() {
    const { getAccountById, getPendingBill } = this.props;
    // getPendingBill({
    //   page: 1,
    //   size: 1000000,
    //   filter: this.filterPendingBills,
    // });
    this.doGetOCBill();
    getAccountById({ id: getAccountId() }, ({ success, data }) => {
      if (success) {
        this.setState({
          accountDetails: data && data ? data : {},
        });
      }
    });
  }

  doGetOCBill = () => {
    const { getOCBill } = this.props;
    const { page, size, totalCount } = this.state;
    getOCBill(
      {
        page: page + 1,
        size,
        filter: this.filterOCBills,
      },
      ({ data }) => {
        if (data)
          this.setState({
            isActiveNext:
              data && data.billInvoiceData && !!(data && data.billInvoiceData && data.billInvoiceData.length === size),
            totalCount: getPageTotalCount({
              totalCount: totalCount || data?.billInvoiceData?.length,
              size,
              items: data?.billInvoiceData || [],
              page,
            }),
          });
      }
    );
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetOCBill());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetOCBill());
  };

  render() {
    const {
      pendingBills,
      ocBills,
      permissionsSelfCare,
      t,
      getObjectFileById,
      history,
      accountIfo,
      listAllAccountStatement,
      ccpProperty,
    } = this.props;
    const { accountDetails, page, isActiveNext, size, totalCount, isActivePdf } = this.state;
    let modeViewBills = 0;
    let modeViewInvoice = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewBills = checkPermissionViewBills({
        listPermission,
      });
      modeViewInvoice = checkPermissionViewInvoice({
        listPermission,
      });
    }
    let historicalFolioLink = null;
    if (ccpProperty && ccpProperty.ccpPropertyList && ccpProperty.ccpPropertyList.length) {
      const historicalFolioLinkProperty = ccpProperty.ccpPropertyList.find(
        val => val.property === 'historicalFolioLink'
      );
      if (historicalFolioLinkProperty) historicalFolioLink = historicalFolioLinkProperty.value;
    }

    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="Billing Data" />
        {modeViewBills !== 0 && (
          <div className="manage-page__form">
            <div className="form-wrapper table-content">
              <div className="table-title table-title-form table-title-form-detail">
                <Row>
                  <Col md={12}>
                    {/* <h4 className="card-title-mb">
                      {`${t('label.invoiceDetails')} | ${t('label.accountNumber')}`}
                      <span className="account-number">
                        <span className="account-detail">{`${t('label.accountNumber')} :`}</span>
                        &nbsp;&nbsp;
                        <span className="account-detail">{getAccountId()}</span>
                      </span>
                    </h4> */}
                    <AccountHeaderForm title={t('label.invoiceDetails')} historicalFolioLink={historicalFolioLink} />
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  {/* {accountIfo && accountIfo.type !== 'CLIENT' && (
                    <div className="card-body" style={{ paddingLeft: 0 }}>
                      <div className="card-header" id="heading1">
                        <h5 className="mb-0 ml-2 header-table">{t('label.pendingBills')}</h5>
                      </div>

                      <Col md={12}>
                        <PendingBills data={pendingBills} />
                      </Col>
                    </div>
                  )} */}
                  <div className="card-body" style={{ paddingLeft: 0 }}>
                    <div className="card-header" id="heading1">
                      <h5 className="mb-0 ml-2 header-table">{t('label.openCloseBills')}</h5>
                    </div>
                    {/* <div className="d-flex float-right">
                      <div className="ml-auto mt-2 mb-auto mr-3">
                        <SwitchExport
                          onChange={this.onChangeSwitch}
                          wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                          title={t('label.excel')}
                          checked={isActivePdf}
                          rightTitle={t('label.pdf')}
                        />
                      </div>
                      <>
                        <ButtonExport onExport={this.onExport} />
                        <ExcelExport
                          element={<button type="button" className="display-none" ref={this.buttonRef} />}
                          nameSheet={t('label.accountStatement').toLocaleUpperCase()}
                          multiDataSet={convertJson2Sheet({
                            data: listAllAccountStatement || [],
                            t,
                            title: t('label.accountStatement').toLocaleUpperCase(),
                          })}
                          fileName={`${t('label.accountStatement')
                            .toLocaleLowerCase()
                            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                        />
                      </>
                    </div> */}
                    <OCBillsTable
                      data={ocBills}
                      modeViewInvoice={modeViewInvoice}
                      getObjectFileById={getObjectFileById}
                      history={history}
                      currency={accountDetails ? accountDetails.currency : ''}
                    />
                    <div className="col-md-12 pl-0 pr-0 pt-3">
                      {/* <Pagination
                        page={page}
                        isActivePre={page !== 1}
                        isActiveNext={isActiveNext}
                        handlePage={this.onPageChange}
                      />
                      <Sizing handleSize={this.onSizeChange} size={size} /> */}
                      <div className="mb-30">
                        <TablePagination
                          pageNumber={page}
                          pageSize={size}
                          totalCount={totalCount}
                          onPageChange={this.onPageChange}
                          onSizeChange={this.onSizeChange}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ViewInvoicesPage.propTypes = {
  history: PropTypes.object,
  pendingBills: PropTypes.array,
  getPendingBill: PropTypes.func,
  getOCBill: PropTypes.func,
  ocBills: PropTypes.object,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  pendingBills: makeGetPendingBills() || [],
  ocBills: makeGetOCBills() || [],
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
  accountIfo: makeGetAccountInfo() || {},
  ccpProperty: makeGetCcpProperty() || {},
});

const withConnect = connect(mapStateToProps, {
  getPendingBill,
  getOCBill,
  getObjectFileById,
  getAccountById,
});

const withReducer = injectReducer({ key: 'viewInvoicesPageReudcer', reducer });
const withSaga = injectSaga({ key: 'viewInvoicesPageSaga', saga });

export default withTranslation('common')(compose(withReducer, withSaga, withConnect)(ViewInvoicesPage));
