import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { MainHeader } from '../../components/Header';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { MakePayment } from '../../components/MakePayment';
import { getAccountId, getUserName } from '../../localStorage';
import { checkPermissionApplyPayment } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare, makeGetAccountInfo } from '../App/selectors';
import { FormHeader } from '../../components/form';
import makeSelectMakePaymentPage from './selectors';
import { GenericInput } from '../../components/common';
import reducer from './reducer';
import saga from './saga';
import { getAccountById, manageAccount, selectInvoiceId, applyPayment } from '../App/actions';
import './styles.scss';

let totalAmount = 0;

const invoiceTableColumns = [
  {
    name: 'id',
    label: 'label.invoiceId',
  },
  {
    name: 'invoiceDate',
    label: 'label.invoiceDate',
  },
  {
    name: 'dueDate',
    label: 'label.dueDate',
  },
  {
    name: 'total',
    label: 'label.invoiceAmount',
  },
  {
    name: 'due',
    label: 'label.due',
  },
];

export class MakePaymentPage extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      makePayment: {},
      creditCards: [{}],
      invoiceData: [],
    };
  }

  componentDidMount() {
    this.doGetAccountDetails();
    const { accountDetails } = this.props;
    if (accountDetails && accountDetails.id) {
      this.doGetSelectInvoiceId();
    }
  }

  doGetSelectInvoiceId = () => {
    const { selectInvoiceId } = this.props;
    selectInvoiceId(
      { page: 1, size: 200, filter: { status: 'ACTIVE', due: 0, accountId: getAccountId() }, sort: 'dueDate_DESC' },
      ({ success, data }) => {
        if (success) this.setState({ invoiceData: success ? data || [] : [] });
      }
    );
  };

  doGetAccountDetails = () => {
    const { accountDetails, getAccountById } = this.props;
    if (!accountDetails || !accountDetails.id || accountDetails.id !== getAccountId()) {
      getAccountById(
        {
          id: getAccountId(),
        },
        ({ success, data }) => {
          if (
            success &&
            data &&
            data.paymentProfiles &&
            data.paymentProfiles[0] &&
            data.paymentProfiles[0].creditCards
          ) {
            this.setState({ creditCards: data.paymentProfiles[0].creditCards });
          }
        }
      );
    } else {
      this.setState({
        creditCards: accountDetails?.paymentProfiles[0]?.creditCards
          ? accountDetails.paymentProfiles[0].creditCards
          : [{}],
      });
    }
  };

  handleAddCreditCardFromURL = payload => {
    this.props.manageAccount(payload, success => {
      if (success) {
        this.props.history.push('/make-payment');
        this.doGetAccountDetails();
      }
    });
  };

  onChangeValueForm = (key, value) => {
    const { makePayment } = this.state;
    makePayment[key] = value;
    this.setState({
      ...makePayment,
    });
  };

  onClickCheckBox = (e, item) => {
    try {
      const { invoiceData } = this.state;
      const newData = cloneDeep(invoiceData);
      const indexData = newData.findIndex(inv => inv.id === item.id);
      newData[indexData].isSelcted = !item.isSelcted;
      this.setState({ invoiceData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onPay = () => {
    const { invoiceData, creditCards } = this.state;
    const { applyPayment } = this.props;
    const allocationData = invoiceData
      .filter(inv => inv.isSelcted)
      .map(val => {
        const { id, due, accountId } = val;
        return { invoiceUnitId: id, amount: due, accountId };
      });

    const payload = {
      accountId: getAccountId() || null,
      paymentCreditCards: [{}],
      source: 'AGENT_CARE',
      userId: getUserName() || 'UserX002',
      paymentDate: moment().format('YYYY-MM-DD'),
      method: 'CREDIT_CARD',
      allocationData,
      amount: totalAmount,
    };
    if (creditCards && creditCards.length) {
      payload.paymentCreditCards = [
        {
          cardToken: creditCards[0].cardToken,
          cardExpiry: creditCards[0].cardExpiry,
          last4CC: creditCards[0].last4CC,
          merchant: creditCards[0].merchant,
        },
      ];
    }
    applyPayment(payload, ({ success }) => {
      if (success) {
        this.doGetSelectInvoiceId();
      }
    });
  };

  renderContent() {
    const { invoiceData, creditCards } = this.state;
    const { permissionsSelfCare } = this.props;
    let modeApplyPayment = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeApplyPayment = checkPermissionApplyPayment({ listPermission });
    }

    totalAmount = 0;
    if (invoiceData && invoiceData.length) {
      invoiceData.forEach(i => {
        const { isSelcted, due } = i;
        if (isSelcted && due) totalAmount += due;
      });
    }

    const newInvoiceTableColumns = [
      {
        name: 'action',
        label: 'label.select',
        render: (colName, item) => (
          <GenericInput
            value={!!item.isSelcted}
            name="action"
            type="checkbox"
            onChange={e => {
              this.onClickCheckBox(e, item);
            }}
            wrapperClass="col-md-12"
            readOnly
          />
        ),
      },
      ...invoiceTableColumns,
    ];

    return (
      <div className="payment">
        <FormHeader
          title="One-Off payment"
          detail={{ name: 'Account Number:', value: getAccountId() }}
          className="large-font-size"
        />
        <FormHeader title="Credit Card Payment" className="large-font-size" />
        <MakePayment
          history={this.props.history}
          data={creditCards}
          modeApplyPayment={modeApplyPayment}
          handleAddCreditCardFromURL={this.handleAddCreditCardFromURL}
          invoiceData={invoiceData}
          invoiceTableColumns={newInvoiceTableColumns}
          // onClickRow={this.onClickCheckBox}
          onPay={this.onPay}
          totalAmount={totalAmount}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="make-payment-page">
        <MainHeader activeTab="MakePayment" />
        <div className="make-payment-page__form">
          <div className="form-payment">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

MakePaymentPage.propTypes = {
  history: PropTypes.object,
  manageAccount: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  makePayment: makeSelectMakePaymentPage(),
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
  accountDetails: makeGetAccountInfo() || {},
});

const withConnect = connect(mapStateToProps, { getAccountById, manageAccount, selectInvoiceId, applyPayment });

const withReducer = injectReducer({ key: 'makePaymentReducer', reducer });
const withSaga = injectSaga({ key: 'makePaymentSaga', saga });

export default compose(withReducer, withSaga, withConnect)(MakePaymentPage);
