import gql from 'graphql-tag';

export default id => gql`
  query {
    getActivityById(input: { id: "${id}" }) {
      id
      accountId
      userId
      entityType
      entityId
      action
      source
      request
      response
      beforeData {
        activityAccountInfo {
          id
          customerSegment
          type
          subType
          salesChannel
          marketSegment
          sellingCompany
          lineOfBusiness
          legalEntity
          currency
          status
          reason
          effectiveDate
          userId
          parentId
          creditProfileIndex
        }
        activityContacts {
          id
          roles
          salutation
          firstName
          middleName
          lastName
          position
          organization
          email
          createdDate
          phones {
            type
            number
          }
        }
        activityAddresses {
          id
          roles
          street
          extraLine
          landmark
          city
          state
          country
          postalCode
          code
          createdDate
        }
        activityBillingProfiles {
          id
          parentId
          billingDom
          billingSegment
          billingFrequency
          invoiceType
          invoiceDelivery
          paymentProfileId
          status
          reason
          nextAccountingDate
          nextBillDate
          lastAccountingDate
          lastBillDate
          nextBillUnitId
          lastBillUnitId
          accountId
        }
        activityPaymentProfiles {
          id
          paymentTerm
          paymentMethod
          creditCards {
            cardToken
            cardExpiry
            last4CC
          }
          electronicChecks {
            routingNumber
            accountNumber
            accountName
            accountType
            bank
            contactNumber
          }
        }
        
        activityCustomAttributes {
          name
          value
        }
      }
      afterData {
            activityAccountInfo {
          id
          customerSegment
          type
          subType
          salesChannel
          marketSegment
          sellingCompany
          lineOfBusiness
          legalEntity
          currency
          status
          reason
          effectiveDate
          userId
          parentId
          creditProfileIndex
        }
        activityContacts {
          id
          roles
          salutation
          firstName
          middleName
          lastName
          position
          organization
          email
          createdDate
          phones {
            type
            number
          }
        }
        activityAddresses {
          id
          roles
          street
          extraLine
          landmark
          city
          state
          country
          postalCode
          code
          createdDate
        }
        activityBillingProfiles {
          id
          parentId
          billingDom
          billingSegment
          billingFrequency
          invoiceType
          invoiceDelivery
          paymentProfileId
          status
          reason
          nextAccountingDate
          nextBillDate
          lastAccountingDate
          lastBillDate
          nextBillUnitId
          lastBillUnitId
          accountId
        }
        activityPaymentProfiles {
          id
          paymentTerm
          paymentMethod
          creditCards {
            cardToken
            cardExpiry
            last4CC
          }
          electronicChecks {
            routingNumber
            accountNumber
            accountName
            accountType
            bank
            contactNumber
          }
        }
        
        activityCustomAttributes {
          name
          value
        }
      }
      activityDate
    }
  }
`;
