/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ButtonToolbar, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import ButtonCustom from '../ButtonCustom';
import dataSelect from '../../../constantsApp/dataSelect';

const listFieldsSearchForm = [
  {
    placeholder: 'Invoice Date',
    label: 'label.invoiceDate',
    name: 'invoiceDate',
    type: 'date',
  },
  {
    placeholder: 'label.dueDate',
    label: 'label.dueDate',
    name: 'dueDate',
    type: 'date',
  },
  {
    placeholder: 'Due',
    label: 'label.due',
    name: 'due',
  },
  {
    placeholder: 'Type',
    label: 'label.type',
    name: 'type',
    type: 'select',
    tOptions: 'selections:source',
  },
  {
    placeholder: 'Status',
    label: 'label.status',
    name: 'status',
    type: 'select',
    tOptions: 'selections:invoiceStatus',
  },
];

export class InvoiceSearchForm extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      dataSearch: {
        startDate: null,
      },
      isOpenInvoiceDate: false,
      isOpenDueDate: false,
    };
  }

  onHandleSearch = evt => {
    evt.preventDefault();
    const { invoiceDate, dueDate, due, type, status } = this.state.dataSearch;
    const filter = {
      invoiceDate: invoiceDate ? moment(invoiceDate).format('YYYY-MM-DD') : null,
      due: due || null,
      dueDate: dueDate ? moment(dueDate).format('YYYY-MM-DD') : null,
      type: type || null,
      status: status || null,
    };
    this.props.onSubmit(filter);
  };

  onChangeValueForm = (key, value) => {
    const { dataSearch } = this.state;
    dataSearch[key] = value;
    this.setState({
      ...dataSearch,
    });
  };

  onChangeDate = (key, date) => {
    const { dataSearch } = this.state;
    const newData = cloneDeep(dataSearch);
    newData[key] = date;
    this.setState({
      dataSearch: newData,
    });
  };

  onHandleClear = () => {
    this.setState({
      dataSearch: {
        startDate: null,
      },
    });
  };

  render() {
    const { isSearching, t } = this.props;
    const { dataSearch } = this.state;
    return (
      <div className="">
        <form className="form" onSubmit={evt => this.onHandleSearch(evt)}>
          <div className="col-md-12 p-0 row">
            {listFieldsSearchForm.map(item => {
              if (item.type === 'select') {
                return (
                  <div className="col-md-3 mt-3">
                    <Label for="">{t(item.label)}</Label>
                    <Select
                      value={(item.options && item.options.find(el => el.value === dataSearch[item.name])) || null}
                      onChange={val => this.onChangeValueForm(item.name, val ? val.value : '')}
                      className="form__form-group-select"
                      isDisabled={false}
                      isClearable
                      options={t(item.tOptions)()}
                      {...item}
                      placeholder={t(item.label)}
                    />
                  </div>
                );
              }
              if (item.type === 'date') {
                return (
                  <div className="col-md-3 mt-3">
                    <Label for="">{t(item.label)}</Label>
                    <div className="d-flex">
                      <div className="date-picker" onClick={() => this.setState({ [item.name]: true })}>
                        <DatePicker
                          className="form__form-group-datepicker"
                          popperPlacement="bottom-start"
                          popperModifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                            },
                          }}
                          open={this.state[item.name]}
                          onClickOutside={() => this.setState({ [item.name]: false })}
                          selected={dataSearch[item.name]}
                          onChange={date => this.onChangeDate(item.name, date)}
                          dateFormat="yyyy-mm-dd"
                          placeholderText={t(item.label)}
                          autoComplete="off"
                          isClearable
                          {...item}
                        />
                      </div>
                      <div
                        className="form__form-group-icon"
                        onClick={() => {
                          if (!this.state[item.name]) this.setState({ [item.name]: true });
                        }}
                      >
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div className=" mt-3 col-md-3 ml-0">
                  <Label for="">{t(item.label)}</Label>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      value={dataSearch[item.name] || ''}
                      onChange={evt => this.onChangeValueForm(item.name, evt.target.value)}
                      {...item}
                      placeholder={t(item.label)}
                    />
                  </div>
                </div>
              );
            })}

            <ButtonToolbar className="ml-auto form__button-toolbar mt-5 mr-3">
              <ButtonCustom
                onClick={this.onHandleClear}
                className="btn btn-cancel mr-0"
                type="button"
                title={t('label.clear')}
                titleloading="Searching"
              />
              <ButtonCustom
                loading={isSearching}
                className="btn btn-submit"
                type="submit"
                title={t('label.search')}
                titleloading="Searching"
              />
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}

InvoiceSearchForm.propTypes = {
  onHandleSearch: PropTypes.func,
  isSearching: PropTypes.bool,
};

export default withTranslation('common')(InvoiceSearchForm);
