import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeGetAccountInfo } from '../../../containers/App/selectors';
import { getAccountId } from '../../../localStorage';

const AccountHeaderForm = ({ title, accountInfo, historicalFolioLink }) => {
  const { t } = useTranslation('common');
  return (
    <div className="col-md-12 pl-0 pr-0 pt-0 pb-3">
      <h4 className="card-title-mb">
        <span className="title-form-header">{title || ''}</span>
        <span>
          <span className="account-number account-info">
            <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
            &nbsp;&nbsp;
            <span className="account-detail">{getAccountId()}</span>
          </span>
        </span>
        {accountInfo?.organization && (
          <span>
            <span className="account-number account-info">
              <span className="account-detail">{`${t('label.organization')}:`}</span>
              &nbsp;&nbsp;
              <span className="account-detail">{accountInfo?.organization}</span>
            </span>
          </span>
        )}
        {historicalFolioLink && (
          <div className="historical-folios-link">
            <a className="key-link" href={historicalFolioLink} target="_blank" rel="noreferrer">
              {t('label.historicalFolios')}
            </a>
          </div>
        )}
      </h4>
    </div>
  );
};

AccountHeaderForm.propTypes = {
  onExport: PropTypes.func,
  isExport: PropTypes.bool,
};

AccountHeaderForm.defaultProps = {
  onExport: () => {},
  isExport: false,
};
const mapStateToProps = createStructuredSelector({
  accountInfo: makeGetAccountInfo() || {},
});
const withConnect = connect(mapStateToProps, {});

export default compose(withConnect)(AccountHeaderForm);
