/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ButtonToolbar, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Select from 'react-select';
import { ButtonCustom, GenericInput } from '../common';
import ModalInvoice from '../common/ModalInvoice';

const listFieldsSearchForm = [
  {
    placeholder: 'label.startDate',
    label: 'label.startDate',
    name: 'startDate',
    type: 'date',
  },
  {
    placeholder: 'label.endDate',
    label: 'label.endDate',
    name: 'endDate',
    type: 'date',
  },
  {
    // placeholder: 'label.invoiceId',
    label: 'label.invoiceId',
    name: 'invoiceId',
    // isPopup: true,
  },
  {
    // placeholder: 'label.invoiceId',
    label: 'label.folioStatus',
    name: 'folioStatus',
    tOptions: 'selections:folioStatus',
    type: 'select',
    isClearable: true,
  },
];

export class SearchFilterAccountStatement extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      dataSearch: {
        startDate: null,
      },
      isOpenStartDate: false,
      isOpenEndDate: false,
    };
  }

  onHandleSearch = evt => {
    evt.preventDefault();
    const { startDate, endDate, invoiceId, folioStatus } = this.state.dataSearch;
    const filter = {
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      invoiceId: invoiceId || null,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      folioStatus: folioStatus || null,
    };
    this.props.onHandleSearch(filter);
  };

  onChangeValueForm = (key, value) => {
    const { dataSearch } = this.state;
    dataSearch[key] = value;
    this.setState({
      ...dataSearch,
    });
  };

  onChangeDate = ({ name, value }) => {
    const { dataSearch } = this.state;
    const newData = cloneDeep(dataSearch);
    newData[name] = value;
    this.setState({
      dataSearch: newData,
    });
  };

  onHandleClear = () => {
    this.setState({
      dataSearch: {
        startDate: null,
      },
    });
  };

  onSelect = (id, row) => {
    const { dataSearch } = this.state;
    const newData = cloneDeep(dataSearch);
    newData.invoiceId = id;
    this.setState({
      dataSearch: newData,
      isOpen: false,
    });
  };

  render() {
    const { isSearching, t } = this.props;
    const { dataSearch, isOpenStartDate, isOpenEndDate, isOpen } = this.state;
    return (
      <div className="search-filter">
        <form className="form" onSubmit={evt => this.onHandleSearch(evt)}>
          <div className="col-md-12 p-0 mb-3 row">
            {listFieldsSearchForm.map(item => {
              if (item.type === 'select') {
                return (
                  <GenericInput
                    {...item}
                    value={dataSearch[item.name] || null}
                    type="select"
                    onChange={val => this.onChangeValueForm(item.name, val ? val.value : '')}
                  />
                  // <div className="col-md-3 mt-3">
                  //   <Label for="">{t(item.label)}</Label>
                  //   <Select
                  //     value={item.options.find(el => el.value === dataSearch[item.name]) || null}
                  //     onChange={val => this.onChangeValueForm(item.name, val ? val.value : '')}
                  //     className="form__form-group-select"
                  //     isDisabled={false}
                  //     isClearable
                  //     {...item}
                  //     label={t(item.label)}
                  //   />
                  // </div>
                );
              }
              if (item.type === 'date') {
                return (
                  <GenericInput
                    {...item}
                    value={dataSearch[item.name] || null}
                    type="date"
                    onChange={this.onChangeDate}
                  />
                  // <div className="col-md-3 mt-3">
                  //   <Label for="">{t(item.label)}</Label>
                  //   <div className="d-flex">
                  //     <div className="date-picker" onClick={() => this.setState({ [item.name]: true })}>
                  //       <DatePicker
                  //         className="form__form-group-datepicker"
                  //         popperPlacement="bottom-start"
                  //         popperModifiers={{
                  //           flip: {
                  //             enabled: false,
                  //           },
                  //           preventOverflow: {
                  //             enabled: true,
                  //             escapeWithReference: false,
                  //           },
                  //         }}
                  //         open={this.state[item.name]}
                  //         onClickOutside={() => this.setState({ [item.name]: false })}
                  //         selected={dataSearch[item.name]}
                  //         onChange={date => this.onChangeDate(item.name, date)}
                  //         // dateFormat="yyyy-mm-dd"
                  //         placeholderText={t(item.label)}
                  //         autoComplete="off"
                  //         isClearable
                  //         {...item}
                  //       />
                  //     </div>
                  //     <div
                  //       className="form__form-group-icon"
                  //       onClick={() => {
                  //         if (!isOpenEndDate) this.setState({ isOpenEndDate: true });
                  //       }}
                  //     >
                  //       <CalendarBlankIcon />
                  //     </div>
                  //   </div>
                  // </div>
                );
              }

              return (
                <div className="col-md-3 ml-0">
                  <Label for="">{t(item.label)}</Label>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      value={dataSearch[item.name] || ''}
                      onChange={evt => this.onChangeValueForm(item.name, evt.target.value)}
                      {...item}
                      placeholder={null}
                      onClick={
                        item.isPopup
                          ? () => {
                              const { isOpen } = this.state;
                              this.setState({ isOpen: !isOpen });
                            }
                          : () => {}
                      }
                    />
                    {item.isPopup && <FaExternalLinkAlt className="fa-ico" />}
                  </div>
                </div>
              );
            })}

            <ButtonToolbar className="ml-auto form__button-toolbar mt-2 mr-3">
              <ButtonCustom
                onClick={this.onHandleClear}
                className="btn btn-cancel mr-0"
                type="button"
                title={t('label.clear')}
                titleloading={t('label.searching')}
              />
              <ButtonCustom
                loading={isSearching}
                className="btn btn-submit"
                type="submit"
                title={t('label.search')}
                titleloading={t('label.searching')}
              />
            </ButtonToolbar>
          </div>
        </form>
        <ModalInvoice isOpen={isOpen} onCancel={() => this.setState({ isOpen: false })} onSelect={this.onSelect} />
      </div>
    );
  }
}

SearchFilterAccountStatement.propTypes = {
  onHandleSearch: PropTypes.func,
  isSearching: PropTypes.bool,
};

export default withTranslation('common')(SearchFilterAccountStatement);
