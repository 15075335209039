/*
 *
 * ManageBalances reducer
 *
 */

import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  errorBalanceUnit: '',
  balanceUnit: {},
});

function manageBalancesReducer(state = initialState, action) {
  switch (action.type) {
    // for get balance units
    case types.GET_BALANCE_UNIT:
      return state.set('balanceUnit', {}).set('errorBalanceUnit', '');
    case types.GET_BALANCE_UNIT_SUCCESS:
      return state.set('balanceUnit', action.payload);
    case types.GET_BALANCE_UNIT_FAILURE:
      return state.set('errorBalanceUnit', action.payload);
    default:
      return state;
  }
}

export default manageBalancesReducer;
