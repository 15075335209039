import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'switchName',
        'durationMinutesStart',
        'durationMinutesEnd',
      ])}`
    : '';
  const getBroadsoftZeroDurationCdrsReport = `getBroadsoftZeroDurationCdrsReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getBroadsoftZeroDurationCdrsReport} {
        userNumber
        groupNumber
        direction
        callingNumber
        calledNumber
        startTime
        endTime
        answerIndicator
        answerTime
        releaseTime
        terminationCause
        dailedDigits
        callCategory
        networkCallType
        networkTranslatedNumber
        releasingParty
        route
        networkCallId
        codec
        accessDeviceAddress
        group
        department
        originalCalledNumber
        originalCalledPresentationIndicator
        originalCalledReason
        redirectingNumber
        redirectingPresentationIndicator
        redirectingReason
        chargeIndicator
        voicePortalInvocationTime
        extAnswerDateTime
        sreserved
        suserid
        sotherPartyName
        serviceProvider
        authorizationCode
        extDurationMinutes
        extDurationSeconds
      }
    }
  `;
};
