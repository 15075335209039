import gql from 'graphql-tag';

export default () => gql`
  query {
    getCustomAttributesByObjectType(input: { objectType: ACCOUNT }) {
      id
      objectType
      attributes {
        index
        name
        length
        type
        mandatory
        displayName
        category
        description
        attributesLOVs {
          index
          attributeId
          attributeValue
        }
      }
    }
  }
`;
