import React from 'react';
import PropTypes from 'prop-types';
import { FaWallet } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import fieldConstant from '../../containers/DashBoardPage/constants';

const InfoCard = ({ accountInformation }) => {
  const { t } = useTranslation('common');
  return (
    <div className="row col-md-12">
      {fieldConstant.fieldsInfoCard.map(item => (
        <div className="col-lg-3 col-md-6" key={item.name}>
          <div className="card">
            <div className="d-flex flex-row">
              <div className="p-10" style={{ backgroundColor: item.bgColor }}>
                <h5 className="text-white box m-b-0">
                  <i className="" />
                  <FaWallet />
                </h5>
              </div>
              <div className="align-self-center m-l-20">
                <h5
                  className="m-b-0"
                  style={{
                    color: item.bgColor,
                  }}
                >
                  {accountInformation[item.name] || 0}
                </h5>
                <h6 className="text-muted m-b-0">{t(item.label)}</h6>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

InfoCard.propTypes = {
  accountInformation: PropTypes.objectOf(PropTypes.any),
};

InfoCard.defaultProps = {
  accountInformation: {},
};

export default InfoCard;
