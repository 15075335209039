import * as types from './types';

export function getAccountById(payload, cb) {
  return {
    type: types.GET_ACCOUNT_BY_ID,
    payload,
    cb,
  };
}
export function manageAccount(payload, cb) {
  return {
    type: types.MANAGE_ACCOUNT,
    payload,
    cb,
  };
}
