import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { SLIDE_UP } from 'react-ladda';
import { MainHeader } from '../../components/Header';
import { GenericInput, ButtonCustom, AccountHeaderForm } from '../../components/common';
import { getAccountId } from '../../localStorage';
import { makeGetPermissionSelfCare } from '../App/selectors';
import { convertCurrency2Option } from '../../utils/utils';
import listCurrency from '../../utils/currency.json';
import { getAccountById } from '../App/actions';
import './styles.scss';

const listFiledsBillingProfile = [
  {
    label: 'label.customerSegment',
    name: 'customerSegment',
    tOptions: 'selections:customerSegment',
  },
  {
    label: 'label.accountType',
    name: 'type',
    tOptions: 'selections:accountType',
  },
  {
    label: 'label.accountSubType',
    name: 'subType',
    tOptions: 'selections:accountSubType',
  },
  {
    label: 'label.creditProfileIndex',
    name: 'creditProfileIndex',
    // fa: 'fa fa-external-link',
  },
  {
    label: 'label.salesChannel',
    name: 'salesChannel',
    tOptions: 'selections:salesChannel',
  },
  {
    label: 'label.marketSegment',
    name: 'marketSegment',
    tOptions: 'selections:marketSegment',
  },
  {
    label: 'label.organization',
    name: 'organization',
  },
  {
    label: 'label.sellingCompany',
    name: 'sellingCompany',
  },
  {
    label: 'label.lineOfBusiness',
    name: 'lineOfBusiness',
  },
  {
    label: 'label.legalEntity',
    name: 'legalEntity',
  },
  {
    label: 'label.currency',
    name: 'currency',
    options: convertCurrency2Option(listCurrency.currencies),
  },
  {
    label: 'label.status',
    name: 'status',
    tOptions: 'selections:status',
  },
  {
    label: 'label.reason',
    name: 'reason',
    tOptions: 'selections:reason',
  },
  {
    label: 'label.effectiveDate',
    name: 'effectiveDate',
  },
  {
    label: 'common:label.clientId',
    name: 'clientId',
  },
  {
    label: 'common:label.salesAgentId',
    name: 'salesAgentId',
  },
  {
    label: 'label.commercialName',
    name: 'commercialName',
  },
];

export class AccountInfoViewer extends React.Component {
  state = {
    accountDetails: {},
  };

  componentDidMount = () => {
    const { getAccountById } = this.props;
    getAccountById({ id: getAccountId() }, ({ success, data }) => {
      if (success) {
        this.setState({
          accountDetails: data && data ? data : {},
        });
      }
    });
  };

  render() {
    const { permissionsSelfCare, t, accountInfo } = this.props;
    // let modeViewSubscription = 0;
    // if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
    //   const listPermission = permissionsSelfCare.selfCareModulePermissions;
    //   modeViewSubscription = checkPermissionViewSubscription({
    //     listPermission,
    //   });
    // }
    const { accountDetails } = this.state;
    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="AccountData" />
        <div className="manage-page__form p-3">
          <div className="form-billing">
            <form className="col-md-12 row pt-4 group-input-billing">
              <div className="col-md-12 p-0">
                <Row>
                  {/* <div className="col-md-12 pl-0 pr-0 pt-0 pb-3">
                    <h4 className="card-title-mb">
                      {t('label.accountInfo')}
                      <span className="account-number">
                        <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                        &nbsp;&nbsp;
                        <span className="account-detail">{getAccountId()}</span>
                      </span>
                    </h4>
                  </div> */}
                  <AccountHeaderForm title={t('label.accountInfo')} />
                </Row>
              </div>
              {listFiledsBillingProfile.map(val => (
                <GenericInput
                  key={val.name}
                  type={val.tOptions || val.options ? 'select' : 'text'}
                  {...val}
                  wrapperClass="col-md-4"
                  value={accountDetails[val.name]}
                  readOnly
                  disabled
                />
              ))}
              <div className="col-md-12 p-0">
                <Row className="mt-sm-0 mx-0 row">
                  <Col sm={{ size: 12, offset: 8 }} className="row float-right">
                    <ButtonCustom
                      // loading={isSubmitting}
                      className="ml-auto mt-auto mb-auto mr-2 btn-cancel"
                      type="button"
                      title={t('label.cancel')}
                      titleloading="Modifying"
                      datastyle={SLIDE_UP}
                      onClick={() => {
                        this.props.history.push('/');
                      }}
                    />
                    {/* <ButtonCustom
                      className="btn-submit"
                      type="submit"
                      title={t('label.submit')}
                      // disabled={modeModifyUser === 1}
                      datastyle={SLIDE_UP}
                    /> */}
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

AccountInfoViewer.propTypes = {
  getAccountById: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
  // accountInfo: makeGetAccountInfo() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    getAccountById: (data, cb) => dispatch(getAccountById(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common'])(compose(withConnect)(AccountInfoViewer));
