import { countryList } from '../../constantsApp/countryList';
import { convertCurrency2Option } from '../../utils/utils';
import listCurrency from '../../utils/currency.json';

export default {
  collapsibleMenuAccountActivities: [
    {
      title: 'label.accountInfo',
      state: 'Account Info',
      key: 'activityAccountInfo',
      entityType: 'ACCOUNT_INFO',
      dataFields: [
        {
          label: 'label.customerSegment',
          name: 'customerSegment',
          tOptions: 'selections:customerSegment',
          type: 'select',
        },
        {
          label: 'label.accountType',
          name: 'type',
          tOptions: 'selections:accountType',
          type: 'select',
        },
        {
          label: 'label.accountSubType',
          name: 'subType',
          tOptions: 'selections:accountSubType',
          type: 'select',
        },
        {
          label: 'label.creditProfileIndex',
          name: 'creditProfileIndex',
        },
        {
          label: 'label.salesChannel',
          name: 'salesChannel',
          tOptions: 'selections:salesChannel',
          type: 'select',
        },
        {
          label: 'label.marketSegment',
          name: 'marketSegment',
          tOptions: 'selections:marketSegment',
          type: 'select',
        },
        {
          label: 'label.sellingCompany',
          name: 'sellingCompany',
        },
        {
          label: 'label.lineOfBusiness',
          name: 'lineOfBusiness',
        },
        {
          label: 'label.legalEntity',
          name: 'legalEntity',
        },
        {
          label: 'label.currency',
          name: 'currency',
          optionSelect: convertCurrency2Option(listCurrency.currencies),
        },
        {
          label: 'label.status',
          name: 'status',
          tOptions: 'selections:status',
          type: 'select',
        },
        {
          label: 'label.reason',
          name: 'reason',
          tOptions: 'selections:reason',
          type: 'select',
        },
        {
          label: 'label.effectiveDate',
          name: 'effectiveDate',
        },
        {
          label: 'label.userId',
          name: 'userId',
        },
        {
          label: 'label.clientId',
          name: 'clientId',
        },
        {
          label: 'label.salesAgentId',
          name: 'salesAgentId',
        },
      ],
    },
    {
      title: 'customerPage:sidebarHandleAccountPage.accountInfo',
      state: 'Account Info',
      key: 'activityAccountInfo',
      entityType: 'CUSTOMER',
      dataFields: [
        {
          label: 'label.customerSegment',
          name: 'customerSegment',
          tOptions: 'selections:customerSegment',
          type: 'select',
        },
        {
          label: 'label.accountType',
          name: 'type',
          tOptions: 'selections:accountType',
          type: 'select',
        },
        {
          label: 'label.accountSubType',
          name: 'subType',
          tOptions: 'selections:accountSubType',
          type: 'select',
        },
        {
          label: 'label.creditProfileIndex',
          name: 'creditProfileIndex',
        },
        {
          label: 'label.salesChannel',
          name: 'salesChannel',
          tOptions: 'selections:salesChannel',
          type: 'select',
        },
        {
          label: 'label.marketSegment',
          name: 'marketSegment',
          tOptions: 'selections:marketSegment',
          type: 'select',
        },
        {
          label: 'label.sellingCompany',
          name: 'sellingCompany',
        },
        {
          label: 'label.lineOfBusiness',
          name: 'lineOfBusiness',
        },
        {
          label: 'label.legalEntity',
          name: 'legalEntity',
        },
        {
          label: 'label.currency',
          name: 'currency',
          optionSelect: convertCurrency2Option(listCurrency.currencies),
        },
        {
          label: 'label.status',
          name: 'status',
          tOptions: 'selections:status',
          type: 'select',
        },
        {
          label: 'label.reason',
          name: 'reason',
          tOptions: 'selections:reason',
          type: 'select',
        },
        {
          label: 'label.effectiveDate',
          name: 'effectiveDate',
        },
        {
          label: 'label.userId',
          name: 'userId',
        },
        {
          label: 'label.clientId',
          name: 'clientId',
        },
        {
          label: 'label.salesAgentId',
          name: 'salesAgentId',
        },
      ],
    },
    {
      title: 'label.contact',
      state: 'Account Contact',
      key: 'activityContacts',
      entityType: 'CONTACT',
      dataFields: [
        {
          name: 'id',
          label: 'common:label.id',
          isDisable: true,
        },
        {
          label: 'common:label.salutation',
          name: 'salutation',
        },
        {
          label: 'common:label.firstName',
          name: 'firstName',
        },
        {
          label: 'common:label.middleName',
          name: 'middleName',
        },
        {
          label: 'common:label.lastName',
          name: 'lastName',
        },
        {
          label: 'common:label.position',
          name: 'position',
        },
        {
          label: 'common:label.organization',
          name: 'organization',
        },
        {
          label: 'common:label.email',
          name: 'email',
        },
        {
          label: 'common:label.phone',
          name: 'phones',
        },
      ],
    },
    {
      title: 'label.addresses',
      state: 'Account Addresses',
      key: 'activityAddresses',
      entityType: 'ADDRESS',
      dataFields: [
        {
          name: 'id',
          label: 'common:label.id',
          required: true,
        },
        {
          name: 'street',
          label: 'common:label.street',
        },
        {
          name: 'extraLine',
          label: 'common:label.extraLine',
        },
        {
          name: 'landmark',
          label: 'common:label.landmark',
        },
        {
          label: 'common:label.country',
          name: 'country',
          optionSelect: countryList,
        },
        {
          name: 'state',
          label: 'common:label.state',
        },
        {
          label: 'common:label.city',
          name: 'city',
        },
        {
          name: 'postalCode',
          label: 'common:label.postalCode',
        },
        {
          name: 'code',
          label: 'common:label.code',
          isDisable: true,
        },
      ],
    },
    {
      title: 'label.paymentProfile',
      state: 'Payment Profile',
      key: 'activityPaymentProfiles',
      entityType: 'PAYMENT_PROFILE',
      dataFields: [
        {
          name: 'id',
          label: 'common:label.id',
        },
        {
          label: 'common:label.paymentTerm',
          name: 'paymentTerm',
          tOptions: 'selections:paymentTerm',
          type: 'select',
        },
        {
          label: 'common:label.paymentMethod',
          name: 'paymentMethod',
          tOptions: 'selections:paymentMethod',
          type: 'select',
        },
        {
          label: 'common:label.cardToken',
          name: 'cardToken',
        },
        {
          label: 'common:label.cardExp',
          name: 'cardExpiry',
        },
        {
          label: 'common:label.creditCardCVV',
          name: 'last4CC',
        },
      ],
    },
    {
      title: 'label.billingProfile',
      state: 'Billing Profile',
      key: 'activityBillingProfiles',
      entityType: 'BILLING_PROFILE',
      dataFields: [
        {
          name: 'id',
          label: 'common:label.id',
        },
        {
          name: 'parentId',
          label: 'common:label.parentId',
          isDisable: true,
        },
        {
          name: 'billingDom',
          label: 'common:label.billingDom',
          type: 'number',
        },
        {
          name: 'billingSegment',
          label: 'common:label.billingSegment',
        },
        {
          name: 'billingFrequency',
          label: 'common:label.billingFrequency',
          tOptions: 'selections:billingFrequency',
          type: 'select',
        },
        {
          name: 'invoiceType',
          label: 'common:label.invoiceType',
          tOptions: 'selections:invoiceType',
          type: 'select',
        },
        {
          name: 'invoiceDelivery',
          label: 'common:label.invoiceDelivery',
          tOptions: 'selections:invoiceDelivery',
          type: 'select',
        },
        {
          name: 'paymentProfileId',
          label: 'common:label.paymentProfileId',
        },
      ],
    },
    {
      title: 'label.customAttributes',
      state: 'Custom Attributes',
      key: 'activityCustomAttributes',
      entityType: 'CUSTOM_ATTRIBUTES',
      dataFields: [],
    },
  ],

  collapsibleMenuBeforeAfter: [
    {
      title: 'Before',
      // state: 'Before',
      state: 'Childrent',
      key: 'beforeData',
    },
    {
      title: 'After',
      // state: 'Aftter',
      state: 'Childrent',
      key: 'afterData',
    },
  ],

  checkRoleGroup: [
    {
      label: 'common:label.useAsBilling',
      value: 'BILLING',
    },
    {
      label: 'common:label.useAsSoldTo',
      value: 'SOLD_TO',
    },
    {
      label: 'common:label.useAsPayment',
      value: 'PAYMENT',
    },
    {
      label: 'common:label.useAsService',
      value: 'SERVICE',
    },
  ],

  dataFieldActivity: [
    {
      name: 'id',
      label: 'label.id',
    },

    {
      name: 'accountId',
      label: 'label.accountId',
    },
    {
      name: 'userId',
      label: 'label.userId',
    },
    {
      name: 'entityType',
      label: 'label.entityType',
      isSelect: true,
      tOptions: 'selections:entityType',
    },
    {
      name: 'entityId',
      label: 'label.entityId',
    },
    {
      name: 'action',
      label: 'label.action',
    },
    {
      name: 'source',
      label: 'label.source',
      isSelect: true,
      tOptions: 'selections:source',
    },
    {
      name: 'activityDate',
      label: 'label.activityDate',
    },
    {
      name: 'request',
      label: 'label.request',
      type: 'textarea',
      wrapperClass: 'col-md-6',
      style: { minHeight: 200 },
    },
    {
      name: 'response',
      label: 'label.response',
      type: 'textarea',
      wrapperClass: 'col-md-6',
      style: { minHeight: 200 },
    },
  ],
};
