import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { IoMdFastforward, IoMdRewind } from 'react-icons/io';

class Pagination extends React.PureComponent {
  componentDidMount() {}

  render() {
    const { isActivePre, isActiveNext, handlePage, t } = this.props;
    return (
      <div
        className="btn-group table__pagination"
        role="group"
        aria-label="Basic example"
      >
        <button
          type="button"
          className="btn btn-default btn-previous"
          disabled={!isActivePre}
          style={{ marginLeft: 30 }}
          onClick={() => handlePage(-1)}
        >
          <span aria-hidden="true">
            <IoMdRewind style={{ margin: '0px 3px 0px 0px' }} />
            {t('label.previous')}
          </span>
          <span className="sr-only">{t('label.previous')}</span>
        </button>
        <button
          type="button"
          className="btn btn-default btn-next"
          disabled={!isActiveNext}
          onClick={() => handlePage(1)}
        >
          <span aria-hidden="true">
            {t('label.next')}
            <IoMdFastforward style={{ margin: '0px 5px 0px 15px' }} />
          </span>
          <span className="sr-only">{t('label.next')}</span>
        </button>
      </div>
    );
  }
}

Pagination.propTypes = {
  isActivePre: PropTypes.bool,
  isActiveNext: PropTypes.bool,
  handlePage: PropTypes.func,
};

export default withTranslation('common')(Pagination);
