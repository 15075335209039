import { createSelector } from 'reselect';

/**
 * Direct selector to the manageProfilePage state domain
 */

const selectViewCollections = state => {
  return state.get('viewCollectionsPageReducer');
};

const makeGetCollectionUnit = () =>
  createSelector(selectViewCollections, collection => (collection ? [...collection.get('transactionUnits')] : []));

const makePageTransactionParams = () =>
  createSelector(selectViewCollections, collection => (collection ? collection.get('paramsTransaction').toJS() : {}));

export { makeGetCollectionUnit, makePageTransactionParams };
