import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';
export default ({ serviceType, page }) => {
  const filterStr = serviceType
    ? `, filter: ${convertFilterRequestUrl(
      { status: 'SELLABLE', serviceType },
      ['status', 'serviceType'],
    )}`
    : '';
  const searchBundles = `searchBundles(page: ${page}, size: 9 ${filterStr})`;
  return gql`
    query {
      ${searchBundles} {
        id
        name
        description
    }
  }
  `;
};
