import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ButtonExport = ({ onExport, isExport, btnTitle, ...rest }) => {
  const { t } = useTranslation('common');
  return (
    <button
      type="button"
      id="embrix-btn-export"
      className="ladda-button btn btn-submit x-small mr-3 mt-0 float-right btn-default-height"
      onClick={onExport}
      {...rest}
    >
      {isExport ? t('label.export') : t(btnTitle) || t('label.download')}
    </button>
  );
};

ButtonExport.propTypes = {
  onExport: PropTypes.func,
  isExport: PropTypes.bool,
};

ButtonExport.defaultProps = {
  onExport: () => {},
  isExport: false,
};

export default ButtonExport;
