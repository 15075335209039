import React, { Component } from 'react';
import './styles.scss';
import logodark from '../../assets/images/logo-dark.png';
import planning from '../../assets/images/planning.png';
class AccountDetails extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 logoimg">
            <img src={logodark} className="reglogo" />
            <br />
            <br />
            <hr width="50%" />
            <ul className="list-inline ulcircle">
              <li className="list-inline-item ">
                <div className="circle active">1</div>
              </li>
              <li className="list-inline-item">
                <div className="circle">2</div>{' '}
              </li>
              <li className="list-inline-item">
                <div className="circle">3</div>
              </li>
              <li className="list-inline-item">
                <div className="circle">4</div>
              </li>
            </ul>
            <ul className="list-inline uldetails">
              <li className="list-inline-item" style={{ marginleft: '-15px' }}>
                Account Details
              </li>
              <li className="list-inline-item" style={{ marginleft: '20px' }}>
                Card Details
              </li>
              <li className="list-inline-item">
                &nbsp;&nbsp;&nbsp;Select Package
              </li>
              <li className="list-inline-item">Create Your Package</li>
            </ul>
          </div>
        </div>

        <div className="container">
          <div className="row row-margin-bottom">
            <div className="col-md-12 no-padding lib-item" data-category="view">
              <div className="lib-panel">
                <div className="row box-shadow">
                  <div className="col-md-3 bgad align-items-center justify-content-center">
                    <center>
                      <img className="lib-img-show" src={planning} />
                    </center>
                    <h2>Account Details</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>

                  <div className="col-md-9">
                    <div className="lib-row lib-header ">
                      <form role="form">
                        <div className="row">
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>First Name</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                            />
                          </div>
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>Street</label>
                            <input type="text" className="form-control" id="" />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>Last Name</label>
                            <input
                              type="password"
                              className="form-control"
                              id=""
                              placeholder=""
                            />
                          </div>
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>Country</label>
                            <input
                              type="password"
                              className="form-control"
                              id=""
                              placeholder="USA"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                            />
                          </div>
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>State</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                              placeholder="State1"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>Phone Number</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                            />
                          </div>
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>City</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                              placeholder="Name of the City"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>Postal Code</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                            />
                          </div>
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>Password*</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>User ID</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                            />
                          </div>
                          <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <label>Retype Password*</label>
                            <input
                              type="email"
                              className="form-control"
                              id=""
                            />
                          </div>
                        </div>
                      </form>
                      <ul className="list-inline accdetailbtn">
                        <li className="list-inline-item">
                          <a href="accountinfo">
                            <button className="btn custbtn">Previous</button>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="credidcarddetails">
                            <button className="btn custbtn">Next</button>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1" />
          </div>
        </div>
      </div>
    );
  }
}

export default AccountDetails;
