/*
 *
 * ViewInvoices constants
 *
 */

export const GET_AR_OPS_UNIT = 'GET_AR_OPS_UNIT';
export const GET_AR_OPS_UNIT_SUCCESS = 'GET_AR_OPS_UNIT_SUCCESS';
export const SET_AR_OPS_PARAMS = 'SET_AR_OPS_PARAMS';
export const GET_AR_OPS_UNIT_BY_ID = 'GET_AR_OPS_UNIT_BY_ID';
