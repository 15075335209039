import React, { PureComponent } from 'react';
import Slider from 'react-slick';

export default class SliderContainer extends PureComponent {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
    };
    const { imagesList } = this.props;
    return (
      <div>
        <Slider {...settings}>
          {imagesList &&
            imagesList.length > 0 &&
            imagesList.map(val => (
              <div>
                <img src={val || ''} className="item-slider" />
              </div>
            ))}
        </Slider>
      </div>
    );
  }
}
