import React, { Component } from 'react';
import './styles.scss';
import logodark from '../../assets/images/logo-dark.png';
import planning from '../../assets/images/planning.png';
import { FaEye } from 'react-icons/fa';
class CreatePackage extends Component {
  constructor() {
    super();
    this.state = {};
  }
render() {
  return (
<div className="container-fluid">
    <div className="row">
        <div className="col-md-12 logoimg">
                  <img src={logodark} className="reglogo"/> <br/><br/>
            <hr width="50%"/>
            <ul className="list-inline ulcircle">
                <li className="list-inline-item "><div className="circle ">1</div></li>
                <li className="list-inline-item"><div className="circle ">2</div> </li>
                <li className="list-inline-item"><div className="circle">3</div></li>
                <li className="list-inline-item"><div className="circle active">4</div></li></ul>
        <ul className="list-inline uldetails">
                      <li className="list-inline-item" style={{ marginleft: '-15px' }}>Account Details</li>
                      <li className="list-inline-item" style={{ marginleft: '20px' }}>Card Details</li>
            <li className="list-inline-item">&nbsp;&nbsp;&nbsp;Select Package</li>
            <li className="list-inline-item">Create Your Package</li>
        </ul>
            </div>
    </div>

<div className="container">
    
    <div className="row row-margin-bottom">
        <div className="col-md-12 no-padding lib-item" data-category="view">
            <div className="lib-panel">
                <div className="row box-shadow">
                    <div className="col-md-3 bgcp align-items-center justify-content-center">
                                  <center><img className="lib-img-show" src={planning} /></center>
                        <h2>Create Package</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                        book.</p>
                    </div>
                    
                    <div className="col-md-9">
                        <div className="lib-row lib-header ">
                            <h2 style={{textalign: 'center'}}>Cable Services</h2>
                            <div className="row">
                            <ul className="list-inline packageul">
                                <li className="list-inline-item"><div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div></li>
                                              <li className="list-inline-item"><div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div></li>
                                <li className="list-inline-item">
                                                  <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div>
                                </li>
                            </ul>
                                      </div>          
                                      <div className="row">
                <ul className="list-inline packageul">
                    <li className="list-inline-item">
                                                  <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div>
                    </li>
                    <li className="list-inline-item">
                                                  <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div>
                    </li>
                    <li className="list-inline-item">
                                                  <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div>
                    </li>
                </ul>
                                      </div>       
            <div className="row"> 
                <ul className="list-inline packageul">
                    <li className="list-inline-item">
                                                  <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div>
                    </li>
                    <li className="list-inline-item">
                                                  <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div>
                    </li>
                    <li className="list-inline-item">
                                                  <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div>
                    </li>
                </ul>
                                      </div>  
                                 
<div className="col-md-12 itembottom">
         <div className="row">  
                <div className="col-md-3 items">
                    <h4 >Cable Services</h4>
<ul className="cpimg"><li>Selected One</li>
<li>Selected Two</li>
<li>Selected Three</li></ul>
                </div>
                <div className="col-md-2 items">
                <h4 style={{textalign: 'center'}}>Internet</h4>
                                              <ul className="cpimg">
                    <li>Selected One</li>
                    <li>Selected Two</li>
                    <li>Selected Three</li>
                </ul>
                </div>
                <div className="col-md-2 items">
                <h4 style={{textalign: 'center'}}>VoIP</h4>
                                              <ul className="cpimg">
                    <li>Selected One</li>
                    <li>Selected Two</li>
                    <li>Selected Three</li>
                </ul>
                </div>
                <div className="col-md-2 items">
                <h4 style={{textalign: 'center'}}>Service</h4>
                <ul className="cpimg">
                    <li>Selected One</li>
                    <li>Selected Two</li>
                    <li>Selected Three</li>
                </ul>
                </div>
                <div className="col-md-2 items">
                <h4 style={{textalign: 'center'}}>Service 2</h4>
                <ul className="cpimg">
                    <li>Selected One</li>
                    <li>Selected Two</li>
                    <li>Selected Three</li>
                </ul>
                </div>
                </div>
                                      </div>           
                        </div>
                        <div className="clearfix"></div>
                        
                        <ul className="list-inline textctrsp">
                            <li className="list-inline-item"><a href="selectpackage"><button className="btn custbtn">Previous</button></a></li>
                            <li className="list-inline-item"><button className="btn custbtn">Submit</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-1"></div>
        
    </div>
</div>


   
</div>

  );
}
}




export default CreatePackage;

