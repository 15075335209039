// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';
import customerVNS from './constants/manageAccountNS';

export default data => {
  const dataCreate = parseToMutationRequest(data, customerVNS);
  const manageAccount = `manageAccount(input: ${dataCreate})`;

  return gql`
  mutation {
    ${manageAccount} {
      accountId
      orderId
      userId
      password
    }
  }
`;
};
