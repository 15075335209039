import React from 'react';
import PropTypes from 'prop-types';
import { FaStar } from 'react-icons/fa';

const RequirIcon = () => (
  <FaStar
    color="#dc3545"
    size={5}
    style={{
      position: 'absolute',
      marginTop: 3,
      marginLeft: 2,
    }}
  />
);

RequirIcon.propTypes = {};

export default RequirIcon;
