import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TableInfo from '../../components/common/TableInfo';
const heads = [
  {
    key: 'id',
    name: 'label.id',
  },
  {
    key: 'Account Id',
    name: 'label.accountId',
  },
  {
    key: 'Status',
    name: 'label.status',
  },
  {
    key: 'Reason',
    name: 'label.reason',
  },
  {
    key: 'Type',
    name: 'label.type',
  },
  {
    key: 'Parent Id',
    name: 'label.parentId',
  },
  {
    key: 'Provisioning Id',
    name: 'label.provisioningId',
  },
  // {
  //   key: 'Bundle Id',
  //   name: 'Bundle Id',
  // },
  {
    key: 'Bundle Name',
    name: 'label.bundleName',
  },
  {
    key: 'Package Name',
    name: 'label.packageName',
  },
  {
    key: 'Subscription Id',
    name: 'label.subscriptionId',
  },
  {
    key: 'Effective Date',
    name: 'label.effectiveDate',
  },
];

class TableServiceUnits extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  renderRow = (row, idx) => {
    const { t } = this.props;
    const status = t('selections:subscriptionStatus')().find(
      val => val.value === row.status,
    );
    const serviceType = t('selections:serviceType')().find(
      val => val.value === row.type,
    );
    return (
      <tr key={idx}>
        <td>{row.id}</td>
        <td>{row.accountId}</td>
        <td>{status ? status.label : ''}</td>
        <td>{row.reason}</td>
        <td>{serviceType ? serviceType.label : ''}</td>
        <td>{row.parentId}</td>
        <td>{row.provisioningId}</td>
        <td>{row.bundleName}</td>
        <td>{row.packageName}</td>
        <td>{row.subscriptionId}</td>
        <td>{row.effectiveDate}</td>
      </tr>
    );
  };

  renderBody() {
    const { data, errorServiceUnits, t } = this.props;
    if (errorServiceUnits) {
      return (
        <tbody>
          <tr>
            <td colSpan={12} className="txt-error">
              {errorServiceUnits}
            </td>
          </tr>
        </tbody>
      );
    }

    if (!data.length) {
      return (
        <tbody>
          <tr>
            <td colSpan={12}>{t('message.noRecord')}</td>
          </tr>
        </tbody>
      );
    }

    return <tbody>{data.map(this.renderRow)}</tbody>;
  }

  render() {
    const { data, title } = this.props;
    return (
      <TableInfo title={title} heads={heads}>
        {this.renderBody(data)}
      </TableInfo>
    );
  }
}

TableServiceUnits.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  errorServiceUnits: PropTypes.string,
};

export default withTranslation('common')(TableServiceUnits);
