import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import ModalDetailsItem from '../common/ModalDetailsItem';
import { FormCollapse, DataTable, GenericInput } from '../common';

const collapsibleMenuArOpsUnitById = [
  {
    title: 'label.arOpsData',
    state: 'AR Ops Data',
  },
  {
    title: 'label.taxData',
    state: 'Tax Data',
  },
  {
    title: 'label.currency',
    state: 'Currency',
  },
];

const tableCurrencyArOpsDataId = [
  // {
  //   name: 'index',
  //   label: 'Index',
  // },
  {
    name: 'type',
    label: 'Type',
  },
  // {
  //   name: 'offerType',
  //   label: 'OfferType',
  // },
  {
    name: 'currencyId',
    label: 'Currency',
  },
  {
    name: 'unitPrice',
    label: 'Unit Price',
  },
  {
    name: 'amount',
    label: 'Amount',
  },
];

const tableOpsTaxData = [
  {
    name: 'index',
    label: 'Index',
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'state',
    label: 'State',
  },
  {
    name: 'country',
    label: 'Country',
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
  },
  {
    name: 'code',
    label: 'Code',
  },
  {
    name: 'taxCode',
    label: 'Tax Code',
  },
  {
    name: 'jurisdiction',
    label: 'Jurisdiction',
  },
  {
    name: 'taxableAmount',
    label: 'Taxable Amount',
  },
  {
    name: 'exemptAmount',
    label: 'Exempt Amount',
  },
  {
    name: 'percent',
    label: 'Percent',
  },
  {
    name: 'taxAmount',
    label: 'Tax Amount',
  },
];

const dataFieldArOpsDataId = [
  {
    name: 'reason',
    label: 'Reason',
  },
  {
    name: 'taxCode',
    label: 'Tax Code',
  },
  {
    name: 'arCrDrType',
    label: 'Ar Cr Dr Type',
    type: 'select',
    tOptions: 'selections:arCrDrType',
  },
  {
    name: 'taxRule',
    label: 'Tax Rule',
    type: 'select',
    tOptions: 'selections:taxRule',
  },
  {
    name: 'itemId',
    label: 'Item Id',
  },
  {
    name: 'numberOfTransactions',
    label: 'Number Of Transactions',
  },
  {
    name: 'amount',
    label: 'Amount',
  },
  {
    name: 'percent',
    label: 'Percent',
    type: 'number',
  },
  // {
  //   name: 'appliedStart',
  //   label: 'Applied Start',
  // },
  // {
  //   name: 'appliedEnd',
  //   label: 'Applied End',
  // },
];

const dataFieldArOpsUnitById = [
  {
    name: 'id',
    label: 'AR Ops Id',
  },
  {
    name: 'type',
    label: 'AR Ops Type',
    // type: 'select',
    // tOptions: 'selections:arType',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'serviceType',
    label: 'ServiceType',
    type: 'select',
    tOptions: 'selections:serviceType',
  },
  {
    name: 'accountId',
    label: 'Account Id',
  },
  {
    name: 'endDate',
    label: 'End Date',
  },
  {
    name: 'source',
    label: 'Source',
    type: 'select',
    tOptions: 'selections:source',
  },
  {
    name: 'netAmount',
    label: 'Net Amount',
  },
];

class ARActivity extends Component {
  state = {
    isOpenModal: false,
    isSearchDataModal: false,
    itemData: {},
    activeTab: {
      // name: 'Recurring Data',
      // isActive: true,
    },
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  doGetArOpsUnitById = id => {
    const { getArOpsUnitById } = this.props;
    this.setState({ isOpenModal: true, isSearchDataModal: true });
    getArOpsUnitById(id, ({ data, success }) =>
      this.setState({
        itemData: success ? data : {},
        isSearchDataModal: false,
      })
    );
  };

  renderItem = item => {
    const { t } = this.props;
    const { itemData } = this.state;
    switch (item.state) {
      case 'AR Ops Data':
        return (
          <div className="row col-md-12 p-2">
            {dataFieldArOpsDataId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.arData && itemData.arData[0] && itemData.arData[0][val.name]
                    ? itemData.arData[0][val.name]
                    : null
                }
                disabled
                readOnly
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Tax Data':
        return (
          <div className="col-md-12">
            <DataTable columns={tableOpsTaxData} data={itemData.taxData ? itemData.taxData : []} />
          </div>
        );
      case 'Currency':
        return (
          <div className="col-md-12">
            <DataTable columns={tableCurrencyArOpsDataId} data={itemData.balances ? itemData.balances : []} />
          </div>
        );

      default:
        return null;
    }
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const { data, t } = this.props;
    const { isSearchDataModal, itemData, isOpenModal, activeTab } = this.state;
    const newCollapsibleMenu = collapsibleMenuArOpsUnitById;

    const tableColumns = [
      { label: 'label.id', name: 'id' },
      { label: 'label.endDate', name: 'endDate' },
      { label: 'label.amount', name: 'amount' },
      {
        label: 'label.type',
        name: 'type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:arOpsType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      { label: 'label.description', name: 'description' },
      {
        label: 'label.source',
        name: 'source',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:source')().find(val => val.value === item.source) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      { label: 'label.invoiceId', name: 'invoiceId' },
      { label: 'label.itemId', name: 'itemId' },
      {
        label: 'label.arCrDrType',
        name: 'arCrDrType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:arCrDrType')().find(val => val.value === item.arCrDrType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        label: 'label.arTaxRule',
        name: 'arTaxRule',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:taxRule')().find(val => val.value === item.arTaxRule) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      { label: 'label.reason', name: 'reason' },
      {
        label: 'label.view',
        name: 'view',
        render: (colName, item) => {
          return (
            <button
              type="button"
              className="btn ml-0 btn-outline-success btn-sm"
              onClick={() => this.doGetArOpsUnitById(item.id)}
            >
              {t('label.view')}
            </button>
          );
        },
      },
    ];
    // if (
    //   !itemData.type ||
    //   [
    //     'BILL_ADJUSTMENT',
    //     'TRANSACTION_ADJUSTMENT',
    //     'BILL_DISPUTE',
    //     'TRANSACTION_DISPUTE',
    //     'BILL_SETTLEMENT',
    //     'ACCOUNT_WRITEOFF',
    //     'INVOICE_WRITEOFF',
    //     'TRANSACTION_SETTLEMENT',
    //     'WRITE_OFF',
    //     'WRITE_OFF_REVERSAL',
    //   ].indexOf(itemData.type) === -1
    // )
    //   newCollapsibleMenu = newCollapsibleMenu.filter(
    //     val => val.state !== 'AR Ops Data',
    //   );
    // if (
    //   !itemData.type ||
    //   [
    //     'TAX_ADJUSTMENT',
    //     'TAX_DISPUTE',
    //     'TAX_SETTLEMENT',
    //     'TAX_WRITEOFF',
    //     'TAX_WRITEOFF_REVERSAL',
    //     'TAX',
    //   ].indexOf(itemData.type) === -1
    // )
    //   newCollapsibleMenu = newCollapsibleMenu.filter(
    //     val => val.state !== 'Tax Data',
    //   );

    return (
      <Card>
        {/* <table className="table table-hover">
          <thead>
            <tr>{heads.map(item => this.renderHeader(item))}</tr>
          </thead>
          {this.renderBody(data)}
        </table> */}
        <DataTable
          isFixedHeaderTable
          columns={tableColumns}
          data={data || []}
          onSort={this.onSortColumn}
          // sorted={sorted}
        />
        <ModalDetailsItem
          isOpen={isOpenModal}
          data={itemData}
          onToggle={this.onToggleModal}
          dataField={dataFieldArOpsUnitById}
          title="label.aRActivity"
          isSearch={isSearchDataModal}
        >
          <div className="pt-4">
            {newCollapsibleMenu.map(item => (
              <FormCollapse
                key={item.title}
                label="First Name"
                isActive={item.state === activeTab.name && activeTab.isActive}
                title={item.title || ''}
                state={item.state}
                onToggleTab={this.onToggleTab}
              >
                {this.renderItem(item)}
              </FormCollapse>
            ))}
          </div>
        </ModalDetailsItem>
      </Card>
    );
  }
}

ARActivity.propTypes = {
  data: PropTypes.array,
  // history: PropTypes.object,
};

export default withTranslation('common')(ARActivity);
