import gql from 'graphql-tag';

export default () => {
  const getTenantConfig = 'getTenantConfig(input: {dummy: ""})';
  return gql`
    query {
      ${getTenantConfig} {
        id
        tenantProfile {
          logoPath
        }
      }
    }
  `;
};
