/*
 *
 * ManageServices actions
 *
 */

import * as types from './types';

// for get service units by accountId
export function getServiceUnitAc(payload) {
  return {
    type: types.GET_SERVICE_UNITS_AC,
    payload,
  };
}

export function getServiceUnitAcSuccess(payload) {
  return {
    type: types.GET_SERVICE_UNITS_AC_SUCCESS,
    payload,
  };
}

export function getServiceUnitAcFailure(payload) {
  return {
    type: types.GET_SERVICE_UNITS_AC_FAILURE,
    payload,
  };
}

// for get subscription
export function getSubscription(payload) {
  return {
    type: types.GET_SUBSCRIPTION,
    payload,
  };
}

export function getSubscriptionSuccess(payload) {
  return {
    type: types.GET_SUBSCRIPTION_SUCCESS,
    payload,
  };
}

export function getSubscriptionFailure(payload) {
  return {
    type: types.GET_SUBSCRIPTION_FAILURE,
    payload,
  };
}
