import { CREATE_SELF_CARE_ACCOUNT, SEARCH_BUNDLES } from './types';

export function createSelfCare(payload, cb) {
  return {
    type: CREATE_SELF_CARE_ACCOUNT,
    payload,
    cb,
  };
}

export function searchBundles(payload, cb) {
  return {
    type: SEARCH_BUNDLES,
    payload,
    cb,
  };
}
