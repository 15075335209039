import { createSelector } from 'reselect';

/**
 * Direct selector to the manageProfilePage state domain
 */

const selectViewTransactions = state => {
  return state.get('viewTransactionsPageReducer');
};

const makeGetTransactionUnit = () =>
  createSelector(selectViewTransactions, transaction => (transaction ? [...transaction.get('transactionUnits')] : []));

const makePageTransactionParams = () =>
  createSelector(selectViewTransactions, customers => (customers ? customers.get('paramsTransaction').toJS() : {}));

export { makeGetTransactionUnit, makePageTransactionParams };
