import gql from 'graphql-tag';

export default () => gql`
  query {
    getAwsS3Credential(dummy: "") {
      accessKey
      secretKey
      region
      bucketName
      tenant
    }
  }
`;
