import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';
import invoiceNS from './constants/invoiceNS';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, invoiceNS)}`
    : '';
  const searchBillInvoice = `searchBillInvoice(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchBillInvoice} {
        accountId
        billingProfileId
        totalInCollection
        billInvoiceData {
          startDate
          endDate
          invoiceDate
          dueDate
          invoiceUnitId
          total
          nonPayingTotal
          billTotal
          due
          type
          invoiceStatus
          billStatus
          accountId
        }
      }
    }
  `;
};
