import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const newCustomerVNS = [
    'status',
    'customerSegment',
    'type',
    'subType',
    'salesChannel',
    'marketSegment',
    'reason',
    'billingDom',
    'billingFrequency',
    'invoiceType',
    'invoiceDelivery',
    'paymentMethod',
    'fetchParentOnly',
    'activeSubscription',
    'subscriptionStatus',
    'creditProfileIndex',
    'trialLevels',
    'trialStatus',
    'propagateToBillingAccounts',
    'pauseBilling',
    'pauseInvoicing',
    'collectionAgentAssigned',
    'duration',
    'childAccount',
    'exemptionLevel',
    'index',
    'lowerLimit',
    'upperLimit',
    'serviceType',
    'percent',
  ];
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, newCustomerVNS)}` : '';
  const searchAccounts = `searchAccounts(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchAccounts} {
        id
        currency
        status
        parentId
        userId
        reason
        type
        salesAgentId
        nonPaying
        clientId
        taxId
        foreignTaxId
        effectiveDate
        collectionAgent
        name
        groupCompany
        accountCategory
        contacts {
          firstName
          lastName
          organization
          email
        }
        addresses {
          city
          state
        }  
      }
    }
  `;
};
