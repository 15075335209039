import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { getFirstTimeLogin, getPassword, getAccountId } from '../../localStorage';
import injectSaga from '../../utils/injectSaga';
import { MainHeader } from '../../components/Header';
import { InfoCard } from '../../components/DashBoard';
import injectReducer from '../../utils/injectReducer';
import { ChangePassword } from '../../components/Login';
import { makeGetAccountInformation } from './selectors';
import { makeGetS3Config, makeGetSelfCareConfig } from '../App/selectors';
import reducer from './reducer';
import saga from './saga';
import { SliderContainer } from '../../components/common';
import { handleChangePassword, getAccountInformation } from './actions';
import { getBase64 } from '../../utils/utils';
import { getTenantSelfCareConfig, readFileFromS3 } from '../App/actions';
import './styles.scss';
import { isConnecting, isEndConnected } from '../Loader/actions';

const ShowMakerting = memo(({ imagesList, data, videosList }) => {
  const { t } = useTranslation('common');

  return (
    <div className="col-md-12 row mt-0">
      <div className="col-md-3">
        <SliderContainer imagesList={imagesList} />
      </div>
      <div className="col-md-3">
        <div className="group-viewer-dashboard">
          <div className="title-header">
            <span className="ml-2">{t('label.keyLink')}</span>
          </div>
          <div className="col-md-12 mt-0 p-0 mt-1">
            {data.keyLinks &&
              data.keyLinks.length > 0 &&
              data.keyLinks.map((val, index) => (
                <div className="pt-2" key={`key-${index}`}>
                  <a className="key-link" href={val.url} target="_blank" rel="noreferrer">
                    {val.text}
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="group-viewer-dashboard">
          <div className="col-md-12">
            <div className="container-advertisement">
              {data.advertisements &&
                data.advertisements.length > 0 &&
                data.advertisements.map((val, index) => (
                  <React.Fragment key={`key-${index}`}>
                    <div key={`key-${index}`} className={`title-hover ${index > 0 ? 'pt-3' : ''}`}>
                      {val.header}
                    </div>
                    <div>{val.shortDescription}</div>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 video-group">
        {videosList && videosList[0] && (
          <video controls muted height="250 !important" width="100%" autoPlay controlsList="nodownload">
            <source src={videosList && videosList[0] ? videosList[0] : null} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        )}
      </div>
    </div>
  );
});

export class DashBoardPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: getFirstTimeLogin() === 'true',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      accountInformation: {},
      data: { mediaFiles: [], advertisements: [], keyLinks: [] },
      imagesList: [],
      videosList: [],
      isLoadingSuccess: false,
    };
    this.timeout = null;
  }

  componentDidMount() {
    const { accountInformation, getAccountInformation } = this.props;
    if (!accountInformation) {
      getAccountInformation(getAccountId(), ({ success, data }) => {
        if (success && data) {
          this.setState({ accountInformation: data });
        }
      });
    } else {
      this.setState({ accountInformation: { ...accountInformation } });
    }
    this.getSelfCareConfig();
  }

  doGetListImages = (mediaData, success) => {
    const { s3Config, readFileFromS3, isConnecting, isEndConnected } = this.props;
    if (mediaData && success) {
      this.setState({
        data: mediaData[0],
      });
      const imagesList = [];
      const videosList = [];
      if (mediaData && mediaData[0] && mediaData[0].mediaFiles) {
        isConnecting();
        sortBy(mediaData[0].mediaFiles, ['type']).forEach((val, index) => {
          readFileFromS3({ url: val.path, ...s3Config }, ({ success, data }) => {
            if (success) {
              getBase64(data, result => {
                if (val.type === 'IMAGE') imagesList.push(result);
                if (val.type === 'VIDEO') {
                  videosList.push(result);
                }
                this.setState({ imagesList: [...imagesList], videosList: [...videosList] });
                if (index === mediaData[0].mediaFiles.length - 1) {
                  clearTimeout(this.timeout);
                  this.timeout = setTimeout(
                    () => this.setState({ isLoadingSuccess: true }, () => isEndConnected()),
                    1800
                  );
                }
              });
            } else {
              isEndConnected();
            }
          });
        });
      } else {
        this.setState({ isLoadingSuccess: true });
      }
    }
  };

  getSelfCareConfig = () => {
    const { getTenantSelfCareConfig, selfCareConfig } = this.props;
    if (selfCareConfig && selfCareConfig.length) {
      this.doGetListImages(selfCareConfig, true);
    } else {
      getTenantSelfCareConfig('', ({ data, success }) => {
        this.doGetListImages(data, success);
      });
    }
  };

  redirectPage = pageName => {
    this.props.history.push(pageName);
  };

  handleChange = (evt, key) => {
    this.setState({ [key]: evt.target.value });
  };

  handleSubmit = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    if (oldPassword !== getPassword()) {
      return alert('Please check password');
    }

    if (newPassword && newPassword === confirmPassword && newPassword !== oldPassword) {
      this.props.handleChangePassword(newPassword, success => {
        if (success) {
          this.setState({ isOpenModal: !success });
        }
      });
    } else {
      return alert('Please check the new password');
    }
  };

  render() {
    const {
      isOpenModal,
      oldPassword,
      newPassword,
      confirmPassword,
      accountInformation,
      imagesList,
      data,
      videosList,
      isLoadingSuccess,
    } = this.state;
    const { t } = this.props;
    return (
      <div className="dashboard-page">
        <header>
          <MainHeader activeTab="Dashboard" />
        </header>
        <div>
          <InfoCard accountInformation={accountInformation} />
        </div>

        {isLoadingSuccess && <ShowMakerting data={data} imagesList={imagesList} videosList={videosList} />}
        <br />
        <br />
        <ChangePassword
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          isOpenModal={isOpenModal}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
        />
      </div>
    );
  }
}

DashBoardPage.propTypes = {
  history: PropTypes.object.isRequired,
  handleChangePassword: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  s3Config: makeGetS3Config() || {},
  selfCareConfig: makeGetSelfCareConfig() || {},
  accountInformation: makeGetAccountInformation() || {},
});

const withConnect = connect(mapStateToProps, {
  handleChangePassword,
  getAccountInformation,
  getTenantSelfCareConfig,
  readFileFromS3,
  isConnecting,
  isEndConnected,
});

const withReducer = injectReducer({ key: 'dashBoardPageReducer', reducer });
const withSaga = injectSaga({ key: 'dashBoardPageSaga', saga });

export default withTranslation('common')(compose(withReducer, withSaga, withConnect)(DashBoardPage));
