export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const URL_PAYMENT =
  'http://payment-gateway.dev.k8s.congerocloud.net/api/v1/braintree/vault?callbackUrl=';

export const typePermissons = {
  readOnly: 'READ_ONLY',
  readWrite: 'READ_WRITE',
  none: 'NONE',
};

export const groupNamePermissions = {
  createUser: 'CREATE_USER',
  processAccount: 'PROCESS_ACCOUNT',
  searchOffers: 'SEARCH_OFFERS',
  addCreditCard: 'ADD_CREDIT_CARD',
  modifyCreditCard: 'MODIFY_CREDIT_CARD',
  modifyOffers: 'MODIFY_OFFERS',
  viewSubscription: 'VIEW_SUBSCRIPTION',
  viewBalances: 'VIEW_BALANCES',
  viewBills: 'VIEW_BILLS',
  viewInvoice: 'VIEW_INVOICE',
  viewTransactions: 'VIEW_TRANSACTIONS',
  applyPayment: 'APPLY_PAYMENT',
  modifyUser: 'MODIFY_USER',
  modifyContact: 'MODIFY_CONTACT',
  thirdPartySelfCare: 'THIRD_PARTY_SELF_CARE',
  PROCESS_ACCOUNT: 'PROCESS_ACCOUNT',
};
