import _ from 'lodash';
import dataEnum from './dataEnum';

const tranformObjToArr = obj => _.map(obj, n => ({ value: n, label: n }));
export default {
  transactionType: tranformObjToArr(dataEnum.transactionType),
  makePayment: tranformObjToArr(dataEnum.makePayment),
  serviceType: tranformObjToArr(dataEnum.serviceType),
  arOpsType: tranformObjToArr(dataEnum.arOpsType),
  taxRule: tranformObjToArr(dataEnum.taxRule),
  CRDRType: tranformObjToArr(dataEnum.CRDRType),
  source: tranformObjToArr(dataEnum.source),
  status: tranformObjToArr(dataEnum.status),
  phoneType: tranformObjToArr(dataEnum.phoneType),
};
