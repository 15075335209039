/*
 *
 * ViewInvoices constants
 *
 */

export const GET_PENDING_BILL = 'app/ViewInvoicesPage/getPendingBill';
export const GET_PENDING_BILL_SUCCESS =
  'app/ViewInvoicesPage/getPendingBillSuccess';

export const GET_OC_BILL = 'app/ViewInvoicesPage/getOCBill';
export const GET_OC_BILL_SUCCESS = 'app/ViewInvoicesPage/getOCBillSuccess';
