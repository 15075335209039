import React from 'react';
import PropTypes from 'prop-types';

const SwitchExport = ({ title, wrapperClass, onChange, name, rightTitle, ...props }) => (
  <div
    className={`checkbox checbox-switch ${wrapperClass || ''} ${
      props.checked ? 'switch-export-active' : 'switch-export-non-active'
    }`}
  >
    <b>{title || ''}</b>
    <label htmlFor={name || 'switch'}>
      <input id={name || 'switch'} type="checkbox" name={name} onChange={onChange} {...props} />
      <span className="label-switch" />
    </label>
    <b>{rightTitle || ''}</b>
  </div>
);

SwitchExport.propTypes = {
  title: PropTypes.string,
  rightTitle: PropTypes.string,
  name: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
};

SwitchExport.defaultProps = {
  onChange: () => {},
  title: '',
  rightTitle: '',
  name: '',
  wrapperClass: '',
};
export default SwitchExport;
