import React from 'react';
import PropTypes from 'prop-types';
import { filter, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { dataSelect } from '../../../constantsApp';
import InputGroup from '../InputGroup';
import SelectGroup from '../SelectGroup';

const PhoneGroup = ({
  colDiv,
  onChange,
  dataPhone,
  isDisabled,
  isNewAccount,
  indexForm,
  modeModifyPhone,
  modeAddPhone,
  modeDeletePhone,
  isDetailModal,
}) => {
  const { t } = useTranslation(['common', 'selections']);
  if (modeModifyPhone === 0) return '';
  return (
    <div className={`${colDiv} ${!isNewAccount ? 'detail-phone' : ''}`}>
      <div className={`col-md-12 ${isDetailModal ? 'p-0' : ''}`} style={{ float: 'right' }}>
        {map(dataPhone, (phone, index) => (
          <div className="phone-group" key={index}>
            <InputGroup
              value={phone.number || ''}
              label={index === 0 ? t('label.phone') : ''}
              type="text"
              onChange={evt => onChange({ value: evt.target.value, fieldName: evt.target.name, index, indexForm })}
              name="number"
              wrapperClass={`col-md-${isDisabled ? '6' : '5'}`}
              disabled={modeModifyPhone === 1 || isDisabled}
              required={isNewAccount}
            />
            <SelectGroup
              label={index === 0 ? t('label.type') : ''}
              value={filter(t('selections:phoneType')(), data =>
                data.value === phone.type ? dataSelect.phoneType : null
              )}
              wrapperClass={`col-md-${isDisabled ? '6' : '5'}`}
              options={dataSelect.phoneType.map(item => ({
                value: item.value,
                label: t('selections:phoneType')().find(val => val.value === item.label).label,
                isDisabled: dataPhone.map(item => item.type).indexOf(item.value) > -1,
              }))}
              onChange={val => onChange({ value: val.value, fieldName: 'type', index, indexForm })}
              name="Type"
              required
              isDisabled={modeModifyPhone === 1 || isDisabled}
            />
            {!isDisabled && modeDeletePhone !== 0 && (
              <button
                type="button"
                className={`${index === 0 ? 'btn-first-phone' : 'btn-phone'}`}
                disabled={modeModifyPhone === 1 || modeDeletePhone === 1}
                onClick={() => onChange({ isRemovePhone: true, index, indexForm })}
              >
                <i className="fa fa-trash" />
              </button>
            )}
          </div>
        ))}
        {!isDisabled && modeAddPhone !== 0 && (
          <button
            type="button"
            disabled={modeModifyPhone === 1 || modeAddPhone === 1}
            className="button x-small float-right"
            onClick={() => onChange({ isNewPhone: true, indexForm })}
          >
            {t('label.addPhone')}
          </button>
        )}
      </div>
    </div>
  );
};

PhoneGroup.propTypes = {
  dataPhone: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  colDiv: PropTypes.string,
  isDisabled: PropTypes.bool,
  isNewAccount: PropTypes.bool,
  isDetailModal: PropTypes.bool,
  indexForm: PropTypes.number,
  modeModifyPhone: PropTypes.number,
  modeAddPhone: PropTypes.number,
  modeDeletePhone: PropTypes.number,
};

PhoneGroup.defaultProps = {
  dataPhone: [],
  onChange: () => {},
  colDiv: '',
  isDisabled: false,
  isNewAccount: false,
  isDetailModal: false,
  indexForm: 0,
  modeModifyPhone: 2,
  modeAddPhone: 2,
  modeDeletePhone: 2,
};

export default PhoneGroup;
