import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { Accumulators, Currency, Grants } from '../../components/ManageBalance';
import { AccountHeaderForm } from '../../components/common';
import { getAccountId } from '../../localStorage';
import { MainHeader } from '../../components/Header';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { checkPermissionViewBalances } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare } from '../App/selectors';
import { makeGetBalanceUnit, makeErrorMessageBU } from './selectors';
import { getBalanceUnit } from './actions';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';

/* eslint-disable react/prefer-stateless-function */
export class ManageBalances extends React.Component {
  componentDidMount = () => {
    this.props.getBalanceUnit(getAccountId());
  };

  render() {
    const { makeErrorMessageBU, makeGetBalanceUnit, permissionsSelfCare, t } = this.props;
    let modeViewBalances = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewBalances = checkPermissionViewBalances({
        listPermission,
      });
    }
    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="Billing Data" />
        {modeViewBalances !== 0 && (
          <div className="manage-page__form">
            <div className="form-wrapper table-content">
              <div className="table-title table-title-form table-title-form-detail">
                <Row>
                  <Col md={12}>
                    {/* <h4 className="card-title-mb">
                      {`${t('label.balances')} | ${t('label.accountNumber')}`}
                      <span className="account-number">
                        <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                        &nbsp;&nbsp;
                        <span className="account-detail">{getAccountId()}</span>
                      </span>
                    </h4> */}
                    <AccountHeaderForm title={`${t('label.balances')} | ${t('label.accountNumber')}`} />
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <div className="card-body" style={{ paddingLeft: 0 }}>
                    <div className="card-header" id="heading1">
                      <h5 className="mb-0 ml-2 header-table">{t('label.currency')}</h5>
                    </div>
                    <Col md={12}>
                      <Currency
                        data={makeGetBalanceUnit && makeGetBalanceUnit.balances ? makeGetBalanceUnit.balances : []}
                        errorSubscription={makeErrorMessageBU}
                      />
                    </Col>
                  </div>
                  <div className="card-body" style={{ paddingLeft: 0 }}>
                    <div className="card-header" id="heading1">
                      <h5 className="mb-0 ml-2 header-table">{t('label.grants')}</h5>
                    </div>
                    <Col md={12}>
                      <Grants
                        data={makeGetBalanceUnit && makeGetBalanceUnit.grants ? makeGetBalanceUnit.grants : []}
                        errorServiceUnits={makeErrorMessageBU}
                      />
                    </Col>
                  </div>
                  <div className="card-body" style={{ paddingLeft: 0 }}>
                    <div className="card-header" id="heading1">
                      <h5 className="mb-0 ml-2 header-table">{t('label.accumulators')}</h5>
                    </div>
                    <Col md={12}>
                      <Accumulators
                        data={
                          makeGetBalanceUnit && makeGetBalanceUnit.accumulators ? makeGetBalanceUnit.accumulators : []
                        }
                        errorServiceUnits={makeErrorMessageBU}
                      />
                    </Col>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ManageBalances.propTypes = {
  makeGetBalanceUnit: PropTypes.object,
  makeErrorMessageBU: PropTypes.string,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  makeGetBalanceUnit: makeGetBalanceUnit(),
  makeErrorMessageBU: makeErrorMessageBU(),
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    getBalanceUnit: (data, cb) => dispatch(getBalanceUnit(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'manageBalances', reducer });
const withSaga = injectSaga({ key: 'manageBalances', saga });

export default withTranslation('common')(compose(withReducer, withSaga, withConnect)(ManageBalances));
