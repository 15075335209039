import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the manageCreditCard state domain
 */

const selectManageCreditCardDomain = state =>
  state.get('manageCreditCard', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageCreditCard
 */

const makeSelectManageCreditCard = () =>
  createSelector(selectManageCreditCardDomain, substate => substate.toJS());

export default makeSelectManageCreditCard;
export { selectManageCreditCardDomain };
