import { call, takeLatest, put } from 'redux-saga/effects';
import { mutationRequest, queryRequest } from '../../utils/request';
import { push } from 'react-router-redux';
import { processNewAccount, searchBundles } from '../../api';
import {
  resetNotification,
  setNotification,
} from '../Notification/actions';
import { isConnecting, isEndConnected } from '../Loader/actions';
import { NotificationTypes } from '../../constantsApp';
import { CREATE_SELF_CARE_ACCOUNT, SEARCH_BUNDLES } from './types';

export function* createSelfCareSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    yield call(mutationRequest, processNewAccount(payload));
    yield put(isEndConnected());
    yield put(
      setNotification({
        type: NotificationTypes.success,
        message: 'create Self-care Account successfully!',
      }),
    );
    yield put(push('/login'));
  } catch (err) {
    yield put(isEndConnected());
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `create Self-care Account failure! ${err}`,
      }),
    );
    if (cb) yield cb();
  }
}

export function* searchBundlesSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, searchBundles(payload));
    if (response.searchBundles) {
      if (cb) cb({ success: true, data: response.searchBundles });
    }
  } catch (err) {
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `Search Bundles failure! ${err}`,
      }),
    );
    if (cb) cb({ success: false, data: [] });
  }
}

export default function* registerSaga() {
  yield takeLatest(CREATE_SELF_CARE_ACCOUNT, createSelfCareSaga);
  yield takeLatest(SEARCH_BUNDLES, searchBundlesSaga);
}
