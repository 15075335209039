import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, redFontPdfFile, totalFontPdfFile } from '../utils';

const HeaderTable = ({ t, customerInfo }) => {
  let columnsList = [];
  let columnCurrency = [];
  if (customerInfo?.type === 'CLIENT') {
    columnCurrency = [t('label.invoiceCurrency')];
    columnsList = [t('label.totalFunctionalCurrencyExport'), t('label.functionalCurrency'), t('label.exchangeRate')];
  }
  return [
    t('label.accountId'),
    t('label.documentType'),
    t('label.invoiceId'),
    t('label.invoiceDate'),
    ...columnCurrency,
    t('label.subTotal'),
    t('label.taxes'),
    t('label.total'),
    ...columnsList,
    t('label.dueDate'),
    t('label.folioId'),
    t('label.folioDate'),
    t('label.folioStatus'),
    t('label.status'),
    t('label.openingBalance'),
    t('label.closingBalance'),
  ];
};

const convertJson2Pdf = ({ data, t, title, fileName, customerInfo, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, customerInfo }));
  let totalAmount = 0;
  let totalFunctionalCurrency = 0;
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.accountId ? item.accountId : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'lineType', fieldOption: 'accountStatementLineType' }));
      newItem.push(item.invoiceId ? item.invoiceId : '');
      newItem.push(item.invoiceDate ? item.invoiceDate : '');
      if (customerInfo?.type === 'CLIENT') {
        newItem.push(item.currency ? item.currency : '');
      }
      newItem.push({ content: item.subTotal ? item.subTotal : '', styles: item.subTotal < 0 ? redFontPdfFile : null });
      newItem.push({ content: item.taxes ? item.taxes : '', styles: item.taxes < 0 ? redFontPdfFile : null });
      newItem.push({ content: item.amount ? item.amount : '', styles: item.amount < 0 ? redFontPdfFile : null });
      totalAmount += Number.parseFloat(item.amount || 0);
      if (customerInfo?.type === 'CLIENT') {
        newItem.push({
          content: item.amountInFunctionalCurrency ? item.amountInFunctionalCurrency : '',
          styles: item.amountInFunctionalCurrency < 0 ? redFontPdfFile : null,
        });
        totalFunctionalCurrency += Number.parseFloat(item.amountInFunctionalCurrency || 0);

        newItem.push(item.functionalCurrency ? item.functionalCurrency : '');
        newItem.push({
          content: item.exchangeRate ? item.exchangeRate : '',
          styles: item.exchangeRate < 0 ? redFontPdfFile : null,
        });
      }

      newItem.push(item.dueDate ? item.dueDate : '');
      newItem.push(item.lineReferenceId ? item.lineReferenceId : '');
      newItem.push(item.lineDate ? item.lineDate : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'folioStatus', fieldOption: 'folioStatus' }));
      newItem.push(item.status ? item.status : '');
      if (customerInfo?.type === 'CLIENT') {
        newItem.push('');
        newItem.push('');
      } else {
        newItem.push({
          content: item.openingBalance ? item.openingBalance : '',
          styles: item.openingBalance < 0 ? redFontPdfFile : null,
        });
        newItem.push({
          content: item.closingBalance ? item.closingBalance : '',
          styles: item.closingBalance < 0 ? redFontPdfFile : null,
        });
      }
    }
    return newItem;
  });

  if (newListData && newListData.length && customerInfo?.type === 'CLIENT') {
    const newTotalItem = newListData[0].map((val, index) => {
      if (customerInfo?.type === 'CLIENT' && index === 7) {
        return { content: totalAmount, styles: totalFontPdfFile };
      }
      if (customerInfo?.type === 'CLIENT' && index === 8) {
        return { content: totalFunctionalCurrency, styles: totalFontPdfFile };
      }
      return { content: '' };
    });
    newListData.push(newTotalItem);
  }
  return pdfConfig({ data: newListData, Header, title, fileName, ...rest });
};

export default convertJson2Pdf;
