import gql from 'graphql-tag';

export default ({ page, userId }) => {
  const getSelfCareOffers = `getSelfCareOffers(page: ${page}, size: 9, input: { userId: "${userId}", action: CREATE })`;
  return gql`
    query {
      ${getSelfCareOffers} {
        packages {
          packageId
          status
        }
        bundles {
          bundleId
          status
        }
    }
  }
  `;
};
