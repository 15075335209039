import gql from 'graphql-tag';
import { parseToMutationRequest } from '../utils/utils';
import { getUserName } from '../localStorage';

export default data => {
  const newData = data;
  newData.userId = getUserName() || newData.userId || '';
  const dataCreate = parseToMutationRequest(newData, [
    'amount',
    'cardOnFile',
    'storeAsPrimary',
    'method',
    'source',
    'exchangeRateForReport',
  ]);
  const applyPayment = `applyPayment(input: ${dataCreate})`;
  return gql`
  mutation {
    ${applyPayment} {
      id
    }
  }
`;
};
