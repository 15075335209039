import gql from 'graphql-tag';

export default id => gql`
  query {
    getAccountInformation(input: { id: "${id}" }) {
      invoiceId
      invoiceDate
      invoiceDueDate
      lastPaymentDate
      currentBalance
      lastBillTotal
      totalCreditAr
      totalDebitAr
      invoiceTotal
      invoiceDue
      lastPaymentAmount
    }
  }
`;
