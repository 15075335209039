import gql from 'graphql-tag';

export default () => gql`
  query {
    getConfigFailureCodes(dummy: "") {
      configFailureCodesList {
        value
        description
      }
    }
  }
`;
