import gql from 'graphql-tag';

export default () => {
  const getCcpProperties = 'getCcpProperties(dummy: "")';
  return gql`
    query {
      ${getCcpProperties} {
        ccpPropertyList {
          value
          property
        }
      }
    }
  `;
};
