import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../common/SearchComponent';

class OrdersSearchForm extends SearchComponent {
  searchInputs = [
    // {
    //   label: 'common:label.accountId',
    //   name: 'accountId',
    // },
    // {
    //   label: 'common:label.userId',
    //   name: 'userId',
    // },
    // {
    //   label: 'common:label.type',
    //   name: 'type',
    //   isClearable: true,
    //   tOptions: 'selections:types',
    //   type: 'select',
    // },
    // {
    //   label: 'common:label.status',
    //   name: 'status',
    //   tOptions: 'selections:statusOrder',
    //   type: 'select',
    //   isClearable: true,
    // },
    {
      label: 'common:label.effectiveDate',
      name: 'effectiveDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.submittedDate',
      name: 'submittedDate',
      type: 'date',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-6';
}

OrdersSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OrdersSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OrdersSearchForm);
