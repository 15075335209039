import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DataTable } from '../common';
import RouteNames from '../../containers/App/RouteNames';

class OCBillsTable extends Component {
  doGetObjectFileById = invoiceId => {
    const { getObjectFileById, history } = this.props;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath('/invoices/:id', invoiceId),
          state: {
            fromPath: '/view-invoices',
            fileData: data,
          },
        });
      }
    });
  };

  render() {
    const { data, modeViewInvoice, currency } = this.props;
    let dataOCBills = [];
    if (data && data.billInvoiceData) {
      dataOCBills = data.billInvoiceData;
    }

    const heads = [
      {
        name: 'accountId',
        label: 'label.accountId',
      },
      {
        name: 'startDate',
        label: 'label.startDate',
      },
      {
        name: 'endDate',
        label: 'label.endDate',
      },
      {
        name: 'billTotal',
        label: 'label.billTotal',
      },
      {
        name: 'billStatus',
        label: 'label.status',
      },
      {
        name: 'due',
        label: 'label.due',
      },
      {
        name: 'dueDate',
        label: 'label.dueDate',
      },
      {
        name: 'invoiceNumber',
        label: 'label.invoiceNumber',
        render: (colName, item) => {
          if (!item.invoiceUnitId || !modeViewInvoice) return '';
          return (
            <button
              className="btn btn-outline-success btn-sm btn-view-file"
              type="button"
              onClick={() => this.doGetObjectFileById(item.invoiceUnitId)}
            >
              {item.invoiceUnitId}
            </button>
          );
        },
      },
      {
        name: 'invoiceStatus',
        label: 'label.invoiceStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:invoiceStatus')().find(val => val.value === item.invoiceStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'type',
        label: 'label.invoiceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:invoiceUnitType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'invoiceCurrency',
        label: 'label.invoiceCurrency',
        render: () => {
          return <span>{currency || ''}</span>;
        },
      },
    ];
    return <DataTable columns={heads} data={dataOCBills || []} isFixedHeaderTable />;
  }
}

OCBillsTable.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
};

export default withTranslation('common')(OCBillsTable);
