import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { MainHeader } from '../../components/Header';
import { ManageCreditCard } from '../../components/ManageCreditCard';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { getAccountId } from '../../localStorage';
import { checkPermissionModifyCreditCard, checkPermissionAddCreditCard } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare } from '../App/selectors';
import makeSelectManageProfilePage from './selectors';
import { getAccountById, manageAccount } from './actions';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';

/* eslint-disable react/prefer-stateless-function */
export class ManageCreditCardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.props.getAccountById(
      {
        id: getAccountId(),
      },
      ({ success, data }) => {
        if (success) {
          this.setState({
            account: data,
          });
        }
      }
    );
  };

  manageAccount = (value, cb) => {
    this.props.manageAccount(value, ({ success }) => {
      if (success) {
        this.getData();
      }
      cb(success);
    });
  };

  renderContent() {
    const { account } = this.state;
    const { permissionsSelfCare } = this.props;
    let modeModifyCreditCard = 0;
    let modeAddCreditCard = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeModifyCreditCard = checkPermissionModifyCreditCard({
        listPermission,
      });
      modeAddCreditCard = checkPermissionAddCreditCard({
        listPermission,
      });
    }
    return (
      <ManageCreditCard
        account={account}
        manageAccount={this.manageAccount}
        modeModifyCreditCard={modeModifyCreditCard}
        modeAddCreditCard={modeAddCreditCard}
        history={this.props.history}
      />
    );
  }

  render() {
    return (
      <div className="manage-credit-card-page">
        <MainHeader activeTab="MakePayment" />
        <div className="manage-credit-card-page__form">
          <div className="form-wrapper cardtop-credit-card">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

ManageCreditCardPage.propTypes = {
  getAccountById: PropTypes.func,
  manageAccount: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  manageProfilePage: makeSelectManageProfilePage(),
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

const withConnect = connect(mapStateToProps, { getAccountById, manageAccount });

const withReducer = injectReducer({ key: 'manageCreditCard', reducer });
const withSaga = injectSaga({ key: 'manageCreditCard', saga });

export default compose(withReducer, withSaga, withConnect)(ManageCreditCardPage);
