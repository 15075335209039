import React from 'react';
import PropsType from 'prop-types';
import { map, isEmpty } from 'lodash';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Col, Input } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import GenericInput from '../GenericInput';

const ModalDetailsItem = ({ title, isOpen, onToggle, data, dataField, isSearch, children }) => {
  const { t } = useTranslation('common');
  return (
    <Modal isOpen={isOpen} toggle={onToggle} className="modal-xl info-form">
      <ModalHeader>{t(title)}</ModalHeader>
      <ModalBody>
        {isSearch ? (
          <div className="text-center">
            <PulseLoader size={13} color="#28a745" />
          </div>
        ) : (
          <div className="row col-md-12 p-0 m-0">
            {!isEmpty(dataField) &&
              map(dataField, item => {
                if (item && item.isSelect) {
                  return (
                    <Col md={item.col || 4}>
                      <FormGroup>
                        <Label for="country">{t(item.label)}</Label>
                        <Select
                          value={
                            item.tOptions
                              ? t(item.tOptions)().find(val => val.value === data[item.name])
                              : {
                                  value: data[item.name],
                                  label: data[item.name],
                                } || null
                          }
                          options={[]}
                          onChange={() => {}}
                          className="form__form-group-select"
                          inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'off',
                          }}
                        />
                      </FormGroup>
                    </Col>
                  );
                }

                if (item && item.fieldDate) {
                  return (
                    <Col md={item.col || 4}>
                      <FormGroup>
                        <Label for="country">{t(item.label)}</Label>
                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={data[item.name] || null}
                          onChange={() => {}}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  );
                }

                return (
                  <GenericInput
                    type={item.type || 'text'}
                    name="lastName"
                    id="lastName"
                    value={data[item.name] || null}
                    onChange={() => {}}
                    readOnly
                    wrapperClass={item.wrapperClass || 'col-md-4'}
                    {...item}
                  />
                );
              })}
          </div>
        )}
        {children}
      </ModalBody>
      <ModalFooter>
        <Button color="" className="btn btn-submit" onClick={onToggle}>
          {t('label.OK')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalDetailsItem.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

ModalDetailsItem.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

export default ModalDetailsItem;
