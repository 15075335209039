/*
 *
 * ManageProfilePage actions
 *
 */

import * as types from './constants';

export function getAccountStatement(payload, cb) {
  return {
    type: types.GET_ACCOUNT_STATEMENT,
    payload,
    cb,
  };
}

export function getAllAccountStatement(payload, cb) {
  return {
    type: types.GET_ALL_ACCOUNT_STATEMENT,
    payload,
    cb,
  };
}

export function getAllAccountStatementSuccess(payload, cb) {
  return {
    type: types.GET_ALL_ACCOUNT_STATEMENT_SUCCESS,
    payload,
    cb,
  };
}

export function getClientAccountStatement(payload, cb) {
  return {
    type: types.GET_CLIENT_ACCOUNT_STATEMENT,
    payload,
    cb,
  };
}

export function getAllClientAccountStatement(payload, cb) {
  return {
    type: types.GET_ALL_CLIENT_ACCOUNT_STATEMENT,
    payload,
    cb,
  };
}
