import { call, put, takeLatest } from 'redux-saga/effects';
import { queryRequest } from '../../utils/request';
// import { push } from 'react-router-redux';
import { getAccountStatement, getClientAccountStatement } from '../../api';
import { resetNotification, setNotification } from '../Notification/actions';
import { NotificationTypes } from '../../constantsApp';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './constants';
import * as actions from './actions';

export function* getAccountStatementSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getAccountStatement(payload));
    if (response.getAccountStatement) {
      if (cb) cb({ success: true, data: response.getAccountStatement });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getAllAccountStatementSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountStatement(payload));
    if (response.getAccountStatement && response.getAccountStatement) {
      yield put(actions.getAllAccountStatementSuccess(response.getAccountStatement));
      if (cb) yield cb({ success: true, data: response.getAccountStatement });
    } else {
      yield put(actions.getAllAccountStatementSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllAccountStatementSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getClientAccountStatementSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getClientAccountStatement(payload));
    if (response.getClientAccountStatement) {
      if (cb) cb({ success: true, data: response.getClientAccountStatement.accountStatement });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getAllClientAccountStatementSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getClientAccountStatement(payload));
    if (response.getClientAccountStatement) {
      yield put(actions.getAllAccountStatementSuccess(response.getClientAccountStatement.accountStatement));
      if (cb) cb({ success: true, data: response.getClientAccountStatement.accountStatement });
    } else {
      yield put(actions.getAllAccountStatementSuccess([]));
      if (cb) cb({ success: false, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllAccountStatementSuccess([]));
    if (cb) cb({ success: false, data: [] });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* viewTransactionsPageSaga() {
  yield takeLatest(types.GET_ACCOUNT_STATEMENT, getAccountStatementSaga);
  yield takeLatest(types.GET_ALL_ACCOUNT_STATEMENT, getAllAccountStatementSaga);
  yield takeLatest(types.GET_CLIENT_ACCOUNT_STATEMENT, getClientAccountStatementSaga);
  yield takeLatest(types.GET_ALL_CLIENT_ACCOUNT_STATEMENT, getAllClientAccountStatementSaga);
}
