import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from 'reactstrap';

const itemChangePassword = [
  {
    key: 'oldPassword',
    name: 'Old Password',
  },
  {
    key: 'newPassword',
    name: 'New Password',
  },
  {
    key: 'confirmPassword',
    name: 'Confirm New Password',
  },
];

class ChangePassword extends React.PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  renderItemChangePassword = item => (
    <Input
      key={item.key}
      style={{ margin: 5 }}
      className={`"mx-1" ${this.props[item.key] ? 'input-successed' : ''}`}
      type="password"
      value={this.props[item.key] || ''}
      placeholder={item.name}
      onChange={evt => this.props.handleChange(evt, item.key)}
    />
  );

  render() {
    return (
      <div className="global-page customer-page">
        <Modal isOpen={this.props.isOpenModal}>
          {/* <ModalHeader>Change password on the first time</ModalHeader> */}
          <ModalHeader>
            You must change your password on the first time
          </ModalHeader>
          <ModalBody>
            {itemChangePassword.map(item =>
              this.renderItemChangePassword(item),
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.props.handleSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
ChangePassword.propTypes = {
  isOpenModal: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default ChangePassword;
