import gql from 'graphql-tag';

export default () => {
  const getTenantSelfCareConfig = 'getTenantSelfCareConfig(dummy: "")';
  return gql`
    query {
      ${getTenantSelfCareConfig} {
        id
        tenantId
        keyLinks {
          index
          text
          url   
        }
        advertisements{
          index
          header
          shortDescription
        }
        mediaFiles {
          index
          title
          type
          name
          path
        }
      }
    }
  `;
};
