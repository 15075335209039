import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from '../../../utils/utils';
import CheckField from '../CheckField';
import DataTable from '../DataTable/Table';
import Pagination from '../Pagination';
import Sizing from '../Sizing';
// import TablePagination from '../DataTable/TablePagination';

class ModalSelector extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listItems: [],
    selectedParentId: null,
    rowItem: null,
    initSelectedParentId: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.initSelectedParentId === props.selectedParentId) return null;
    return {
      selectedParentId: props.selectedParentId,
      initSelectedParentId: props.selectedParentId,
      isMultiple: props.isMultiple,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isNeedFetch, isOpen } = this.props;
    if ((isNeedFetch && isNeedFetch !== prevProps.isNeedFetch) || (isOpen && prevProps.isOpen !== isOpen))
      this.doSearchItems();
  }

  onSelectParent = e => {
    e.preventDefault();
    const { onSelect } = this.props;
    const { selectedParentId } = this.state;
    if (onSelect) {
      this.setState({ filter: {} });
      onSelect(selectedParentId, this.state.rowItem);
      this.setState({ filter: {}, selectedParentId: null });
    }
  };

  onCancel = e => {
    e.preventDefault();
    const { onCancel } = this.props;
    if (onCancel) {
      this.setState({ filter: {} });
      onCancel(false);
    }
  };

  onUnselectParent = e => {
    e.preventDefault();
    const { onSelect, isRequired } = this.props;
    if (isRequired) return false;
    this.setState({ selectedParentId: null });
    if (onSelect) {
      this.setState({ filter: {} });
      onSelect(null);
    }
  };

  onClickRow = (e, rowItem) => {
    e.preventDefault();
    this.setState({
      selectedParentId: rowItem[this.props.selectFieldName],
      rowItem,
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchItems());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchItems());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchItems());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchItems());
  };

  doSearchItems = () => {
    const { page, size, sort, filter = {}, totalCount, sorted } = this.state;
    this.setState({ isSearching: true });
    const { arraySort, defaultSearchParams } = this.props;
    let filteredParams = { ...filter };
    if (defaultSearchParams) {
      filteredParams = { ...filteredParams, ...defaultSearchParams };
    }
    this.props.searchItems(
      {
        page: page + 1,
        size,
        filter: filteredParams,
        sort: !isEmpty(sorted) && !isEmpty(arraySort) ? arraySort[sorted.sortCol][sorted.sortDir] : null,
      },
      resp => {
        const listItems = resp.success ? resp.data : [];
        this.setState({
          isSearching: false,
          filter,
          sort,
          listItems,
          totalCount: getPageTotalCount({
            page,
            size,
            totalCount,
            items: listItems,
          }),
        });
      }
    );
  };

  render() {
    const { listItems, sorted } = this.state;
    const { tableColumns, modalTitle, SearchForm, isRequired, toggleModal, t, isNotRenderPagination } = this.props;
    const columns = [
      {
        name: 'action',
        label: 'label.select',
        render: (colName, item) => (
          <CheckField
            name="selectedParentId"
            value={item[this.props.selectFieldName]}
            checked={item[this.props.selectFieldName] === this.state.selectedParentId}
            readOnly
          />
        ),
      },
      ...tableColumns,
    ];
    const tableConfig = {
      columns,
      data: listItems,
      getRowClass: item => (item[this.props.selectFieldName] === this.state.selectedParentId ? 'selected-row' : ''),
      sorted,
    };
    return (
      <Modal size="" className="modal-xl" isOpen={this.props.isOpen} toggle={toggleModal}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          {SearchForm && (
            <div className="col-md-12 p-0 mb-30">
              <div className="card card-statistics h-100">
                <div className="">
                  <div className="repeater-file">
                    <div data-repeater-list="group-a">
                      <SearchForm onSubmit={this.onSubmitSearch} isSubmitting={this.state.isSearching} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-sm-12 p-0 mb-30">
            <div className="card card-statistics h-100">
              <div className="">
                <DataTable
                  {...tableConfig}
                  onClickRow={this.onClickRow}
                  nullIsLoading={false}
                  isLoading={this.state.isSearching}
                  onSort={this.onSortColumn}
                  fixedHeader
                  tableClass="card-table-body"
                />
              </div>
            </div>
          </div>
          {/* {!isNotRenderPagination && (
            <div className="mb-30">
              <TablePagination
                pageNumber={this.state.page}
                pageSize={this.state.size}
                totalCount={this.state.totalCount}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
              />
            </div>
          )} */}

          <Pagination
            page={this.state.page}
            isActivePre={this.state.page !== 0}
            isActiveNext={this.state.size === listItems.length}
            handlePage={this.onPageChange}
          />
          <Sizing handleSize={this.onSizeChange} size={this.state.size} />
          <ModalFooter className="text-right">
            <button type="button" className="btn btn-secondary" onClick={this.onCancel}>
              {t('label.cancel')}
            </button>
            {!isRequired && (
              <button type="button" className="btn btn-primary" onClick={this.onUnselectParent}>
                {t('label.unselect')}
              </button>
            )}

            <button type="button" className="btn btn-success" onClick={this.onSelectParent}>
              {t('label.select')}
            </button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  }
}

ModalSelector.propTypes = {
  selectFieldName: PropTypes.string,
  isOpen: PropTypes.bool,
  isRequired: PropTypes.bool,
  isMultiple: PropTypes.bool,
  selectedParentId: PropTypes.any,
  tableColumns: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchItems: PropTypes.func.isRequired,
  SearchForm: PropTypes.any,
  defaultSearchParams: PropTypes.any,
};
ModalSelector.defaultProps = {
  selectFieldName: 'id',
  isRequired: true,
  isMultiple: false,
};

export default withTranslation('common')(ModalSelector);
