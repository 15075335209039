import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DataTable } from '../common';

const heads = [
  {
    name: 'accumulatorId',
    label: 'label.id',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
  {
    name: 'startDate',
    label: 'label.validityStart',
  },
  {
    name: 'endDate',
    label: 'label.validityEnd',
  },
];

class TableServiceUnits extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { data, title } = this.props;
    return <DataTable columns={heads} data={data || []} isFixedHeaderTable />;
  }
}

TableServiceUnits.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  errorServiceUnits: PropTypes.string,
};

export default withTranslation('common')(TableServiceUnits);
