/*
 *
 * ManageProfilePage reducer
 *
 */

import { fromJS } from 'immutable';
import * as types from './constants';

export const initialState = fromJS({
  isFetchingSuccess: false,
  ocBills: {},
  pendingBills: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_PENDING_BILL_SUCCESS:
      return state
        .set('pendingBills', action.payload)
        .set('isFetchingSuccess', true);
    case types.GET_OC_BILL_SUCCESS:
      return state
        .set('ocBills', action.payload)
        .set('isFetchingSuccess', true);
    default:
      return state;
  }
}
