import { fromJS } from 'immutable';
import * as types from './constants';

export const initialState = fromJS({
  isFetchingSuccess: false,
  arOpsUnit: [],
  paramsArOpsUnit: {
    page: 1,
    size: 10,
    filter: {},
    sort: {},
  },
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_AR_OPS_UNIT_SUCCESS:
      return state
        .set('arOpsUnit', action.payload)
        .set('isFetchingSuccess', true);

    case types.SET_AR_OPS_PARAMS: {
      const { page, size } = action.payload;
      return state
        .setIn(['paramsArOpsUnit', 'size'], size)
        .setIn(['paramsArOpsUnit', 'page'], page);
    }
    default:
      return state;
  }
}
