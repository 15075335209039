import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../common/SearchComponent';

class AccountActivitySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-6';
}

AccountActivitySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountActivitySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AccountActivitySearchForm);
