import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { FaMinus, FaPlus } from 'react-icons/fa';

export default class Collapsible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classStatus: 'closed',
    };
  }

  toggle = () => {
    const { onToggleTab, state } = this.props;
    onToggleTab(state);
  };

  onEntering = () => {
    this.setState({ classStatus: 'opening' });
  };

  onEntered = () => {
    this.setState({ classStatus: 'opened' });
  };

  onExiting = () => {
    this.setState({ classStatus: 'closing' });
  };

  onExited = () => {
    this.setState({ classStatus: 'closed' });
  };

  render() {
    const {
      title,
      className,
      isActive,
      children,
      isSubCollapse,
      isActiveSubForm,
    } = this.props;
    const { classStatus, icon } = this.state;
    const Class = className || '';
    return (
      <div
        className={`collapse__wrapper ${classStatus} ${Class} ${
          isActive ? 'active' : ''
        } ${isActiveSubForm ? 'active-sub-from' : ''} ${
          isSubCollapse ? 'sub_collapse' : ''
        }`}
      >
        <div
          role="button"
          onKeyPress={this.toggle}
          tabIndex="0"
          onClick={this.toggle}
          className={isSubCollapse ? 'collapse_sub_title' : 'collapse__title'}
        >
          {icon}
          <div
            className={
              isSubCollapse
                ? 'panel_sub_heading panel_sub_heading--btn'
                : 'panel__heading panel__heading--btn'
            }
          >
            {isActive ? (
              <FaMinus
                color="#28a745"
                className="float-right i-collapsible"
                size={20}
              />
            ) : (
              // <i className="fa fa-plus" />
              <FaPlus
                color="#ffffff"
                className="float-right i-collapsible"
                size={20}
              />
            )}
            &nbsp;
            <span className="panel__title">{title}</span>
          </div>
        </div>
        <Collapse
          isOpen={isActive}
          className="collapse__content"
          onEntering={this.onEntering}
          onEntered={this.onEntered}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <div>{children}</div>
        </Collapse>
      </div>
    );
  }
}

Collapsible.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  isSubCollapse: PropTypes.bool,
  isActiveSubForm: PropTypes.bool,
  onToggleTab: PropTypes.func,
  state: PropTypes.string,
};

Collapsible.defaultProps = {
  title: '',
  isActive: false,
  isSubCollapse: false,
  isActiveSubForm: false,
  className: '',
  state: '',
  // children: React.createElement('div'),
  onToggleTab: () => {},
};
