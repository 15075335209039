import gql from 'graphql-tag';

export default id => {
  const getUserById = `getUserById(input: {id: "${id}"})`;
  return gql`
    query {
      ${getUserById} {
        id
        category
        roleGroups {
          index
          roleGroupId
        }
        roles {
          index
          roleId
        }
      }
    }
  `;
};
