import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the manageBalances state domain
 */

const selectManageBalancesDomain = state =>
  state.get('manageBalances', initialState);

const makeGetBalanceUnit = () =>
  createSelector(selectManageBalancesDomain, customers =>
    customers.get('balanceUnit'),
  );

const makeErrorMessageBU = () =>
  createSelector(selectManageBalancesDomain, customers =>
    customers.get('errorBalanceUnits'),
  );
const makeSelectManageBalances = () =>
  createSelector(selectManageBalancesDomain, substate => substate.toJS());

export default makeSelectManageBalances;
export {
  selectManageBalancesDomain,
  makeGetBalanceUnit,
  makeErrorMessageBU,
  makeSelectManageBalances,
};
