import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Cards from 'react-credit-cards';
import { DataTable } from '../common';
import { getAccountId, getUserName } from '../../localStorage';
import { cardTokenFields, urlGetCreditCard } from '../../constantsApp';
import { getDataCardFromURL } from '../../utils/utils';

class MakePayment extends Component {
  constructor() {
    super();
    this.state = {
      cardToken: '',
      cardExpiry: '',
      last4CC: '',
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { search },
      },
      handleAddCreditCardFromURL,
    } = this.props;
    let token = '';
    let expiryDate = '';
    let last4 = '';
    if (search) {
      token = getDataCardFromURL(search, cardTokenFields.token);
      expiryDate = decodeURIComponent(getDataCardFromURL(search, cardTokenFields.expiryDate));
      last4 = getDataCardFromURL(search, cardTokenFields.last4cc);
    }
    if (token && expiryDate && last4) {
      handleAddCreditCardFromURL({
        accountId: getAccountId(),
        userId: getUserName(),
        creditCard: {
          cardToken: token,
          cardExpiry: expiryDate,
          last4CC: last4,
          merchant: 'qabrain3', // hard code
        },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.length) {
      this.setState({
        cardToken: nextProps.data[0].cardToken,
        cardExpiry: nextProps.data[0].cardExpiry,
        last4CC: nextProps.data[0].last4CC,
      });
    }
  }

  changeText = () => {};

  render() {
    const { cardToken, cardExpiry, last4CC } = this.state;
    const { modeApplyPayment, t, invoiceData, invoiceTableColumns, onClickRow, onPay, totalAmount } = this.props;
    const domCVV = document.querySelector('.rccs__number');
    const domCardExpiry = document.querySelector('.rccs__expiry__valid');
    if (domCardExpiry) document.querySelector('.rccs__expiry__valid').textContent = 'Expiry';
    if (domCVV) document.querySelector('.rccs__number').textContent = `CVV: ${last4CC || ''}`;

    return (
      <div>
        <div className="cardtop">
          <br />
          <h4 className="card-title pl-4 pt-0">{t('label.selectInvoices')}</h4>
          <div className="col-md-12 row">
            <div className="col-md-8">
              <div className="col-md-12">
                <DataTable columns={invoiceTableColumns} data={invoiceData || []} onClickRow={onClickRow} />
                <h4 className="card-title pl-0">
                  {`${t('label.totalSelectedToPay')} :`}
                  <span className="ml-3">{totalAmount}</span>
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body make-payment-card">
                  <ul className="list-inline font-14 card-on-file d-flex">
                    <h4 className="card-title pl-0">{t('label.cardOnFile')}</h4>
                    <button
                      onClick={() => {
                        window.open(`${urlGetCreditCard.URL}${window.location}`, '_self');
                      }}
                      type="button"
                      className="btn-submit btn-rounded waves-effect waves-light ml-auto mt-2 mb-2"
                    >
                      {t('label.addCard')}
                    </button>
                  </ul>
                  <Cards
                    cvc={last4CC || ''}
                    expiry={cardExpiry || ''}
                    focused=""
                    name={`Token: ${cardToken || ''}`}
                    number="asd"
                  />
                  <br />
                  <br />
                  <button
                    type="button"
                    disabled={!invoiceData || !invoiceData.length || !invoiceData.find(val => val.isSelcted)}
                    className="btn-submit btn-rounded waves-effect waves-light m-t-20 ml-0"
                    onClick={onPay}
                  >
                    {t('label.payNow')}
                  </button>
                  &nbsp;
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* <div className="group-payment-control">
            <div className="card-body">
              <form action="#">
                <div className="form-body">
                  <div className="row p-t-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t('label.oneOffAmount')}</label>
                        <Input
                          style={{ marginRight: 20 }}
                          name="One-Off Amount"
                          value={t('label.oneOffAmount')}
                          placeholder={t('label.oneOffAmount')}
                          onChange={this.changeText}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t('label.applyToInvoiceUnitID')}</label>
                        <Input
                          style={{ marginRight: 20 }}
                          name="50.0099"
                          value="50.0099"
                          placeholder="50.0099"
                          onChange={this.changeText}
                        />
                      </div>
                    </div>
                    {modeApplyPayment !== 0 && (
                      <div className="col-md-2">
                        <button
                          type="button"
                          disabled={modeApplyPayment === 1}
                          className="btn-submit btn-apply btn-rounded waves-effect waves-light m-t-20"
                        >
                          {t('label.apply')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

MakePayment.propTypes = {
  history: PropTypes.object,
  data: PropTypes.array,
  handleAddCreditCardFromURL: PropTypes.func,
};

export default withTranslation('common')(MakePayment);
