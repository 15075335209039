import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, []);
  const modifyUser = `modifyUser(input: ${dataCreate})`;
  return gql`
  mutation {
    ${modifyUser} {
      id
      userId
    }
  }
`;
};
