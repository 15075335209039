import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { MainHeader } from '../../components/Header';
import { ManageProfileForm } from '../../components/ManageProfilePage';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { getAccountId, setUserName } from '../../localStorage';
import {
  checkPermissionModifyUser,
  checkPermissionModifyContact,
} from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare } from '../App/selectors';
import makeSelectManageProfilePage from './selectors';
import { getAccountById, manageAccount } from './actions';
import reducer from './reducer';

import saga from './saga';
import './styles.scss';

export class ManageProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.props.getAccountById(
      {
        id: getAccountId(),
      },
      ({ success, data }) => {
        if (success) {
          this.setState({
            profile: data,
          });
        }
      },
    );
  };

  manageAccount = (value, cb) => {
    this.props.manageAccount(value, ({ success }) => {
      if (success) {
        this.getData();
        setUserName(value.userId);
      }
      cb();
    });
  };

  renderContent() {
    const { profile } = this.state;
    const { history, permissionsSelfCare } = this.props;
    let modeModifyUser = 0;
    let modeModifyContact = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeModifyUser = checkPermissionModifyUser({ listPermission });
      modeModifyContact = checkPermissionModifyContact({ listPermission });
    }
    return (
      <ManageProfileForm
        profile={profile}
        manageAccount={this.manageAccount}
        history={history}
        modeModifyUser={modeModifyUser}
        modeModifyContact={modeModifyContact}
      />
    );
  }

  render() {
    return (
      <div className="manage-page global-page profile">
        <MainHeader activeTab="user-profile" />
        <div className="manage-page__form">
          <div className="form-wrapper table-content">
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

ManageProfilePage.propTypes = {
  getAccountById: PropTypes.func,
  manageAccount: PropTypes.func,
  history: PropTypes.object,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  manageProfilePage: makeSelectManageProfilePage(),
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    getAccountById: (data, cb) => dispatch(getAccountById(data, cb)),
    manageAccount: (data, cb) => dispatch(manageAccount(data, cb)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'manageProfilePage', reducer });
const withSaga = injectSaga({ key: 'manageProfilePage', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ManageProfilePage);
