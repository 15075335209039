import React from 'react';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Notification from '../Notification';
import ScrollToTop from './ScrollToTop';
import Router from './Router';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import GlobalStyle from '../../global-styles';
import Loader from '../Loader';
import { checkPermissionForAllPages } from './CheckPermission';
import { makeGetPermissionsReports } from './selectors';

const App = ({ permissionsReports }) => {
  let permissions = {};
  if (permissionsReports && permissionsReports.reportModulePermissions) {
    const listPermission = permissionsReports.reportModulePermissions;
    permissions = checkPermissionForAllPages({ listPermission });
  }
  return (
    <ScrollToTop>
      <ToastContainer timeout={3000} position={toast.POSITION.TOP_CENTER} />
      <Helmet>
        <title>EMBRIX</title>
        <meta name="description" content="EMBRIX Application" />
      </Helmet>
      <GlobalStyle />
      <Notification />
      <Router permissions={permissions} />
      <Loader />
    </ScrollToTop>
  );
};

const mapStateToProps = createStructuredSelector({
  permissionsReports: makeGetPermissionsReports() || {},
});

const withSaga = injectSaga({ key: 'rootSaga', saga });
const withConnect = connect(mapStateToProps, null);

export default compose(withSaga, withConnect)(App);
