import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FaRegEye } from 'react-icons/fa';

class PasswordField extends React.PureComponent {
  state = {
    type: 'password',
  };

  onToggleType = () => {
    const { type } = this.state;
    if (type === 'password') return this.setState({ type: 'text' });
    return this.setState({ type: 'password' });
  };

  render() {
    const { wrapperClass, label, required, onChange, t, ...props } = this.props;
    const { type } = this.state;
    return (
      <div className={`form-group ${wrapperClass}`}>
        {label && (
          <span>
            {t(label)}
            {label && required && <i className="fa fa-star is-required" />}
            &nbsp;
          </span>
        )}
        <div className="wr-input d-flex">
          <input
            className="form-control input-group"
            type={type}
            onChange={evt => onChange({ name: evt.target.name, value: evt.target.value })}
            {...props}
          />
          <button type="button" className="btn-toggle-type" onClick={this.onToggleType}>
            {/* <i className="ti-eye icon-show-password" /> */}
            <FaRegEye className="ti-eye icon-show-password" />
          </button>
        </div>
      </div>
    );
  }
}

PasswordField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

PasswordField.defaultProps = {
  label: '',
  onChange: () => {},
  value: '',
  wrapperClass: 'col-md-3',
  required: false,
};

export default withTranslation('common')(PasswordField);
