import gql from 'graphql-tag';

export default () => gql`
  query {
    getConfigIdo(dummy: "") {
      configIdoList {
        value
        description
      }
    }
  }
`;
