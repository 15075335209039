import { map } from 'lodash';

const tranformObjToArr = obj =>
  map(obj, (value, key) => ({ value: key, label: value }));

const selections = common => ({
  subscriptionStatus: () =>
    tranformObjToArr(common.dropdown.subscriptionStatus),
  termUnit: () => tranformObjToArr(common.dropdown.termUnit),
  trialTermUnit: () => tranformObjToArr(common.dropdown.trialTermUnit),
  trialStatus: () => tranformObjToArr(common.dropdown.trialStatus),
  trialTypes: () => tranformObjToArr(common.dropdown.trialTypes),
  serviceType: () => tranformObjToArr(common.dropdown.serviceType),
  transactionType: () => tranformObjToArr(common.dropdown.transactionType),
  transactionSource: () => tranformObjToArr(common.dropdown.transactionSource),
  accountType: () => tranformObjToArr(common.dropdown.accountType),
  accountSubType: () => tranformObjToArr(common.dropdown.accountSubType),
  marketSegment: () => tranformObjToArr(common.dropdown.marketSegment),
  rateUnit: () => tranformObjToArr(common.dropdown.rateUnit),
  salesChannel: () => tranformObjToArr(common.dropdown.salesChannel),
  timeUnitPricing: () => tranformObjToArr(common.dropdown.timeUnitPricing),
  prorationType: () => tranformObjToArr(common.dropdown.prorationType),
  recurringUnit: () => tranformObjToArr(common.dropdown.recurringUnit),
  recurringType: () => tranformObjToArr(common.dropdown.recurringType),
  billStatus: () => tranformObjToArr(common.dropdown.billStatus),
  invoiceStatus: () => tranformObjToArr(common.dropdown.invoiceStatus),
  invoiceUnitType: () => tranformObjToArr(common.dropdown.invoiceUnitType),
  arOpsType: () => tranformObjToArr(common.dropdown.arOpsType),
  taxRule: () => tranformObjToArr(common.dropdown.taxRule),
  CRDRType: () => tranformObjToArr(common.dropdown.CRDRType),
  source: () => tranformObjToArr(common.dropdown.source),
  arCrDrType: () => tranformObjToArr(common.dropdown.arCrDrType),
  statusCollections: () => tranformObjToArr(common.dropdown.statusCollections),
  billingFrequency: () => tranformObjToArr(common.dropdown.billingFrequency),
  invoiceType: () => tranformObjToArr(common.dropdown.invoiceType),
  invoiceDelivery: () => tranformObjToArr(common.dropdown.invoiceDelivery),
  statusOrder: () => tranformObjToArr(common.dropdown.statusOrder),
  types: () => tranformObjToArr(common.dropdown.types),
  entityType: () => tranformObjToArr(common.dropdown.entityType),
  paymentMethod: () => tranformObjToArr(common.dropdown.paymentMethod),
  customerSegment: () => tranformObjToArr(common.dropdown.customerSegment),
  status: () => tranformObjToArr(common.dropdown.status),
  reason: () => tranformObjToArr(common.dropdown.reason),
  paymentTerm: () => tranformObjToArr(common.dropdown.paymentTerm),
  phoneType: () => tranformObjToArr(common.dropdown.phoneType),
  arNotesType: () => tranformObjToArr(common.dropdown.arNotesType),
  noteStatus: () => tranformObjToArr(common.dropdown.noteStatus),
  statusPayment: () => tranformObjToArr(common.dropdown.statusPayment),
  accountStatementLineType: () => tranformObjToArr(common.dropdown.accountStatementLineType),
  selectBool: () => tranformObjToArr(common.dropdown.selectBool),
  folioStatus: () => tranformObjToArr(common.dropdown.folioStatus),
  switchName: () => tranformObjToArr(common.dropdown.switchName),
  switchNameRawFileData: () => tranformObjToArr(common.dropdown.switchNameRawFileData),
  switchNameRawNonBroadsoft: () => tranformObjToArr(common.dropdown.switchNameRawNonBroadsoft),
  switchNameRawSmsFile: () => tranformObjToArr(common.dropdown.switchNameRawSmsFile),
  accountCategory: () => tranformObjToArr(common.dropdown.accountCategory),
});

export default selections;
