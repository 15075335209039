import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ModalDetailsItem from '../common/ModalDetailsItem';
import { FormCollapse, DataTable, GenericInput } from '../common';

const dataFieldTransactionById = [
  {
    name: 'id',
    label: 'label.transactionId',
  },
  {
    name: 'type',
    label: 'label.transactionType',
    isSelect: true,
    tOptions: 'selections:transactionType',
  },
  {
    name: 'description',
    label: 'label.description',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isSelect: true,
    tOptions: 'selections:serviceType',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'billUnitId',
    label: 'label.billUnitId',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
  {
    name: 'source',
    label: 'label.source',
  },
  {
    name: 'serviceUnitId',
    label: 'label.serviceUnitId',
  },
  {
    name: 'netAmount',
    label: 'label.netAmount',
  },
];

const tableCurrencyTransactionById = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'balanceType',
    label: 'label.balanceType',
  },
  {
    name: 'offerType',
    label: 'label.offerType',
  },
  {
    name: 'currencyId',
    label: 'label.currency',
  },
  {
    name: 'unitPrice',
    label: 'label.unitPrice',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
];

const tableTaxDataByTransactionId = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'city',
    label: 'label.city',
  },
  {
    name: 'state',
    label: 'label.state',
  },
  {
    name: 'country',
    label: 'label.country',
  },
  {
    name: 'postalCode',
    label: 'label.postalCode',
  },
  {
    name: 'code',
    label: 'label.code',
  },
  {
    name: 'taxCode',
    label: 'label.taxCode',
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdiction',
  },
  {
    name: 'taxableAmount',
    label: 'label.taxableAmount',
  },
  {
    name: 'exemptAmount',
    label: 'label.exemptAmount',
  },
  {
    name: 'percent',
    label: 'label.percent',
  },
  {
    name: 'taxAmount',
    label: 'label.taxAmount',
  },
];

const tableAccumulatorByTransactionId = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'accumulatorId',
    label: 'label.accumulatorId',
  },
  {
    name: 'balanceUnitIndex',
    label: 'label.balanceUnitIndex',
  },
  {
    name: 'validStart',
    label: 'label.accumulatorStartDate',
  },
  {
    name: 'validEnd',
    label: 'label.accumulatorEndDate',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
];

const tableGrantsByTransactionId = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'grantId',
    label: 'label.grantId',
  },
  {
    name: 'grantType',
    label: 'label.grantType',
  },
  {
    name: 'validStart',
    label: 'label.grantStartDate',
  },
  {
    name: 'validEnd',
    label: 'label.grantEndDate',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
];

const collapsibleMenuTransactionId = [
  {
    title: 'label.recurringData',
    state: 'Recurring Data',
  },
  {
    title: 'label.usageData',
    state: 'Usage Data',
  },
  {
    title: 'label.taxData',
    state: 'Tax Data',
  },
  {
    title: 'label.currency',
    state: 'Currency',
  },
  {
    title: 'label.grants',
    state: 'Grants',
  },
  {
    title: 'label.accumulators',
    state: 'Accumulator',
  },
];

const dataFieldRecurringByTransactionId = [
  {
    name: 'type',
    label: 'label.recurringType',
    type: 'select',
    tOptions: 'selections:recurringType',
  },
  {
    name: 'cycleUnit',
    label: 'label.recurringUnit',
    type: 'select',
    tOptions: 'selections:recurringUnit',
  },
  {
    name: 'cycleDuration',
    label: 'label.recurringDuration',
  },
  {
    name: 'proration',
    type: 'select',
    tOptions: 'selections:prorationType',
    label: 'label.proration',
  },
  {
    name: 'scale',
    label: 'label.scale',
  },
  {
    name: 'cycleStart',
    label: 'label.cycleStart',
    // type: 'date',
  },
  {
    name: 'cycleEnd',
    label: 'label.cycleEnd',
    // type: 'date',
  },
  {
    name: 'appliedStart',
    label: 'label.appliedStart',
    // type: 'date',
  },
  {
    name: 'appliedEnd',
    label: 'label.appliedEnd',
    // type: 'date',
  },
];

const dataFieldUsageTransactionId = [
  {
    name: 'rateUnit',
    label: 'label.rateUnit',
    type: 'select',
    tOptions: 'selections:rateUnit',
  },
  {
    name: 'timeUnit',
    label: 'label.timeUnit',
    type: 'select',
    tOptions: 'selections:timeUnitPricing',
  },
  {
    name: 'zoneUnit',
    label: 'label.zoneUnit',
  },
  {
    name: 'impactUnit',
    label: 'label.impactUnit',
  },
  {
    name: 'holidayName',
    label: 'label.holidayName',
  },
  {
    name: 'salesChannel',
    label: 'label.salesChannel',
    type: 'select',
    tOptions: 'selections:salesChannel',
  },
  {
    name: 'marketSegment',
    label: 'label.marketSegment',
    type: 'select',
    tOptions: 'selections:marketSegment',
  },
  {
    name: 'type',
    label: 'label.accountType',
    type: 'select',
    tOptions: 'selections:accountType',
  },
  {
    name: 'subType',
    label: 'label.accountSubType',
    type: 'select',
    tOptions: 'selections:accountSubType',
  },
  {
    name: 'code',
    label: 'label.code',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
  },
  {
    name: 'scaledQuantity',
    label: 'label.scaledQuantity',
  },
  {
    name: 'batchid',
    label: 'label.batchid',
  },
  {
    name: 'batchFileName',
    label: 'label.batchFileName',
  },
  {
    name: 'transactionId',
    label: 'label.transactionId',
  },
];

class Transactions extends Component {
  state = {
    isOpenModal: false,
    activeTab: {},
    itemData: {},
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal, activeTab: {} });
  };

  doGetTransactionById = id => {
    const { getTransactionUnitById } = this.props;
    this.setState({ itemData: {} });
    this.setState({ isOpenModal: true, isSearchDataModal: true });
    getTransactionUnitById(id, ({ data, success }) =>
      this.setState({
        itemData: success ? data : {},
        isSearchDataModal: false,
      })
    );
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  renderItem = item => {
    const { itemData } = this.state;
    switch (item.state) {
      case 'Recurring Data':
        return (
          <div className="row col-md-12 pl-0 pr-0 pt-2 pb-2">
            {dataFieldRecurringByTransactionId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.recurringData && itemData.recurringData[0] && itemData.recurringData[0][val.name]
                    ? itemData.recurringData[0][val.name]
                    : null
                }
                disabled
                readOnly
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Usage Data':
        return (
          <div className="row col-md-12 pl-0 pr-0 pt-2 pb-2">
            {dataFieldUsageTransactionId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.txnUsageData && itemData.txnUsageData && itemData.txnUsageData[val.name]
                    ? itemData.txnUsageData[val.name]
                    : null
                }
                disabled
                readOnly
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Tax Data':
        return (
          <div className="col-md-12 p-0">
            <DataTable columns={tableTaxDataByTransactionId} data={itemData.taxData ? itemData.taxData : []} />
          </div>
        );
      case 'Currency':
        return (
          <div className="col-md-12 p-0">
            <DataTable columns={tableCurrencyTransactionById} data={itemData.balances ? itemData.balances : []} />
          </div>
        );
      case 'Grants':
        return (
          <div className="col-md-12 p-0">
            <DataTable columns={tableGrantsByTransactionId} data={itemData.txnGrants ? itemData.txnGrants : []} />
          </div>
        );
      case 'Accumulator':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableAccumulatorByTransactionId}
              data={itemData.txnAccumulators ? itemData.txnAccumulators : []}
            />
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { data, t } = this.props;
    const { isOpenModal, itemData, isSearchDataModal, activeTab } = this.state;
    let newCollapsibleMenu = collapsibleMenuTransactionId;

    if (!itemData.type || ['FORWARD_RECURRING', 'ARREARS_RECURRING'].indexOf(itemData.type) === -1)
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Recurring Data');
    if (!itemData.type || itemData.type !== 'USAGE')
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Usage Data');
    if (!itemData.type || itemData.type !== 'TAX')
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Tax Data');
    if (!itemData.balances || !itemData.balances.length)
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Currency');
    if (!itemData.txnGrants || !itemData.txnGrants.length)
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Grants');
    if (!itemData.txnAccumulators || !itemData.txnAccumulators.length)
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Accumulator');

    const heads = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) => {
          return (
            <button type="button" className="no-border btn-link" onClick={() => this.doGetTransactionById(item.id)}>
              {item.id}
            </button>
          );
        },
      },
      {
        name: 'startDate',
        label: 'label.startDate',
      },
      {
        name: 'endDate',
        label: 'label.endDate',
      },
      {
        name: 'type',
        label: 'label.transactionType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:transactionType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'billUnitId',
        label: 'label.transactionUnit',
      },
      {
        name: 'itemId',
        label: 'label.itemDescription',
      },
      {
        name: 'serviceType',
        label: 'label.serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'netAmount',
        label: 'label.NET',
      },
      {
        name: 'source',
        label: 'label.source',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:transactionSource')().find(val => val.value === item.source) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'view',
        label: 'label.view',
        render: (colName, item) => {
          return (
            <button
              className="btn btn-outline-success btn-sm btn-view-file"
              type="button"
              onClick={() => this.doGetTransactionById(item.id)}
            >
              {item.id}
            </button>
          );
        },
      },
    ];
    return (
      <Card>
        {/* <table className="table table-hover">
          <thead>
            <tr>{heads.map(item => this.renderHeader(item))}</tr>
          </thead>
          {this.renderBody(data)}
        </table> */}
        <DataTable tableClass="col-md-12" columns={heads} data={data || []} isFixedHeaderTable />
        <ModalDetailsItem
          isOpen={isOpenModal}
          data={itemData}
          onToggle={this.onToggleModal}
          dataField={dataFieldTransactionById}
          title={t('label.transactions')}
          isSearch={isSearchDataModal}
        >
          <div className="pt-4">
            {newCollapsibleMenu.map(item => (
              <FormCollapse
                key={item.title}
                // label="First Name"
                isActive={item.state === activeTab.name && activeTab.isActive}
                title={item.title || ''}
                state={item.state}
                onToggleTab={this.onToggleTab}
              >
                {this.renderItem(item)}
              </FormCollapse>
            ))}
          </div>
        </ModalDetailsItem>
      </Card>
    );
  }
}

Transactions.propTypes = {
  data: PropTypes.array,
  // history: PropTypes.object,
};

export default withTranslation('common')(Transactions);
