import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { MainHeader } from '../../components/Header';
import {
  DataTable,
  ModalDetailsItem,
  FormCollapse,
  GenericInput,
  PhoneGroup,
  PasswordField,
  CheckFieldRole,
  TablePagination,
  AccountHeaderForm,
} from '../../components/common';
import AccountActivitySearchForm from '../../components/AccountActivity/AccountActivitySearchForm';
import { getAccountId } from '../../localStorage';
import { makeGetPermissionSelfCare } from '../App/selectors';
import { getCustomerActivity, getActivityById } from '../App/actions';
import constant from './constants';
import { getPageTotalCount } from '../../utils/utils';

import './styles.scss';

const orderSort = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  userId: {
    asc: 'userId_ASC',
    desc: 'userId_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  activityDate: {
    asc: 'activityDate_ASC',
    desc: 'activityDate_DESC',
  },
};

export class ViewAccountActivity extends React.Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sorted: {},
    totalCount: null,
    isSearching: false,
    isActiveNext: false,
    isOpenModal: false,
    isSearchDataModal: false,
    itemData: {},
    activeParentTab: {
      // name: 'Recurring Data',
      // isActive: true,
    },
    activeChildrentTab: {},
  };

  componentDidMount = () => {
    this.doGetCustomerActivity();
  };

  doGetCustomerActivity = () => {
    const { page, size, sorted, filter, totalCount } = this.state;
    const { getCustomerActivity } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && orderSort[sorted.sortCol]
        ? orderSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter: { ...filter, accountId: getAccountId() },
    };
    if (sort) {
      params.sort = sort;
    }
    getCustomerActivity(params, ({ success, data }) => {
      this.setState({
        isSearching: false,
        page,
        size,
        filter,
        data: success ? data : [],
        isActiveNext: !!(data && data.length === size),
        totalCount: getPageTotalCount({ totalCount: totalCount || data?.length, size, items: data || [], page }),
      });
    });
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: { ...filteredData }, page: 0 }, () => this.doGetCustomerActivity());
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetCustomerActivity());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetCustomerActivity());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetCustomerActivity());
  };

  doGetActivityById = id => {
    const { getActivityById } = this.props;
    this.setState({ isOpenModal: true, isSearchDataModal: true });
    getActivityById(id, ({ data, success }) =>
      this.setState({ itemData: success ? data : {}, isSearchDataModal: false })
    );
  };

  onToggleParentTab = activeTabName => {
    const { activeParentTab } = this.state;
    if (activeParentTab.name === activeTabName) {
      this.setState({
        activeParentTab: {
          name: activeTabName,
          isActive: !activeParentTab.isActive,
        },
        activeChildrentTab: {},
      });
    } else {
      this.setState({
        activeParentTab: {
          name: activeTabName,
          isActive: true,
        },
        activeChildrentTab: {},
      });
    }
  };

  onToggleChildrentTab = activeTabName => {
    const { activeChildrentTab } = this.state;
    if (activeChildrentTab.name === activeTabName) {
      this.setState({
        activeChildrentTab: { name: activeTabName, isActive: !activeChildrentTab.isActive },
      });
    } else {
      this.setState({ activeChildrentTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  renderItem = ({ item, data }) => {
    const { t } = this.props;
    return (
      <div className="col-md-12 row p-3">
        {item.dataFields &&
          item.dataFields.length > 0 &&
          item.dataFields.map(val => {
            if (val.name === 'phones') {
              return (
                <PhoneGroup
                  key={val.name}
                  colDiv="col-md-6 p-0"
                  readOnly
                  isDetailModal
                  isDisabled
                  dataPhone={data[0] ? data[0].phones : ''}
                  {...val}
                  onChange={() => {}}
                />
              );
            }
            if (val.name === 'last4CC') {
              return (
                <PasswordField
                  key={val.name}
                  readOnly
                  disabled
                  value={
                    data[0] && data[0].creditCards && data[0].creditCards[0] ? data[0].creditCards[0][val.name] : ''
                  }
                  {...val}
                  onChange={() => {}}
                />
              );
            }
            if (['cardToken', 'cardExpiry'].indexOf(val.name) > -1) {
              return (
                <GenericInput
                  key={val.name}
                  readOnly
                  disabled
                  value={
                    data[0] && data[0].creditCards && data[0].creditCards[0] ? data[0].creditCards[0][val.name] : ''
                  }
                  {...val}
                  onChange={() => {}}
                />
              );
            }
            return (
              <GenericInput
                key={val.name}
                onChange={() => {}}
                readOnly
                disabled
                value={data[0] ? data[0][val.name] : ''}
                {...val}
              />
            );
          })}

        {item.key === 'activityCustomAttributes' && (
          <div className="col-md-12 row p-0">
            {data &&
              data.length > 0 &&
              data.map(attr => {
                return (
                  <GenericInput
                    key={attr.name}
                    onChange={() => {}}
                    readOnly
                    disabled
                    value={attr && attr.value ? attr.value : ''}
                    label={attr && attr.name ? attr.name : ''}
                  />
                );
              })}
          </div>
        )}
        {data[0] && data[0].roles && (
          <div className="col-md-12 row mt-3">
            <span>{`${t('label.roles')}: `}</span>
            {constant.checkRoleGroup.map((role, index) => {
              return (
                <CheckFieldRole
                  key={role.label}
                  label={t && role.label ? t(role.label) : role.label || ''}
                  className={role.className}
                  name={role.value}
                  disabled
                  value={data[0] && data[0].roles.indexOf(role.value) > -1}
                  onChange={() => {}}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { permissionsSelfCare, t } = this.props;
    const {
      billingData,
      data,
      page,
      isActiveNext,
      size,
      sorted,
      isOpenModal,
      itemData,
      isSearchDataModal,
      activeParentTab,
      activeChildrentTab,
      totalCount,
    } = this.state;
    const tableColumns = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) => (
          <button type="button" className="no-border btn-link" onClick={() => this.doGetActivityById(item.id)}>
            {item.id}
          </button>
        ),
      },
      {
        name: 'userId',
        label: 'common:label.userId',
      },
      {
        name: 'entityType',
        label: 'common:label.entityType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:entityType')().find(val => val.value === item.entityType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'source',
        label: 'common:label.source',
        render: (colName, item) => {
          const slt = t ? t('selections:source')().find(val => val.value === item.source) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'action',
        label: 'common:label.action',
      },
      {
        name: 'activityDate',
        label: 'common:label.activityDate',
        style: { minWidth: '100px' },
        sortable: true,
      },
      {
        name: 'select',
        label: 'label.view',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetActivityById(item.id)}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];
    let newCollapsible = constant.collapsibleMenuAccountActivities.filter(
      val => val.entityType === itemData.entityType
    );

    let dataFileRqRp = cloneDeep(constant.dataFieldActivity);
    // if (itemData.entityType !== 'INTEGRATION') {
    //   dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'response');
    //   dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'request');
    // }
    if (!itemData.request || itemData.request === 'null') {
      // dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'response');
      dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'request');
    }
    if (!itemData.response || itemData.response === 'null') {
      // dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'response');
      dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'response');
    }
    if ((itemData.request && itemData.request !== 'null') || (itemData.response && itemData.response !== 'null')) {
      newCollapsible = [];
    }
    if (itemData.entityType === 'CUSTOMER') {
      newCollapsible = constant.collapsibleMenuAccountActivities.filter(val => val.entityType !== itemData.entityType);
    }
    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="Reports" />
        <div className="manage-page__form p-3">
          <div className="form-billing">
            <div className="col-md-12 row pt-4 group-input-billing" onSubmit={this.onSubmit}>
              <div className="col-md-12 p-0">
                <br />
                <div className="col-md-12 pl-0 pr-0 pt-0 pb-3">
                  {/* <h4 className="card-title-mb">
                    {t('label.accountActivity')}
                    <span className="account-number">
                      <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                      &nbsp;&nbsp;
                      <span className="account-detail">{getAccountId()}</span>
                    </span>
                  </h4> */}
                  <AccountHeaderForm title={t('label.accountActivity')} />
                </div>
                <br />
              </div>
              <AccountActivitySearchForm onSubmit={this.onSubmitSearch} />
            </div>
            <div className="group-input-billing">
              <DataTable
                isFixedHeaderTable
                columns={tableColumns}
                data={data || []}
                onSort={this.onSortColumn}
                sorted={sorted}
              />
              {/* <div className="col-md-12 pl-0 pr-0 pt-3">
                <Pagination
                  page={page}
                  isActivePre={page !== 1}
                  isActiveNext={isActiveNext}
                  handlePage={this.onPageChange}
                />
                <Sizing handleSize={this.onSizeChange} size={size} />
              </div> */}
              <div className="mb-30">
                <TablePagination
                  pageNumber={page}
                  pageSize={size}
                  totalCount={totalCount}
                  onPageChange={this.onPageChange}
                  onSizeChange={this.onSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalDetailsItem
          isOpen={isOpenModal}
          data={itemData}
          onToggle={this.onToggleModal}
          dataField={dataFileRqRp}
          title={t('label.customerActivity')}
          isSearch={isSearchDataModal}
        >
          <div className="pt-4">
            {newCollapsible &&
              newCollapsible.length > 0 &&
              newCollapsible.map(item => (
                <FormCollapse
                  key={item.title}
                  label="First Name"
                  isActive={item.state === activeParentTab.name && activeParentTab.isActive}
                  title={t(item.title) || ''}
                  state={item.state}
                  onToggleTab={this.onToggleParentTab}
                >
                  <div className="col-md-12 pt-3">
                    {constant.collapsibleMenuBeforeAfter.map(val => (
                      <FormCollapse
                        key={val.title}
                        label="First Name"
                        isActive={val.state === activeChildrentTab.name && activeChildrentTab.isActive}
                        isActiveSubForm={val.state === activeChildrentTab.name && activeChildrentTab.isActive}
                        isSubCollapse
                        title={t(val.title) || ''}
                        state={val.state}
                        onToggleTab={this.onToggleChildrentTab}
                      >
                        {this.renderItem({
                          item,
                          data: itemData[val.key] && itemData[val.key][item.key] ? itemData[val.key][item.key] : {},
                        })}
                      </FormCollapse>
                    ))}
                  </div>
                  {/* {this.renderItem(item)} */}
                </FormCollapse>
              ))}
          </div>
        </ModalDetailsItem>
      </div>
    );
  }
}

ViewAccountActivity.propTypes = {
  getCustomerActivity: PropTypes.func.isRequired,
  getActivityById: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    getCustomerActivity: (data, cb) => dispatch(getCustomerActivity(data, cb)),
    getActivityById: (data, cb) => dispatch(getActivityById(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common'])(compose(withConnect)(ViewAccountActivity));
