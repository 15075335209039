import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';

const ProgressBarRegister = ({ stage, onChangeStage, isExistAccount }) => (
  <div className="col-md-12">
    <ProgressBar percent={stage >= 2 || isExistAccount ? 100 : 50 * stage}>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <button
              type="button"
              onClick={() => onChangeStage(0)}
              disabled={isExistAccount}
              className={`number-loader-progress no-border-focus ${
                accomplished ? 'number-loader-active' : ''
              }`}
            >
              1
            </button>
            <div className="title-loader-progress text-account-detail">
              Account Details
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <button
              type="button"
              disabled={isExistAccount}
              onClick={() => onChangeStage(1)}
              className={`number-loader-progress no-border-focus ${
                accomplished ? 'number-loader-active' : ''
              }`}
            >
              2
            </button>
            <div className="title-loader-progress text-credit-card">
              Credit Card Details
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <button
              type="button"
              disabled={isExistAccount}
              onClick={() => onChangeStage(2)}
              className={`number-loader-progress no-border-focus ${
                accomplished ? 'number-loader-active' : ''
              }`}
            >
              3
            </button>
            <div className="title-loader-progress text-select-package">
              Select/Create Package
            </div>
          </div>
        )}
      </Step>
      {/* <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <button
              type="button"
              onClick={() => onChangeStage(3)}
              className={`number-loader-progress no-border-focus ${
                accomplished ? 'number-loader-active' : ''
              }`}
            >
              3
            </button>
            <div className="title-loader-progress text-create-package">
              Create Your Package
            </div>
          </div>
        )}
      </Step> */}
    </ProgressBar>
  </div>
);
export default ProgressBarRegister;
