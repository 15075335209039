import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['recordCount'])}` : '';
  const getDataServiceReportData = `getDataServiceReportData(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getDataServiceReportData} {
        lineDate
        ipAddress
        volumeUpload
        volumeDownload
        quantity
        organization
        recordCount
      }
    }
  `;
};
