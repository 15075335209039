import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    background-color: #e8e8e8;
  },
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: font-family: Poppins; !important;
    font-size: 14px;
  }

  body.fontLoaded {
    font-family: font-family: Poppins; !important;
    font-size: 14px;
  }

  #app {
    background-color: #e8e8e8;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: font-family: Poppins; !important;
    line-height: 1.5em;
  }

  .container {
    padding: 0;
  }
  
  h1 {
    font-size: 20px !important;
    margin: 0;
  }
  
  h2 {
    font-size: 18px !important;
    margin: 0;
  }
  
  h3 {
    font-size: 16px !important;
    margin: 0;
  }
  
  p,
  label.col-form-label,
  input.form-control-plaintext {
    font-size: 14px;
  }
  
  .font-size-20 {
    font-size: 20px;
  }

  .form-control {
    font-size: 14px !important;
  }
`;

export default GlobalStyle;
