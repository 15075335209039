import React from 'react';

const CreatePackageDetails = ({ title, contentView, imageView }) => (
  <div className="col-md-3 view-detail-left">
    <div className="image-group">
      <img src={imageView} alt="logo" className="image-register" />
    </div>
    <br />
    <div>
      <h1 className="title-slected-step">{title}</h1>
    </div>
    <br />
    <div>
      <h4 className="text-detail-step">
        {/* {`Lorem Ipsum is simply dummy text of the printing and typesetting
          in-dustry. Lorem Ipsum has been the industry's standard dummy
          text ever since the 1500s, when an un-known printer took a.`} */}
        {contentView}
      </h4>
    </div>
  </div>
);
export default CreatePackageDetails;
