import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DataTable } from '../common';

const heads = [
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
  {
    name: 'total',
    label: 'label.total',
  },
  {
    name: 'nonPayingTotal',
    label: 'label.nonPayingTotal',
  },
  {
    name: 'billTotal',
    label: 'label.billTotal',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:billStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];
class PendingBills extends PureComponent {
  render() {
    const { data } = this.props;
    return <DataTable columns={heads} data={data || []} isFixedHeaderTable />;
  }
}

PendingBills.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};

export default withTranslation('common')(PendingBills);
