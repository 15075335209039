import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../components/common/SearchComponent';

class OnDemandSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'datetime',
      required: true,
      defaultValue: moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'datetime',
      required: true,
      defaultValue: moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.ipAddress',
      name: 'ipAddress',
    },
    {
      label: 'label.countLessThan',
      name: 'recordCount',
      type: 'number',
      isInteger: true,
    },
  ];

  btnSpan = 'col-md-12';
}

OnDemandSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OnDemandSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OnDemandSearchForm);
