/*
 *
 * ManageProfilePage actions
 *
 */

import * as types from './constants';
// for get transaction unit
export function getTransactionUnit(payload, cb) {
  return {
    type: types.GET_TRANSACTION_UNIT,
    payload,
    cb,
  };
}

export function getTransactionUnitSuccess(payload) {
  return {
    type: types.GET_TRANSACTION_UNIT_SUCCESS,
    payload,
  };
}

export function setTransactionParams(payload) {
  return {
    type: types.SET_TRANSACTION_PARAMS,
    payload,
  };
}

export function getTransactionUnitById(payload, cb) {
  return {
    type: types.GET_TRANSACTION_UNIT_BY_ID,
    payload,
    cb,
  };
}
