import React, { PureComponent } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import InvoiceLayout from './InvoiceLayout';
import saga from './saga';
import reducer from './reducer';

class InvoiceRoute extends PureComponent {
  render() {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    return <InvoiceLayout id={id} history={history} />;
  }
}

InvoiceRoute.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

const withSaga = injectSaga({ key: 'invoiceRouteSaga', saga });
const withReducer = injectReducer({ key: 'invoiceReducer', reducer });

export default compose(withReducer, withSaga)(InvoiceRoute);
