import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectmanageServicesDomain = state =>
  state.get('manageServices', initialState);

const makeGetServiceUnitsAc = () =>
  createSelector(selectmanageServicesDomain, customers => [
    ...customers.get('serviceUnitsAc'),
  ]);

const makeGetSubscription = () =>
  createSelector(
    selectmanageServicesDomain,
    customers => customers.get('subscription')[0] || {},
  );
const makeErrorMessageSU = () =>
  createSelector(selectmanageServicesDomain, customers =>
    customers.get('errorServiceUnits'),
  );

const makeErrorMessageSubscription = () =>
  createSelector(selectmanageServicesDomain, customers =>
    customers.get('errorSubscription'),
  );

const makeSelectmanageServices = () =>
  createSelector(selectmanageServicesDomain, substate => substate.toJS());

export default makeSelectmanageServices;
export {
  selectmanageServicesDomain,
  makeGetServiceUnitsAc,
  makeGetSubscription,
  makeErrorMessageSU,
  makeErrorMessageSubscription,
};
