/* eslint-disable linebreak-style */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { startCase, toLower, cloneDeep } from 'lodash';
import { FaAngleLeft, FaAngleRight, FaEye, FaCheck } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import { IoMdRemoveCircle } from 'react-icons/io';
import { Tooltip } from 'reactstrap';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';

const dataDefaluBundleId = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  arrow: false,
  slidesToScroll: 3,
};

class CreatePackage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      serviceNameModeCreatePackage: 'CABLE',
      listBundleId: [],
      isSearching: false,
      viewDescriptionBundleId: '',
      listBundleIdSelected: [],
      page: 1,
      tooltipOpen: '',
    };
  }

  componentDidMount() {
    this.doSearchBubdles();
  }

  handlePage = i => {
    const { page } = this.state;
    this.setState({ page: page + i, listBundleId: [] }, () =>
      this.doSearchBubdles(),
    );
  };

  doSearchBubdles = () => {
    const { searchBundles } = this.props;
    const { serviceNameModeCreatePackage, page } = this.state;
    this.setState({ isSearching: true });
    searchBundles(
      { serviceType: serviceNameModeCreatePackage, page },
      ({ success, data }) => {
        if (success && data) {
          const listBundleId = data.map(item => ({
            bundleId: item.id,
            name: item.name,
            description: item.description,
          }));
          this.setState({
            listBundleId,
            isSearching: false,
          });
        }
      },
    );
  };

  nextSlider = () => {
    this.slider.slickNext();
  };

  previousSlider = () => {
    this.slider.slickPrev();
  };

  onHandleChangeServiceName(value) {
    this.setState(
      {
        serviceNameModeCreatePackage: value,
        listBundleId: [],
      },
      () => this.doSearchBubdles(),
    );
  }

  onSelectBundleId = (bundleId, name, description) => {
    const { listBundleIdSelected, serviceNameModeCreatePackage } = this.state;
    const { onHandleSelectBundle } = this.props;
    const newListBundleIdSelected = cloneDeep(listBundleIdSelected);
    const indexServiceSelect = newListBundleIdSelected.findIndex(
      item => item.serviceType === serviceNameModeCreatePackage,
    );
    if (indexServiceSelect === -1) {
      newListBundleIdSelected.push({
        serviceType: serviceNameModeCreatePackage,
      });
      newListBundleIdSelected[newListBundleIdSelected.length - 1].bundleId = [];
      newListBundleIdSelected[newListBundleIdSelected.length - 1].bundleId.push(
        { bundleId, name, description },
      );
    } else {
      const listBunldeIdOnServiceSelected =
        newListBundleIdSelected[indexServiceSelect].bundleId;
      if (
        listBunldeIdOnServiceSelected.findIndex(i => i.bundleId === bundleId) >
        -1
      ) {
        newListBundleIdSelected[
          indexServiceSelect
        ].bundleId = listBunldeIdOnServiceSelected.filter(
          item => item.bundleId !== bundleId,
        );
      } else {
        newListBundleIdSelected[indexServiceSelect].bundleId.push({
          bundleId,
          name,
          description,
        });
      }
      if (newListBundleIdSelected[indexServiceSelect].bundleId.length === 0) {
        newListBundleIdSelected.splice(indexServiceSelect, 1);
      }
    }
    this.setState({ listBundleIdSelected: newListBundleIdSelected });
    onHandleSelectBundle(newListBundleIdSelected);
  };

  onHandleViewDescripton = bundleId => {
    let { viewDescriptionBundleId } = this.state;
    if (viewDescriptionBundleId === bundleId) {
      viewDescriptionBundleId = '';
    } else {
      viewDescriptionBundleId = bundleId;
    }
    this.setState({ viewDescriptionBundleId });
  };

  onHandleRemoveBundleSelect = ({ bundleId, serviceType }) => {
    const { listBundleIdSelected } = this.state;
    const { onHandleSelectBundle } = this.props;
    const newListBundleIdSelected = cloneDeep(listBundleIdSelected);
    const indexService = newListBundleIdSelected.findIndex(
      item => item.serviceType === serviceType,
    );
    newListBundleIdSelected[indexService].bundleId = newListBundleIdSelected[
      indexService
    ].bundleId.filter(item => item.bundleId !== bundleId);
    if (newListBundleIdSelected[indexService].bundleId.length === 0) {
      newListBundleIdSelected.splice(indexService, 1);
    }
    this.setState({ listBundleIdSelected: newListBundleIdSelected });
    onHandleSelectBundle(newListBundleIdSelected);
  };

  toggle = bundleIdHover => {
    this.setState({
      tooltipOpen:
        this.state.tooltipOpen === bundleIdHover ? '' : bundleIdHover,
    });
  };

  render() {
    const { data, t } = this.props;
    const {
      serviceNameModeCreatePackage,
      listBundleId,
      viewDescriptionBundleId,
      isSearching,
      listBundleIdSelected,
      page,
      tooltipOpen,
    } = this.state;

    const serviceSelected = listBundleIdSelected.filter(
      val => val.serviceType === serviceNameModeCreatePackage,
    )[0];
    const listBundleChecked = serviceSelected ? serviceSelected.bundleId : [];

    return (
      <div className="col-md-12 no-padding select-package-from">
        <div className="col-md-12 select-package-container no-padding">
          <div
            className="select-package-group m-auto"
            style={{
              width: `${serviceNameModeCreatePackage.length * 1.2 + 15}%`,
            }}
          >
            <Select
              options={t('selections:serviceType')()}
              value={{
                value: serviceNameModeCreatePackage,
                label: startCase(
                  toLower(
                    `${
                      t('selections:serviceType')().find(
                        sv => sv.value === serviceNameModeCreatePackage,
                      ).label
                    } Service`,
                  ),
                ),
              }}
              onChange={evt => this.onHandleChangeServiceName(evt.value)}
            />
          </div>
        </div>
        <div className="col-md-12 no-padding d-flex form-input mt-1">
          <div className="no-padding arrow-handle-page">
            <button
              className="icon-handle-page no-border-focus"
              type="button"
              disabled={page === 1}
              onClick={() => this.handlePage(-1)}
            >
              <span className="icon-arrow">
                <FaAngleLeft />
              </span>
            </button>
          </div>

          <div className="col-md-11 row package-container">
            {dataDefaluBundleId.map((item, index) => {
              if (!isSearching && !listBundleId[item])
                return index === 0 ? (
                  <div className="col-md-12 text-center mb-5" key={item}>
                    {t('message.noRecord')}
                  </div>
                ) : (
                  <div key={item} />
                );
              return (
                <div className="col-md-4 row group-item-package" key={item}>
                  <div
                    className="col-md-10 item-bundleId"
                    onClick={() =>
                      this.onSelectBundleId(
                        listBundleId[item].bundleId,
                        listBundleId[item].name,
                        listBundleId[item].description,
                      )
                    }
                  >
                    {listBundleId[item] ? (
                      <div
                        // className={`${
                        //   listBundleId[item].bundleId ===
                        //   viewDescriptionBundleId
                        //     ? ''
                        //     : 'no-description'
                        // } package-id`}
                        className="package-id mt-1"
                      >
                        {listBundleId[item].name}
                        {listBundleChecked.length > 0 &&
                          listBundleChecked.findIndex(
                            c => c.bundleId === listBundleId[item].bundleId,
                          ) > -1 && (
                            <FaCheck
                              color="#1e7e34"
                              style={{ marginLeft: 5, marginBottom: 5 }}
                            />
                          )}
                      </div>
                    ) : (
                      <Skeleton height={60} duration={1} />
                    )}

                    {/* <div className="description-bundle">
                      {listBundleId[item] &&
                      listBundleId[item].bundleId === viewDescriptionBundleId
                        ? `${listBundleId[item].description.slice(0, 39)}...`
                        : ''}
                    </div> */}
                  </div>
                  <button
                    className="col-md-2 no-padding group-handle-description no-border-focus"
                    type="button"
                    id={`view-description-${item}`}
                    onClick={
                      () => null
                      // this.onHandleViewDescripton(listBundleId[item].bundleId)
                    }
                  >
                    <div className="icon-show-description">
                      <FaEye />
                    </div>
                    <Tooltip
                      placement="top"
                      style={{ background: '#0f9d20de' }}
                      isOpen={
                        listBundleId[item] &&
                        tooltipOpen === listBundleId[item].bundleId
                      }
                      autohide={false}
                      target={`view-description-${item}`}
                      toggle={() =>
                        this.toggle(
                          listBundleId[item] && listBundleId[item].bundleId
                            ? listBundleId[item].bundleId
                            : '',
                        )
                      }
                    >
                      {listBundleId[item] && listBundleId[item].description}
                    </Tooltip>
                  </button>
                </div>
              );
            })}
          </div>
          <div className="no-padding arrow-handle-page">
            <button
              className="icon-handle-page no-border-focus"
              type="button"
              disabled={listBundleId.length < 9}
              onClick={() => this.handlePage(1)}
            >
              <span className="icon-arrow">
                <FaAngleRight />
              </span>
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="no-padding arrow-handle-page">
            <button
              className="icon-handle-page no-border-focus"
              type="button"
              onClick={this.previousSlider}
            >
              <span className="icon-arrow">
                <FaAngleLeft />
              </span>
            </button>
          </div>
          <div className="col-md-11">
            <Slider ref={c => (this.slider = c)} {...settings}>
              {listBundleIdSelected.length > 0 &&
                listBundleIdSelected.map(item => (
                  <div className="mt-1" key={item.serviceType}>
                    <div className="title-service">
                      {startCase(
                        toLower(
                          `${
                            t('selections:serviceType')().find(
                              sv => sv.value === item.serviceType,
                            ).label
                          } Service`,
                        ),
                      )}
                    </div>
                    <div className="item-bundle-id">
                      {item.bundleId.length > 0 &&
                        item.bundleId.map(val => (
                          <div className="col-md-16" key={val.bundleId}>
                            <button
                              className="ic-remove-bundle no-border-focus"
                              type="button"
                              onClick={() =>
                                this.onHandleRemoveBundleSelect({
                                  bundleId: val.bundleId,
                                  serviceType: item.serviceType,
                                })
                              }
                            >
                              <IoMdRemoveCircle color="red" />
                            </button>
                            {val.name}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          <div className="no-padding arrow-handle-page">
            <button
              className="icon-handle-page no-border-focus"
              type="button"
              onClick={this.nextSlider}
            >
              <span className="icon-arrow">
                <FaAngleRight />
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

CreatePackage.propTypes = {
  data: PropTypes.object,
  stateCallBackFromURL: PropTypes.object,
  onChange: PropTypes.func,
  focusCard: PropTypes.string,
};

export default withTranslation('common')(CreatePackage);
