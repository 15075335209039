import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import fieldConstant from '../../containers/RegisterNew/constants';

class CreateUserWithAccountExist extends PureComponent {
  render() {
    const { data, onChange, t } = this.props;
    return (
      <div className="col-md-12 d-flex flex-wrap form-input no-padding">
        {fieldConstant.filedCreateWithExistAccount.map(item => (
          <Col md={6} key={item.name}>
            <FormGroup>
              <Label for={item.name}>{t(item.label)}:</Label>
              <Input
                type={item.isPassword ? 'password' : 'text'}
                name={item.name}
                id={item.name}
                value={data[item.name] || ''}
                onChange={evt =>
                  onChange({ name: evt.target.name, value: evt.target.value })
                }
              />
            </FormGroup>
          </Col>
        ))}
      </div>
    );
  }
}

CreateUserWithAccountExist.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withTranslation('common')(CreateUserWithAccountExist);
