import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ModalDetailsItem from '../common/ModalDetailsItem';
import { FormCollapse, DataTable, GenericInput } from '../common';

const dataFieldTransactionById = [
  {
    name: 'invoiceDate',
    label: 'label.invoiceDate',
  },
  {
    name: 'userId',
    label: 'label.userId',
  },
  {
    name: 'dueDate',
    label: 'label.dueDate',
  },
  {
    name: 'lastActionDate',
    label: 'label.lastActionDate',
  },
  {
    name: 'status',
    label: 'label.status',
    tOptions: 'selections:statusCollections',
  },
  {
    name: 'closeDate',
    label: 'label.closeDate',
  },
  {
    name: 'daysInCollection',
    label: 'label.daysInCollection',
  },
  {
    name: 'notes',
    label: 'label.notes',
    type: 'textarea',
    col: 12,
  },
];

const tableCurrencyCollectionById = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'action',
    label: 'label.action',
  },
  {
    name: 'description',
    label: 'label.description',
  },
  {
    name: 'date',
    label: 'label.date',
  },
];

const heads = [
  'label.collectionUnitId',
  'label.invoiceId',
  'label.lastName',
  'label.invoiceDate',
  'label.dueDate',
  'label.daysInCollection',
  'label.status',
  'label.lastActionDate',
  'label.closeDate',
  'label.view',
];
class Transactions extends Component {
  state = {
    isOpenModal: false,
    activeTab: {},
    itemData: {},
  };

  renderRow = row => {
    const { t } = this.props;
    const status = t('selections:statusCollections')().find(val => val.value === row.status);

    return (
      <tr key={row.id}>
        <td>
          <button type="button" className="no-border btn-link" onClick={() => this.doGetCollectionUnitsById(row.id)}>
            {row.id}
          </button>
        </td>
        <td>{row.invoiceId}</td>
        <td>{row.lastName}</td>
        <td>{row.invoiceDate}</td>
        <td>{row.dueDate}</td>
        <td>{row.daysInCollection}</td>
        <td>{status ? status.label : ''}</td>
        <td>{row.lastActionDate}</td>
        <td>{row.closeDate}</td>
        <td>
          <button
            type="button"
            className="btn ml-0 btn-outline-success btn-sm"
            onClick={() => this.doGetCollectionUnitsById(row.id)}
          >
            {t('label.view')}
          </button>
        </td>
      </tr>
    );
  };

  renderHeader = header => {
    const { t } = this.props;
    return (
      <th key={header} scope="col" className="w-25 p-3">
        {t(header)}
      </th>
    );
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  doGetCollectionUnitsById = id => {
    const { getCollectionUnitById } = this.props;
    this.setState({ itemData: {} });
    this.setState({ isOpenModal: true, isSearchDataModal: true });
    getCollectionUnitById(id, ({ data, success }) =>
      this.setState({
        itemData: success ? data : {},
        isSearchDataModal: false,
      })
    );
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  renderBody(data) {
    const { t } = this.props;
    if (!data.length) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>{t('message.noRecord')}</td>
          </tr>
        </tbody>
      );
    }
    return <tbody>{data.map(this.renderRow)}</tbody>;
  }

  render() {
    const { data, t } = this.props;
    const { isOpenModal, itemData, isSearchDataModal, activeTab } = this.state;

    return (
      <Card>
        <table className="table table-hover">
          <thead>
            <tr>{heads.map(item => this.renderHeader(item))}</tr>
          </thead>
          {this.renderBody(data)}
        </table>
        <ModalDetailsItem
          isOpen={isOpenModal}
          data={itemData}
          onToggle={this.onToggleModal}
          dataField={dataFieldTransactionById}
          title={t('label.transactions')}
          isSearch={isSearchDataModal}
        >
          <div className="col-md-12">
            <h4 className="text-center">{t('label.collectionActionsForInvoice')}</h4>
            <DataTable
              columns={tableCurrencyCollectionById}
              data={itemData.unitActionList ? itemData.unitActionList : []}
            />
          </div>
        </ModalDetailsItem>
      </Card>
    );
  }
}

Transactions.propTypes = {
  data: PropTypes.array,
  // history: PropTypes.object,
};

export default withTranslation('common')(Transactions);
