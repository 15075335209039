export default {
  transactionType: {
    oneTime: 'ONE_TIME',
    oneTimeCancel: 'ONE_TIME_CANCEL',
    forwardRecurring: 'FORWARD_RECURRING',
    arrearsRecurring: 'ARREARS_RECURRING',
    forwardBilledGrant: 'FORWARD_BILLED_GRANT',
    arrearsBilledGrant: 'ARREARS_BILLED_GRANT',
    usage: 'USAGE',
    billDiscount: 'BILL_DISCOUNT',
    tax: 'TAX',
  },
  makePayment: {
    item1: 'item1',
    item2: 'item2',
    item3: 'item3',
    item4: 'item4',
  },

  serviceType: {
    default: 'DEFAULT',
    voip: 'VOIP',
    cable: 'CABLE',
    utilities: 'UTILITIES',
    retail: 'RETAIL',
    wholesale: 'WHOLESALE',
    wireline: 'WIRELINE',
    telephony: 'TELEPHONY',
    iptv: 'IPTV',
    data: 'DATA',
    professional: 'PROFESSIONAL',
    internet: 'INTERNET',
    homesecurity: 'HOMESECURITY',
    voice: 'VOICE',
    sms: 'SMS',
    mms: 'MMS',
    email: 'EMAIL',
  },

  arOpsType: {
    BILL_ADJUSTMENT: 'BILL_ADJUSTMENT',
    TRANSACTION_ADJUSTMENT: 'TRANSACTION_ADJUSTMENT',
    BILL_DISPUTE: 'BILL_DISPUTE',
    TRANSACTION_DISPUTE: 'TRANSACTION_DISPUTE',
    BILL_SETTLEMENT: 'BILL_SETTLEMENT',
    ACCOUNT_WRITEOFF: 'ACCOUNT_WRITEOFF',
    INVOICE_WRITEOFF: 'INVOICE_WRITEOFF',
    TRANSACTION_SETTLEMENT: 'TRANSACTION_SETTLEMENT',
    WRITE_OFF: 'WRITE_OFF',
    WRITE_OFF_REVERSAL: 'WRITE_OFF_REVERSAL',
    PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
    PAYMENT: 'PAYMENT',
    REFUND: 'REFUND',
    CHARGEBACK: 'CHARGEBACK',
    TAX: 'TAX',
    TAX_ADJUSTMENT: 'TAX_ADJUSTMENT',
    TAX_SETTLEMENT: 'TAX_SETTLEMENT',
    TAX_DISPUTE: 'TAX_DISPUTE',
    TAX_WRITEOFF: 'TAX_WRITEOFF',
    TAX_WRITEOFF_REVERSAL: 'TAX_WRITEOFF_REVERSAL',
  },

  taxRule: {
    WITH_TAX: 'WITH_TAX',
    WITHOUT_TAX: 'WITHOUT_TAX',
    TAX_ONLY: 'TAX_ONLY',
  },

  CRDRType: {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
  },

  source: {
    EXTERNAL: 'EXTERNAL',
    SELF_CARE: 'SELF_CARE',
    AGENT_CARE: 'AGENT_CARE',
    RERATING: 'RERATING',
    PAYMENTS: 'PAYMENTS',
    PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
    INTERNAL_BATCH_JOBS: 'INTERNAL_BATCH_JOBS',
  },

  status: {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    REVERSED: 'REVERSED',
  },

  phoneType: {
    work: 'WORK',
    home: 'HOME',
    mobile: 'MOBILE',
    fax: 'FAX',
  },
};
