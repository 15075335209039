import { call, put, takeLatest } from 'redux-saga/effects';
import { queryRequest } from '../../utils/request';
import {} from 'react-router-redux';
import { getBalanceUnitByAccountId } from '../../api';
import {} from '../Notification/actions';
import {} from '../../constantsApp';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';

export function* getBalanceUnitSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBalanceUnitByAccountId(payload));
    if (response.getBalanceUnitByAccountId) {
      yield put(actions.getBalanceUnitSuccess(response.getBalanceUnitByAccountId));
    } else {
      yield put(actions.getBalanceUnitFailure(`Can not get balance units for account ${payload}`));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(actions.getBalanceUnitFailure(`Can not get balance units for account ${payload}`));
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* customersSaga() {
  yield takeLatest(types.GET_BALANCE_UNIT, getBalanceUnitSaga);
}
