import { toast } from 'react-toastify';
import client from '../config/ApolloClient';

export function queryRequest(query) {
  return client
    .query({
      query,
    })
    .then(data => {
      if (data.errors) {
        const errorMessage =
          data.errors && data.errors.length > 0 ? data.errors[0].extensions.errorMessage : data.errors[0].errorMessage;
        const errorMessage2 = data.errors && data.errors.length > 0 && data.errors[0].message;
        toast.error(errorMessage || errorMessage2);
        return Promise.reject(new Error({ message: errorMessage }));
      }
      return Promise.resolve(data.data);
    })
    .catch(err => {
      const errorMessage =
        err.graphQLErrors && err.graphQLErrors.length > 0
          ? err.graphQLErrors[0].extensions.errorMessage
          : err.graphQLErrors[0].errorMessage;
      const errorMessage2 = err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].message;
      toast.error(errorMessage || errorMessage2);
      return Promise.reject(err);
    });
}

export function mutationRequest(mutation) {
  return client
    .mutate({
      mutation,
    })
    .then(data => {
      if (data.errors) {
        const errorMessage =
          data.errors && data.errors.length > 0 ? data.errors[0].extensions.errorMessage : data.errors[0].errorMessage;
        const errorMessage2 = data.errors && data.errors.length > 0 && data.errors[0].message;
        toast.error(errorMessage || errorMessage2);
        return Promise.reject(new Error({ message: errorMessage }));
      }
      return Promise.resolve(data.data);
    })
    .catch(err => {
      const errorMessage =
        err.graphQLErrors && err.graphQLErrors.length > 0
          ? err.graphQLErrors[0].extensions.errorMessage
          : err.graphQLErrors[0].errorMessage;
      const errorMessage2 = err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].message;
      toast.error(errorMessage || errorMessage2);
      return Promise.reject(err);
    });
}
