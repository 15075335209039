/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import * as types from './constants';

/**
 * Load the repositories, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_REPOS
 */
export function loadRepos() {
  return {
    type: types.LOAD_REPOS,
  };
}

/**
 * Dispatched when the repositories are loaded by the request saga
 *
 * @param  {array} repos The repository data
 * @param  {string} username The current username
 *
 * @return {object}      An action object with a type of LOAD_REPOS_SUCCESS passing the repos
 */
export function reposLoaded(repos, username) {
  return {
    type: types.LOAD_REPOS_SUCCESS,
    repos,
    username,
  };
}

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export function repoLoadingError(error) {
  return {
    type: types.LOAD_REPOS_ERROR,
    error,
  };
}

export function getSelfCareOffers(payload, cb) {
  return {
    type: types.GET_SELF_CARE_OFFERS,
    payload,
    cb,
  };
}

export function getBundleByPackageId(payload, cb) {
  return {
    type: types.GET_BUNDLE_ID_BY_PACKAGE_ID,
    payload,
    cb,
  };
}

export function getAccountById(payload, cb) {
  return {
    type: types.GET_ACCOUNT_BY_ID,
    payload,
    cb,
  };
}

export function getAccountByIdSuccess(payload, cb) {
  return {
    type: types.GET_ACCOUNT_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function manageAccount(payload, cb) {
  return {
    type: types.MANAGE_ACCOUNT,
    payload,
    cb,
  };
}

export function searchPackage(payload, cb) {
  return {
    type: types.SEARCH_PACKAGE,
    payload,
    cb,
  };
}

// permissions
export function getRoleAndRoleGroupById(payload, cb) {
  return {
    type: types.GET_ROLE_AND_ROLE_GROUP_BY_ID,
    payload,
    cb,
  };
}

export function getRoleAndRoleGroupByIdSuccess(payload, cb) {
  return {
    type: types.GET_ROLE_AND_ROLE_GROUP_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function getRoleById(payload, cb) {
  return {
    type: types.GET_ROLE_BY_ID,
    payload,
    cb,
  };
}

export function getRoleIdByRoleGroups(payload, cb) {
  return {
    type: types.GET_ROLE_ID_BY_ROLEGROUP,
    payload,
    cb,
  };
}

export function getPermissionByIdSuccess(payload, cb) {
  return {
    type: types.GET_PERMISSIONS_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function removePermissions() {
  return {
    type: types.REMOVE_PERMISSIONS,
  };
}

export function selectInvoiceId(payload, cb) {
  return {
    type: types.SELECT_INVOICE_UNITS_ID,
    payload,
    cb,
  };
}

export function modifyAccountById(payload, cb) {
  return {
    type: types.MODIFY_ACCOUNT_BY_ID,
    payload,
    cb,
  };
}

export function searchOrders(payload, cb) {
  return {
    type: types.SEARCH_ORDERS,
    payload,
    cb,
  };
}

// for get customer activity
export function getCustomerActivity(payload, cb) {
  return {
    type: types.GET_CUSTOMER_ACTIVITY,
    payload,
    cb,
  };
}

export function getActivityById(payload, cb) {
  return {
    type: types.GET_ACTIVITY_BY_ID,
    payload,
    cb,
  };
}

export function searchNotesUnits(payload, cb) {
  return {
    type: types.SEARCH_NOTE_UNITS,
    payload,
    cb,
  };
}

export function getExternalPOByAccountId(payload, cb) {
  return {
    type: types.GET_EXTERNAL_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function getCustomAttributesByObjectType(cb) {
  return {
    type: types.GET_CUSTOM_ATTRIBUTES,
    cb,
  };
}

export function getS3BuctketSucess(payload, cb) {
  return {
    type: types.GET_S3_BUCKET_SUCESS,
    payload,
    cb,
  };
}

export function readFileFromS3(payload, cb) {
  return {
    type: types.READ_FILE_FROM_S3,
    payload,
    cb,
  };
}

export function getTenantSelfCareConfig(payload, cb) {
  return {
    type: types.GET_TENANT_SELF_CARE_CONFIG,
    payload,
    cb,
  };
}

export function getTenantSelfCareConfigSuccess(payload, cb) {
  return {
    type: types.GET_TENANT_SELF_CARE_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function getTenantDefaultConfig(cb) {
  return {
    type: types.GET_TENANT_DEFAULT_CONFIG,
    cb,
  };
}

export function getTenantDefaultConfigSuccess(payload, cb) {
  return {
    type: types.GET_TENANT_DEFAULT_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function getAwsS3Credential(payload, cb) {
  return {
    type: types.GET_AWS_S3_CREDENTIAL,
    payload,
    cb,
  };
}

export function getLogoSuccess(payload, cb) {
  return {
    type: types.GET_LOGO_SUCCESS,
    payload,
    cb,
  };
}

export function getObjectFileById(payload, cb) {
  return {
    type: types.GET_OBJECT_FILE_BY_ID,
    payload,
    cb,
  };
}

export function searchPaymentByAccount(payload, cb) {
  return {
    type: types.SEARCH_PAYMENT_BY_ACCOUNT,
    payload,
    cb,
  };
}

export function redirectMCMSelfCare(payload, cb) {
  return {
    type: types.REDIRECT_MCM_SELF_CARE,
    payload,
    cb,
  };
}

export function uploadMultiPartFiles(payload, cb, isSendEmail) {
  return {
    type: types.UPLOAD_MULTI_PART_FILE,
    payload,
    cb,
    isSendEmail,
  };
}

export function getCurrencyConfigApp(payload, cb) {
  return {
    type: types.GET_CURRENCY_CONFIG,
    payload,
    cb,
  };
}
export function getCurrencyConfigAppSuccess(payload, cb) {
  return {
    type: types.GET_CURRENCY_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function getCcpPropertiesConfig(payload, cb) {
  return {
    type: types.GET_CCP_PROPERTIES_CONFIG,
    payload,
    cb,
  };
}

export function getCcpPropertiesConfigSuccess(payload, cb) {
  return {
    type: types.GET_CCP_PROPERTIES_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function onResetDefaultData(payload, cb) {
  return {
    type: types.ON_RESET_DEFAUL_DATA,
    payload,
    cb,
  };
}

// getBroadsoftZeroDurationCdrsReport
export function getBroadsoftZeroDurationCdrsReport(payload, cb) {
  return {
    type: types.GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    payload,
    cb,
  };
}

export function getBroadsoftZeroDurationCdrsReportSuccess(payload, cb) {
  return {
    type: types.GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllBroadsoftZeroDurationCdrsReport(payload, cb) {
  return {
    type: types.GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    payload,
    cb,
  };
}

export function getAllBroadsoftZeroDurationCdrsReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getRawCdrData(payload, cb) {
  return {
    type: types.GET_RAW_CDR_DATA_REPORT,
    payload,
    cb,
  };
}

export function getRawCdrDataSuccess(payload, cb) {
  return {
    type: types.GET_RAW_CDR_DATA_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRawCdrData(payload, cb) {
  return {
    type: types.GET_ALL_RAW_CDR_DATA_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function getAllRawCdrDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_RAW_CDR_DATA_REPORT_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getConfigFailureCodes(payload, cb) {
  return {
    type: types.GET_CONFIG_FAILURE_CODES,
    payload,
    cb,
  };
}

export function getConfigIdd(payload, cb) {
  return {
    type: types.GET_CONFIG_IDD,
    payload,
    cb,
  };
}

export function getConfigIdo(payload, cb) {
  return {
    type: types.GET_CONFIG_IDO,
    payload,
    cb,
  };
}

export function getConfigIncomingOutgoingRoute(payload, cb) {
  return {
    type: types.GET_CONFIG_INCOMING_OUTGOING_ROUTE,
    payload,
    cb,
  };
}

export function getSplitTaxPerLineConfig(payload, cb) {
  return {
    type: types.GET_SPLIT_TAX_PER_LINE_CONFIG,
    payload,
    cb,
  };
}

export function getConfigFailureCodesList(payload, cb) {
  return {
    type: types.GET_CONFIG_FAILURE_CODES_LIST,
    payload,
    cb,
  };
}

export function getConfigSreserved(payload, cb) {
  return {
    type: types.GET_CONFIG_SRESERVED,
    payload,
    cb,
  };
}

export function getConfigOrganization(payload, cb) {
  return {
    type: types.GET_CONFIG_ORGANIZATION,
    payload,
    cb,
  };
}

export function getNonBroadSoftRawCdrData(payload, cb) {
  return {
    type: types.GET_NON_BROAD_SOFT_RAW_CDR_DATA,
    payload,
    cb,
  };
}

export function getNonBroadSoftRawCdrDataSuccess(payload, cb) {
  return {
    type: types.GET_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS,
    payload,
    cb,
  };
}

export function getAllNonBroadSoftRawCdrData(payload, cb) {
  return {
    type: types.GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA,
    payload,
    cb,
  };
}

export function getAllNonBroadSoftRawCdrDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS,
    payload,
    cb,
  };
}

export function getOutputTemplateByType(payload, cb) {
  return {
    type: types.GET_OUTPUT_TEMPLATE_BY_TYPE,
    payload,
    cb,
  };
}

// getNonBroadsoftZeroDurationCdrsReport
export function getNonBroadsoftZeroDurationCdrsReport(payload, cb) {
  return {
    type: types.GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    payload,
    cb,
  };
}

export function getNonBroadsoftZeroDurationCdrsReportSuccess(payload, cb) {
  return {
    type: types.GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllNonBroadsoftZeroDurationCdrsReport(payload, cb) {
  return {
    type: types.GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    payload,
    cb,
  };
}

export function getAllNonBroadsoftZeroDurationCdrsReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRawSmsData
export function getRawSmsData(payload, cb) {
  return {
    type: types.GET_RAW_SMS_DATA_REPORT,
    payload,
    cb,
  };
}

export function getRawSmsDataSuccess(payload, cb) {
  return {
    type: types.GET_RAW_SMS_DATA_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRawSmsData(payload, cb) {
  return {
    type: types.GET_ALL_RAW_SMS_DATA_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function getAllRawSmsDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_RAW_SMS_DATA_REPORT_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getDistinctUsageTypes(payload, cb) {
  return {
    type: types.GET_DISTINCT_USAGE_TYPES,
    payload,
    cb,
  };
}

export function getDistinctOrganization(payload, cb) {
  return {
    type: types.GET_DISTINCT_ORGANIZATION,
    payload,
    cb,
  };
}

// On Demand
export function getDataServiceReportData(payload, cb) {
  return {
    type: types.GET_LIST_ON_DEMAND,
    payload,
    cb,
  };
}

export function getDataServiceReportDataSuccess(payload, cb) {
  return {
    type: types.GET_LIST_ON_DEMAND_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllDataServiceReportData(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ON_DEMAND,
    payload,
    cb,
  };
}

export function getListAllDataServiceReportDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ON_DEMAND_SUCCESS,
    payload,
    cb,
  };
}

// getDataServiceReportDetails
export function getDataServiceReportDetails(payload, cb) {
  return {
    type: types.GET_DATA_SERVICE_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function getAllDataServiceReportDetails(payload, cb) {
  return {
    type: types.GET_ALL_DATA_SERVICE_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function applyPayment(payload, cb) {
  return {
    type: types.APPLY_PAYMENT,
    payload,
    cb,
  };
}

export function searchAccounts(payload, cb) {
  return {
    type: types.SEARCH_ACCOUNTS,
    payload,
    cb,
  };
}

export function searchAccountsSuccess(payload, cb) {
  return {
    type: types.SEARCH_ACCOUNTS_SUCCESS,
    payload,
    cb,
  };
}

export function getParentAccountDetail(payload, cb) {
  return {
    type: types.GET_PARENT_ACCOUNT_DETAIL,
    payload,
    cb,
  };
}

export function resetData(payload, cb) {
  return {
    type: types.RESET_DATA,
    payload,
    cb,
  };
}
