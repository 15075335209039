import React, { Component } from 'react';
import _ from 'lodash';
import { getAccountId, getUserName } from '../../localStorage';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.scss';
import {
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Row,
  FormFeedback,
} from 'reactstrap';
import { SLIDE_UP } from 'react-ladda';
import Select from 'react-select';
import { countryList, stateUSData } from '../../constantsApp/countryList';
import { getCities, getZips } from '../../utils/utils';
import { ButtonCustom } from '../common';

class ManageProfileForm extends Component {
  constructor() {
    super();
    this.state = {
      profile: {},
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      isSubmitting: false,
      hadPressSubmit: false,
      street: '',
      city: '',
      state: '',
      country: 'USA',
      postalCode: '',
      userId: getUserName(),
      password: '',
      retypePassword: '',
      optionsCities: [],
      isChecked: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { profile } = nextProps;
    if (
      JSON.stringify(this.state.profile) !== JSON.stringify(nextProps.profile)
    ) {
      const contacts = profile.contacts || [];
      const contact = contacts && contacts.length > 0 ? contacts[0] : {};
      const addresses = profile.addresses || [];
      const address = addresses && addresses.length > 0 ? addresses[0] : {};

      this.setState({
        profile,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phoneNumber:
          contact.phones && contact.phones.length > 0 && contact.phones[0]
            ? contact.phones[0].number
            : '',
        street: address.street,
        country: address.country,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        password: '',
      });
    }
  }

  componentDidMount() {}

  onChangeText = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onChangeCheckBox = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onBlurUserId = () => {};

  onChangeCountry = countryVal => {
    const { country } = this.state;
    if (
      (country && country !== countryVal) ||
      (!country && countryVal === 'USA')
    ) {
      this.setState({
        state: '',
        city: '',
        postalCode: '',
        country: countryVal,
      });
    } else {
      this.setState({ country: countryVal });
    }
  };

  onChangeState = stateVal => {
    const { state } = this.state;
    if (state && state !== stateVal) {
      this.setState({
        state: stateVal,
        city: '',
        postalCode: '',
      });
    } else {
      this.setState({ state: stateVal });
    }
  };

  onChangeCity = cityVal => {
    const { city } = this.state;
    if (city && city !== cityVal) {
      this.setState({
        city: cityVal,
        postalCode: '',
      });
    } else {
      this.setState({ city: cityVal });
    }
  };

  onChangePostalCode = postalCodeVal => {
    this.setState({ postalCode: postalCodeVal });
  };

  handleSubmit = evt => {
    evt.preventDefault();

    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      street,
      state,
      city,
      postalCode,
      userId,
      password,
      retypePassword,
      profile,
      country,
    } = this.state;

    if (password !== retypePassword) return alert('Please fill valid form');
    const { manageAccount } = this.props;
    const paymentProfile =
      profile &&
      profile.paymentProfiles &&
      profile.paymentProfiles.length > 0 &&
      profile.paymentProfiles[0]
        ? profile.paymentProfiles[0]
        : {};
    const creditCard =
      paymentProfile &&
      paymentProfile.creditCards &&
      paymentProfile.creditCards.length > 0 &&
      paymentProfile.creditCards[0]
        ? paymentProfile.creditCards[0]
        : {};
    return manageAccount(
      {
        accountId: getAccountId(),
        userId,
        password,
        selfCareAddress: {
          street,
          city,
          state,
          country,
          postalCode,
        },
        selfCareContact: {
          firstName,
          lastName,
          email,
          number: phoneNumber,
        },
      },
      () => {
        this.setState({ isSubmitting: false });
      },
    );
  };

  renderState() {
    const { country, state, hadPressSubmit } = this.state;
    if (country === 'USA') {
      let stateSelected = null;
      if (country === 'USA' && state) {
        const st = stateUSData.find(el => el.value === state);
        stateSelected = st
          ? { label: `${st.label} (${st.value})`, value: st.value }
          : null;
      }
      return (
        <FormGroup>
          <Label for="country">State:</Label>
          <Select
            className={state ? '' : 'select-field-error'}
            value={stateSelected}
            options={stateUSData.map(item => ({
              label: `${item.label} (${item.value})`,
              value: item.value,
            }))}
            onChange={val => this.onChangeState(val.value)}
          />
        </FormGroup>
      );
    }

    return (
      <FormGroup>
        <Label for="state">State:</Label>
        <Input
          type="text"
          name="state"
          id="state"
          placeholder="State"
          value={state}
          onChange={this.onChangeText}
        />
        <FormFeedback valid={!hadPressSubmit}>
          <b>State</b>
          {' '}
can not be blank.
        </FormFeedback>
      </FormGroup>
    );
  }

  getCitiesFormState = () => {
    const { state } = this.state;
    const options =
      getCities(state).map(item => ({
        label: item.city,
        value: item.city,
      })) || [];
    this.setState({ optionsCities: options });
  };

  onSetDefaulOptionCities = () => {
    this.setState({ optionsCities: [] });
  };

  renderCity() {
    const { city, hadPressSubmit, country, optionsCities } = this.state;
    const { t } = this.props;
    if (country === 'USA') {
      let citySelected = null;
      if (city) {
        citySelected = { value: city, label: city };
      }
      return (
        <FormGroup>
          <Label for="city">{`${t('label.city')}:`}</Label>
          <Select
            className={city ? '' : 'select-field-error'}
            value={citySelected}
            onChange={val => this.onChangeCity(val.value)}
            id="city"
            options={optionsCities}
            onFocus={this.getCitiesFormState}
            onBlur={this.onSetDefaulOptionCities}
          />
        </FormGroup>
      );
    }
    return (
      <FormGroup>
        <Label for="city">{`${t('label.city')}:`}</Label>
        <Input
          type="text"
          name="city"
          id="city"
          placeholder="City"
          value={city}
          onChange={this.onChangeText}
        />
        <FormFeedback valid={!hadPressSubmit}>
          <b>{`${t('label.city')}`}</b> 
          {' '}
          {`${t('message.canNotBeBlank')}`}
        </FormFeedback>
      </FormGroup>
    );
  }

  renderPostalCode() {
    const { t } = this.props;
    const { postalCode, hadPressSubmit, country, state, city } = this.state;
    if (country === 'USA') {
      let postalCodeSelected = null;
      if (postalCode) {
        postalCodeSelected = { value: postalCode, label: postalCode };
      }
      return (
        <FormGroup>
          <Label for="country">{`${t('label.postalCode')}:`}</Label>
          <Select
            name="address_postalCode"
            className={postalCode ? '' : 'select-field-error'}
            options={
              state && city
                ? getZips(city, state).map(item => ({
                    label: item.zipcode,
                    value: item.zipcode,
                  }))
                : [
                    {
                      label: 'Please select City before',
                      value: null,
                      isDisabled: true,
                    },
                  ]
            }
            value={postalCodeSelected}
            onChange={val => this.onChangePostalCode(val.value)}
          />
        </FormGroup>
      );
    }
    return (
      <FormGroup>
        <Label for="postalCode">{`${t('label.postalCode')}:`}</Label>
        <Input
          type="text"
          name="postalCode"
          id="postalCode"
          placeholder="postalCode"
          value={postalCode}
          onChange={this.onChangeText}
        />
        <FormFeedback valid={!hadPressSubmit}>
          <b>{`${t('label.postalCode')}`}</b> 
          {' '}
          {`${t('message.canNotBeBlank')}`}
        </FormFeedback>
      </FormGroup>
    );
  }

  renderBasicInfo() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      street,
      userId,
      password,
      retypePassword,
      hadPressSubmit,
      country,
      isChecked,
    } = this.state;
    let countrySelected = null;
    if (country) {
      const ctr = countryList.find(el => el.value === country);
      countrySelected = {
        label: ctr ? `${ctr.label} (${ctr.value})` : null,
        value: ctr ? ctr.value : null,
      };
    }

    const { modeModifyContact, t } = this.props;

    return (
      <div>
        <Col md={12}>
          <Row>
            {modeModifyContact !== 0 && (
              <Col md={4}>
                <FormGroup>
                  <Label for="firstName">{`${t('label.firstName')}:`}</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    disabled={modeModifyContact === 1}
                    value={firstName}
                    onChange={this.onChangeText}
                  />
                  <FormFeedback valid={!hadPressSubmit}>
                    <b>{`${t('label.firstName')}`}</b>
                    {`${t('message.canNotBeBlank')}`}
                  </FormFeedback>
                </FormGroup>
              </Col>
            )}
            {modeModifyContact !== 0 && (
              <Col md={4}>
                <FormGroup>
                  <Label for="lastName">{`${t('label.lastName')}:`}</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    disabled={modeModifyContact === 1}
                    value={lastName}
                    onChange={this.onChangeText}
                  />
                  <FormFeedback valid={!hadPressSubmit}>
                    <b>{`${t('label.lastName')}`}</b>
                    {`${t('message.canNotBeBlank')}`}
                  </FormFeedback>
                </FormGroup>
              </Col>
            )}
            <Col md={4}>
              <FormGroup>
                <Label for="street">{`${t('label.street')}:`}</Label>
                <Input
                  type="text"
                  name="street"
                  id="street"
                  placeholder="Street"
                  value={street}
                  onChange={this.onChangeText}
                />
                <FormFeedback valid={!hadPressSubmit}>
                  <b>{`${t('label.street')}`}</b>
                  {`${t('message.canNotBeBlank')}`}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4}>{this.renderPostalCode()}</Col>
            <Col md={4}>
              <FormGroup>
                <Label for="country">{`${t('label.country')}:`}</Label>
                <Select
                  value={countrySelected}
                  options={countryList.map(item => ({
                    label: `${item.label} (${item.value})`,
                    value: item.value,
                  }))}
                  placeholder="Country"
                  onChange={val => this.onChangeCountry(val.value)}
                  className="form__form-group-select"
                  inputProps={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'off',
                  }}
                />
                <FormFeedback valid={!hadPressSubmit}>
                  <b>{`${t('label.Country')}`}</b>
                  {`${t('message.canNotBeBlank')}`}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4}>{this.renderState()}</Col>
            <Col md={4}>{this.renderCity()}</Col>
            {modeModifyContact !== 0 && (
              <Col sm={4}>
                <FormGroup>
                  <Label for="email">{`${t('label.email')}:`}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    disabled={modeModifyContact === 1}
                    value={email}
                    onChange={this.onChangeText}
                  />
                  <FormFeedback valid={!hadPressSubmit}>
                    <b>{`${t('label.email')}`}</b>
                    {`${t('message.canNotBeBlank')}`}
                  </FormFeedback>
                </FormGroup>
              </Col>
            )}
            {modeModifyContact !== 0 && (
              <Col sm={4}>
                <FormGroup>
                  <Label for="phoneNumber">
                    {`${t(
                    'label.phoneNumber',
                  )}:`}

                  </Label>
                  <Input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    disabled={modeModifyContact === 1}
                    value={phoneNumber}
                    onChange={this.onChangeText}
                  />
                  <FormFeedback valid={!hadPressSubmit}>
                    <b>{`${t('label.phoneNumber')}`}</b>
                    {`${t('message.canNotBeBlank')}`}
                  </FormFeedback>
                </FormGroup>
              </Col>
            )}
            <Col md={4}>
              <FormGroup>
                <Label for="userId">{`${t('label.userId')}:`}</Label>
                <Input
                  type="text"
                  name="userId"
                  id="userId"
                  placeholder="User Id"
                  value={userId}
                  onChange={this.onChangeText}
                  onBlur={this.onBlurUserId}
                />
                <FormFeedback valid={!hadPressSubmit}>
                  <b>{`${t('label.userId')}`}</b>
                  {`${t('message.canNotBeBlank')}`}
                </FormFeedback>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label for="password">{`${t('label.password')}:`}</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="***"
                  value={password}
                  onChange={this.onChangeText}
                />
                <FormFeedback valid={!hadPressSubmit}>
                  <b>{`${t('label.password')}`}</b>
                  {`${t('message.canNotBeBlank')}`}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="retypePassword">
                  {`${t(
                  'label.retypePassword',
                )}:`}

                </Label>
                <Input
                  type="password"
                  invalid={password !== retypePassword}
                  name="retypePassword"
                  id="re-password"
                  placeholder="***"
                  value={retypePassword}
                  onChange={this.onChangeText}
                />
                <FormFeedback valid={!hadPressSubmit}>
                  <b>{`${t('label.retypePassword')}`}</b>
                  {`${t('message.canNotBeBlank')}`}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4} />
            <Col md={4} />
            <Col md={4}>
              <FormGroup style={{ paddingLeft: 30 }}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  checked={isChecked}
                  onChange={evt =>
                    this.onChangeCheckBox({
                      name: 'isChecked',
                      value: evt.target.checked,
                    })
                  }
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  {`${t('label.rememberMe')}`}
                </label>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }

  render() {
    const { isSubmitting } = this.state;
    const { modeModifyUser, t } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        className="title-form title-form-detail"
      >
        {this.renderBasicInfo()}
        <Row className="mt-sm-0 mx-0 row">
          <Col sm={{ size: 12, offset: 8 }} className="row float-right">
            <ButtonCustom
              loading={isSubmitting}
              className="ml-auto mt-auto mb-auto mr-2 btn-cancel"
              type="button"
              title={t('label.cancel')}
              titleloading="Modifying"
              datastyle={SLIDE_UP}
              onClick={() => {
                this.props.history.push('/');
              }}
            />
            {modeModifyUser !== 0 && (
              <ButtonCustom
                className="btn-submit"
                type="submit"
                title={t('label.submit')}
                disabled={modeModifyUser === 1}
                datastyle={SLIDE_UP}
              />
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}

ManageProfileForm.propTypes = {
  manageAccount: PropTypes.func,
  profile: PropTypes.object,
  modeModifyUser: PropTypes.number,
  modeModifyContact: PropTypes.number,
};

export default withTranslation('common')(ManageProfileForm);
