import gql from 'graphql-tag';
import { parseToMutationRequest } from '../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'status',
    'customerSegment',
    'type',
    'subType',
    'salesChannel',
    'marketSegment',
    'reason',
    'billingDom',
    'billingFrequency',
    'invoiceType',
    'invoiceDelivery',
    'paymentTerm',
    'paymentMethod',
    'fetchParentOnly',
    'activeSubscription',
    'subscriptionStatus',
    'creditProfileIndex',
    'trialLevels',
    'trialStatus',
  ]);
  const modifyAccount = `modifyAccount(input: ${dataCreate})`;
  return gql`
  mutation {
    ${modifyAccount} {
      id
    }
  }
`;
};
