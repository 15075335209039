import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/private-route';
// import Layout from '../Layouts';
import Register from '../RegisterNew';
import LoginPage from '../Login';
import DashBoardPage from '../DashBoardPage';
import ManageProfilePage from '../ManageProfilePage';
import ManageService from '../ManageServices';
import ManageBalances from '../ManageBalances';
import ManageCreditCard from '../ManageCreditCard';
import ViewInvoices from '../ViewInvoicesPage';
import Invoice from '../Invoice';
import MakePayment from '../MakePaymentPage';
import ViewTransactions from '../ViewTransactionsPage';
import ARActivity from '../ViewARActivityPage';
import RouterNames from './RouteNames';
import ViewAccountStatement from '../ViewAccountStatement';
import AccountDetails from '../../components/Register/AccountDetails';
import AccountInfo from '../../components/Register/AccountInfo';
import CreatePackage from '../../components/Register/CreatePackage';
import CredidCardDetails from '../../components/Register/CredidCardDetails';
import SelectPackage from '../../components/Register/SelectPackage';
import ViewCollectionsPage from '../ViewCollectionsPage';
import BillingProfile from '../BillingProfile';
import Orders from '../Orders';
import ViewAccountActivity from '../ViewAccountActivity';
import ViewCreditNotes from '../ViewCreditNotes';
import ViewExternalPO from '../ViewExternalPO';
import AccountInfoViewer from '../AccountInfo';
import CustomAttributes from '../CustomAttributes';
import ViewPayment from '../ViewPayment';
import BroadsoftZeroDurationCdrs from '../BroadsoftZeroDurationCdrs';
import RawCdrData from '../RawCdrData';
import RawNonBroadsoft from '../RawNonBroadsoft';
import NonBroadsoftZeroDurationCDR from '../NonBroadsoftZeroDurationCDR';
import RawSmsData from '../RawSmsData';
import OnDemand from '../OnDemand';
import SearchAccountsPage from '../SearchAccountsPage';
// import McmSelfCare from '../McmSelfCare';
// import McmSelfCareGatway from '../McmSelfCareGatway';

const WrappedRoutes = ({ ...rest }) => {
  return (
    // <Layout>
    <Switch>
      <Route exact path="/" component={DashBoardPage} />
      <Route path="/dashboard" exact component={DashBoardPage} />
      <Route path="/manage-profile" exact component={ManageProfilePage} />
      <Route path="/manage-credit-card" exact component={ManageCreditCard} />
      <Route path="/view-invoices" exact component={ViewInvoices} />
      <Route path="/invoices/:id" exact component={Invoice} />
      <Route path="/view-file/:id" exact component={Invoice} />
      <Route path="/make-payment" exact component={MakePayment} />
      <Route path="/view-transactions" exact component={ViewTransactions} />
      <Route path="/manage-service" exact component={ManageService} />
      <Route path="/manage-balances" exact component={ManageBalances} />
      <Route path="/accountdetails" exact component={AccountDetails} />
      <Route path="/accountinfo" exact component={AccountInfo} />
      <Route path="/createpackage" exact component={CreatePackage} />
      <Route path="/credidcarddetails" exact component={CredidCardDetails} />
      <Route path="/ar-activity" exact component={ARActivity} />
      <Route path="/account-statement" exact component={ViewAccountStatement} />
      <Route path="/selectpackage" exact component={SelectPackage} />
      <Route path="/view-collections" exact component={ViewCollectionsPage} />
      <Route path="/billing-profile" exact component={BillingProfile} />
      <Route path="/orders" exact component={Orders} />
      <Route path="/view-account-activity" exact component={ViewAccountActivity} />
      <Route path="/view-credit-notes" exact component={ViewCreditNotes} />
      <Route path="/credit-notes" exact component={ViewInvoices} />
      <Route path="/credit-notes/:id" exact component={Invoice} />
      <Route path="/purchase-order" exact component={ViewExternalPO} />
      <Route path={RouterNames.accountInfo.path} exact component={AccountInfoViewer} />
      <Route path={RouterNames.customerDetailsCustomAttributes.path} exact component={CustomAttributes} />
      <Route path="/view-payment-details" exact component={ViewPayment} />
      <Route path="/payment-details/:id" exact component={Invoice} />
      <Route
        path="/broadsoft-zero-duration-cdrs"
        exact
        component={props => <BroadsoftZeroDurationCdrs {...rest} {...props} />}
      />
      <Route path={RouterNames.rawCdr.path} exact component={props => <RawCdrData {...rest} {...props} />} />
      <Route path={RouterNames.nonBroadSoftRawCdrData.path} exact component={props => <RawNonBroadsoft {...rest} {...props} />} />
      <Route path={RouterNames.nonBroadsoftZeroDurationCdrs.path} exact component={props => <NonBroadsoftZeroDurationCDR {...rest} {...props} />} />
      <Route path={RouterNames.rawSMSFiles.path} exact component={props => <RawSmsData {...rest} {...props} />} />
      <Route path={RouterNames.onDemand.path} exact component={props => <OnDemand {...rest} {...props} />} />
      <Route path={RouterNames.searchAccounts.path} exact component={props => <SearchAccountsPage {...rest} {...props} />} />

      {/* <Route path="/mcm-self-care" exact component={McmSelfCare} /> */}
    </Switch>
    // </Layout>
  );
};

const Router = ({ ...rest }) => (
  <Switch>
    <Route exact path="/login" component={LoginPage} />
    <Route path="/register" component={Register} />
    <PrivateRoute path="" component={props => <WrappedRoutes {...props} {...rest} />} />
  </Switch>
);

export default Router;
