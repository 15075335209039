import gql from 'graphql-tag';

export default () => {
  return gql`
  query {
    getConfigOrganization(dummy: "") {
      configOrganizationList {
        value
        description
      }
    }
  }
`;
};
