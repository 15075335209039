import React from 'react';
import PropTypes from 'prop-types';
import { FaStar } from 'react-icons/fa';
import './styles.scss';

const TextInput = ({ type, onChange, value, ...props }) => {
  // const defaultValue = value || '';
  if (type === 'textarea') {
    return <textarea value={value} onChange={onChange} {...props} />;
  }
  return <input type={type} value={value} onChange={onChange} {...props} />;
};

TextInput.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextInput.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
};

const InputGroup = ({ label, wrapperClass, fa, isPopupInfo, labelPopupInfo, onOpenPopupInfo, ...props }) => (
  <div className={`form-group ${wrapperClass}`}>
    {label && (
      <label>
        {label}
        {label && props.required && <FaStar className="fa fa-star is-required" />}
        &nbsp;
      </label>
    )}
    <div className={`wr-input ${isPopupInfo ? 'd-flex' : ''}`}>
      <TextInput className="form-control input-group" {...props} />
      {fa && (
        <div className="wr-input-icon">
          <i className={`fa ${fa}`} />
        </div>
      )}
      {isPopupInfo && (
        <button type="button" className="button x-small btn-popup" onClick={onOpenPopupInfo}>
          {labelPopupInfo}
        </button>
      )}
    </div>
  </div>
);

InputGroup.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onOpenPopupInfo: PropTypes.func,
  required: PropTypes.bool,
};

InputGroup.defaultProps = {
  label: '',
  onChange: () => {},
  onOpenPopupInfo: () => {},
  type: 'text',
  wrapperClass: 'col-md-3',
  required: false,
};

export default InputGroup;
