import React from 'react';
import PropsType from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class ModalWithItem extends React.PureComponent {
  render() {
    const { isOpen, onToggle, modalTitle, children, wrapperClass, rightTitle, t, isSupportTopBackButton } = this.props;
    return (
      <Modal size="lg" isOpen={isOpen} toggle={onToggle} className={wrapperClass || ''}>
        {modalTitle && (
          <ModalHeader className="header-modal">
            {modalTitle}
            {rightTitle && <span className="modal-item-right-title">{rightTitle}</span>}
            {isSupportTopBackButton && (
              <button onClick={onToggle} type="button" className="button x-small float-right">
                {t('label.back')}
              </button>
            )}
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
      </Modal>
    );
  }
}

ModalWithItem.propTypes = {
  onToggle: PropsType.func.isRequired,
  children: PropsType.oneOfType([PropsType.arrayOf(PropsType.any), PropsType.objectOf(PropsType.any)]),
  isOpen: PropsType.bool.isRequired,
  modalTitle: PropsType.string,
  wrapperClass: PropsType.string,
};

ModalWithItem.defaultProps = {
  modalTitle: '',
  wrapperClass: '',
  children: {},
};

export default withTranslation('common')(ModalWithItem);
