// import React from 'react';
import gql from 'graphql-tag';

export default data => {
  const userLogin = `userLogin(input: {userName: "${
    data.userName
  }", password: "${data.password}"} )`;
  return gql`
  query {
    ${userLogin} {
      id
      userId
      accountId
      lastLoginDate
      lastPasswordChangeDate
      firstTimeLogin
      daysForPasswordChange
    }
  }
`;
};
