import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'type',
        'status',
        'isPartialFulfillmentAllowed',
        'initialTerm',
        'initialTermUnit',
        'renewalTerm',
        'renewalTermUnit',
        'trialTerm',
        'trialTermUnit',
        'index',
        'serviceType',
        'action',
        'quantity',
        'priceOverride',
        'priceOffset',
        'discountPercent',
        'relativeStart',
        'relativeEnd',
        'relativeStartUnit',
        'relativeEndUnit',
        'subscriptionReason',
        'trialType',
        'inFlightOrder',
      ])}`
    : '';
  const searchOrders = `searchOrders(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchOrders} {
        id
        type
        status
        reason
        accountId
        userId
        effectiveDate
        submittedDate
      }
    }
  `;
};
