import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TableInfo from '../../components/common/TableInfo';

const heads = [
  {
    key: 'id',
    name: 'label.id',
  },
  {
    key: 'Account Id',
    name: 'label.accountId',
  },
  {
    key: 'Status',
    name: 'label.status',
  },
  {
    key: 'Reason',
    name: 'label.reason',
  },
  {
    key: 'Initial Term',
    name: 'label.initialTerm',
  },

  {
    key: 'Initial Term Unit',
    name: 'label.initialTermUnit',
  },
  {
    key: 'Renewal Term',
    name: 'label.renewalTerm',
  },
  {
    key: 'Renewal Term Unit',
    name: 'label.renewalTermUnit',
  },
  {
    key: 'Trial Term',
    name: 'label.trialTerm',
  },
  {
    key: 'Trial Term Unit',
    name: 'label.trialTermUnit',
  },
  {
    key: 'Effective Date',
    name: 'label.effectiveDate',
  },
];

class TableSubsciption extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  renderRow = row => {
    const { t } = this.props;
    const status = t('selections:subscriptionStatus')().find(
      val => val.value === row.status,
    );
    const initialTermUnit = t('selections:termUnit')().find(
      val => val.value === row.initialTermUnit,
    );
    const renewalTermUnit = t('selections:termUnit')().find(
      val => val.value === row.renewalTermUnit,
    );
    const trialTermUnit = t('selections:trialTermUnit')().find(
      val => val.value === row.renewalTermUnit,
    );

    return (
      <tr key={row.id}>
        <td>{row.id}</td>
        <td>{row.accountId}</td>
        <td>{status ? status.label : ''}</td>
        <td>{row.reason}</td>
        <td>{row.initialTerm}</td>
        <td>{initialTermUnit ? initialTermUnit.label : ''}</td>
        <td>{row.renewalTerm}</td>
        <td>{renewalTermUnit ? renewalTermUnit.label : ''}</td>
        <td>{row.trialTerm}</td>
        <td>{trialTermUnit ? trialTermUnit.label : ''}</td>
        <td>{row.effectiveDate}</td>
      </tr>
    );
  };

  renderBody() {
    const { data, errorSubscription, t } = this.props;

    if (errorSubscription) {
      return (
        <tbody>
          <tr>
            <td colSpan={12} className="txt-error">
              {errorSubscription}
            </td>
          </tr>
        </tbody>
      );
    }

    if (!data || !data.id) {
      return (
        <tbody>
          <tr>
            <td colSpan={12}>{t('message.noRecord')}</td>
          </tr>
        </tbody>
      );
    }

    return <tbody>{this.renderRow(data)}</tbody>;
  }

  render() {
    const { data, title } = this.props;
    return (
      <TableInfo title={title} heads={heads}>
        {this.renderBody(data)}
      </TableInfo>
    );
  }
}

TableSubsciption.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  errorSubscription: PropTypes.string,
};

export default withTranslation('common')(TableSubsciption);
