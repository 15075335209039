import React, { Component } from 'react';
import planning from '../../assets/images/planning.png';
import './styles.scss';
import logodark from '../../assets/images/logo-dark.png';
class AccountInfo extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 logoimg">
            <img src={logodark} className="reglogo" /><br /><br />
          </div>
        </div>

        <div className="container">

          <div className="row row-margin-bottom">
            <div className="col-md-12 no-padding lib-item" data-category="view">
              <div className="lib-panel">
                <div className="row box-shadow">
                  <div className="col-md-3 bggreen">
                    <center><img className="lib-img-show" src={planning} /></center> 
                    <h2>Account Info</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                    dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                        book.</p>
                  </div>
                  <div className="col-md-9">
                    <div className="lib-row lib-header ">

                      <ul className="list-inline textctr">
                        <li className="list-inline-item"><h3>Do you already have a Account No?</h3></li><li className="list-inline-item"><a href="credidcarddetails"><button class="btn custbtn">Yes</button></a></li><li class="list-inline-item"><a href="accountdetails"><button class="btn custbtn">No</button></a></li></ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>

          </div>
        </div>



      </div>

    );
  }
}



export default AccountInfo;