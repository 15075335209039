import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['arType', 'arTaxRule', 'arCrDrType'])}`
    : '';
  const searchArOpsUnit = `searchArOpsUnit(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchArOpsUnit} {
        id
        endDate
        amount
        description
        type
        source
        invoiceId
        itemId
        arCrDrType
        arTaxRule
        reason
      }
    }
  `;
};
