import gql from 'graphql-tag';

export default id => gql`
  query {
    getArOpsUnitById(input: { id: "${id}" }) {
      id
      accountId
      invoiceId
      userId
      sharedId
      sharedAccountId
      originalId
      requestActivityId
      endDate
      notes
      description
      revRecType
      netAmount
      type
      source
      taxData {
        index
        taxCode
        taxableAmount
        exemptAmount
        taxAmount
        city
        state
        postalCode
        country
        jurisdiction
        code
        percent
      }
      arData {
        id
        serviceUnitId
        itemId
        reason
        taxCode
        arobjectid
        numberOfTransactions
        amount
        percent
        arCrDrType
        taxRule
      }
      balances {
        id
        currencyId
        balanceUnitId
        offerId
        minimum
        maximum
        unitPrice
        amount
        quantity
        type
      }
    }
  }
`;
