/*
 *
 * ManageProfilePage actions
 *
 */

import * as types from './constants';
// for get transaction unit
export function getArOpsUnit(payload, cb) {
  return {
    type: types.GET_AR_OPS_UNIT,
    payload,
    cb,
  };
}

export function getArOpsUnitSuccess(payload) {
  return {
    type: types.GET_AR_OPS_UNIT_SUCCESS,
    payload,
  };
}

export function setArOpsUnitParams(payload) {
  return {
    type: types.SET_AR_OPS_PARAMS,
    payload,
  };
}

export function getArOpsUnitById(payload, cb) {
  return {
    type: types.GET_AR_OPS_UNIT_BY_ID,
    payload,
    cb,
  };
}
