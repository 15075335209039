/*
 *
 * manageServices reducer
 *
 */

import { fromJS } from 'immutable';

import * as types from './types';

export const initialState = fromJS({
  subscription: {},
  serviceUnitsAc: [],
  errorServiceUnits: '',
  errorSubscription: '',
});

function manageServicesReducer(state = initialState, action) {
  switch (action.type) {
    // for get service units by account id
    case types.GET_SERVICE_UNITS_AC:
      return state.set('serviceUnitsAc', []).set('errorServiceUnits', '');
    case types.GET_SERVICE_UNITS_AC_SUCCESS:
      return state.set('serviceUnitsAc', action.payload);
    case types.GET_SERVICE_UNITS_AC_FAILURE:
      return state.set('errorServiceUnits', action.payload);
    // for get subscription
    case types.GET_SUBSCRIPTION:
      return state.set('subscription', []).set('errorSubscription', '');
    case types.GET_SUBSCRIPTION_SUCCESS:
      return state.set('subscription', action.payload);
    case types.GET_SUBSCRIPTION_FAILURE:
      return state.set('errorSubscription', action.payload);
    // for get balance units
    default:
      return state;
  }
}

export default manageServicesReducer;
