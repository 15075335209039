import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { TableServiceUnits, TableSubscription } from '../../components/ManageServices';
import { MainHeader } from '../../components/Header';
import { AccountHeaderForm } from '../../components/common';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { getAccountId } from '../../localStorage';
import { checkPermissionViewSubscription } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare } from '../App/selectors';
import {
  makeGetServiceUnitsAc,
  makeGetSubscription,
  makeErrorMessageSU,
  makeErrorMessageSubscription,
} from './selectors';
import reducer from './reducer';
import { getServiceUnitAc, getSubscription } from './actions';
import saga from './saga';
import './styles.scss';

/* eslint-disable react/prefer-stateless-function */
export class ManageServices extends React.Component {
  componentDidMount = () => {
    this.props.getServiceUnitAc(getAccountId());
    this.props.getSubscription(getAccountId());
  };

  render() {
    const { permissionsSelfCare, t } = this.props;
    let modeViewSubscription = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewSubscription = checkPermissionViewSubscription({
        listPermission,
      });
    }
    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="ManageServices" />
        {modeViewSubscription !== 0 && (
          <div className="manage-page__form">
            <div className="form-wrapper table-content">
              <div className="table-title table-title-form table-title-form-detail">
                <Row>
                  <Col md={12}>
                    {/* <h4 className="card-title-mb">
                      {`${t('label.services')} | ${t('label.accountNumber')}`}
                      <span className="account-number">
                        <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                        &nbsp;&nbsp;
                        <span className="account-detail">{getAccountId()}</span>
                      </span>
                    </h4> */}
                    <AccountHeaderForm title={`${t('label.services')} | ${t('label.accountNumber')}`} />
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <div className="card-body" style={{ paddingLeft: 0 }}>
                    <div className="card-header" id="heading1">
                      <h5 className="mb-0 ml-2 header-table">{t('label.subscription')}</h5>
                    </div>
                    <Col md={12}>
                      <TableSubscription
                        data={this.props.subscription}
                        errorSubscription={this.props.errorSubscription}
                      />
                    </Col>
                  </div>
                  <div className="card-body" style={{ paddingLeft: 0 }}>
                    <div className="card-header" id="heading1">
                      <h5 className="mb-0 ml-2 header-table">{t('label.serviceUnits')}</h5>
                    </div>
                    <Col md={12}>
                      <TableServiceUnits
                        data={this.props.serviceUnitsAc}
                        errorServiceUnits={this.props.errorServiceUnits}
                      />
                    </Col>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ManageServices.propTypes = {
  serviceUnitsAc: PropTypes.array,
  subscription: PropTypes.object,
  errorServiceUnits: PropTypes.string,
  errorSubscription: PropTypes.string,
  getServiceUnitAc: PropTypes.func,
  getSubscription: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  serviceUnitsAc: makeGetServiceUnitsAc() || [],
  subscription: makeGetSubscription() || {},
  errorServiceUnits: makeErrorMessageSU() || '',
  errorSubscription: makeErrorMessageSubscription() || '',
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    getServiceUnitAc: (data, cb) => dispatch(getServiceUnitAc(data, cb)),
    getSubscription: (data, cb) => dispatch(getSubscription(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'manageServices', reducer });
const withSaga = injectSaga({ key: 'manageServices', saga });

export default withTranslation(['common'])(compose(withReducer, withSaga, withConnect)(ManageServices));
