const S3 = require('aws-sdk/clients/s3');

export default ({ url, accessKey, region, secretKey }) => {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: 'embrix-tenants',
      Key: url,
    };
    const s3 = new S3({
      accessKeyId: accessKey || 'AKIAVGONWLSWKNDWOZDJ',
      secretAccessKey: secretKey || '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',
      signatureVersion: 'v4',
      region: region || 'us-east-1',
    });
    s3.getObject(params, (err, data) => {
      if (err) reject(err);
      else {
        const blob = new Blob([data.Body], { type: data.ContentType });
        // let base64data = null;
        // const reader = new FileReader();
        // reader.readAsDataURL(blob);
        // reader.onloadend = () => {
        //   base64data = reader.result;
        // };
        resolve(blob);
      }
    });
  });
};
