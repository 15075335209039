import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { isEmpty, cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { searchAccounts, getParentAccountDetail } from '../App/actions';
import { makeGetListCustomers, makeGetPermissionSelfCare, makeGetUserDetails } from '../App/selectors';
import { SearchAccountsForm } from '../../components/SearchAccounts';
import { MainHeader } from '../../components/Header';
import { DataTable, TablePagination, FormCollapse, GenericInput } from '../../components/common';
import { getPageTotalCount } from '../../utils/utils';
import { getAccountId, setAccountId } from '../../localStorage';
import { checkPermissionViewAccountSearch } from '../../utils/CheckPermissions';

const accountSort = {
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  firstName: {
    asc: 'firstName_ASC',
    desc: 'firstName_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
  city: {
    asc: 'city_ASC',
    desc: 'city_DESC',
  },
  state: {
    asc: 'state_ASC',
    desc: 'state_DESC',
  },
};

const detectParentIds = customers => {
  const parentIds = customers
    .filter(c => {
      return c.parentId && (isEmpty(c.contacts) || isEmpty(c.addresses));
    })
    .map(c => c.parentId);
  return parentIds.filter((item, index) => parentIds.indexOf(item) === index);
};

const collapsibleAdvancedSearch = [
  {
    title: 'label.advancedSearch',
    state: 'advancedSearch',
  },
];

const SearchAccountsPage = ({
  searchAccounts,
  getParentAccountDetail,
  listCustomers,
  permissionsSelfCare,
  userDetails,
}) => {
  const { t } = useTranslation('common');

  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [activeTab, setActiveTab] = useState({});
  const [accountSelected, setAccountSelected] = useState(getAccountId() || null);

  let modeViewAccounts = 0;

  if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
    const listPermission = permissionsSelfCare.selfCareModulePermissions;

    modeViewAccounts = checkPermissionViewAccountSearch({
      listPermission,
    });
  }

  const doSearchAccount = useCallback(() => {
    if (!modeViewAccounts || !userDetails || userDetails.category !== 'AGENT') return null;
    const newFilter = cloneDeep(filter);
    if (!newFilter.status) delete newFilter.status;
    if (!newFilter.fetchParentOnly) delete newFilter.fetchParentOnly;
    if (newFilter.fetchParentOnly) newFilter.fetchParentOnly = newFilter.fetchParentOnly === 'TRUE' ? 'true' : 'false';
    if (newFilter.collectionAgentAssigned)
      newFilter.collectionAgentAssigned = newFilter.collectionAgentAssigned === 'TRUE' ? 'true' : 'false';
    if (newFilter.childAccount) newFilter.childAccount = newFilter.childAccount === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? accountSort[sorted.sortCol][sorted.sortDir] : null,
    };
    setLoading(true);
    searchAccounts(payload, ({ data }) => {
      if (isEmpty(detectParentIds(data))) setLoading(false);
      detectParentIds(data).map(parentId =>
        getParentAccountDetail(parentId, () => {
          setLoading(false);
        })
      );
    });
  }, [filter, getParentAccountDetail, modeViewAccounts, page, searchAccounts, size, sorted, userDetails]);

  const onSubmitSearch = filter => {
    setFilter(filter);
    setPage(0);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onChangeAdvancedFilter = () => {};

  const onHandleResetAdvancedSearch = () => {};

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };

  useEffect(() => {
    doSearchAccount();
  }, [doSearchAccount]);

  useEffect(() => {
    setTotalCount(
      getPageTotalCount({
        totalCount,
        page,
        size,
        items: listCustomers && listCustomers.length ? listCustomers : [],
      })
    );
  }, [listCustomers, page, size, totalCount]);

  const tableColumns = [
    {
      name: 'action',
      label: 'label.select',
      render: (colName, item) => (
        <button
          className="btn-submit btn-rounded waves-effect waves-light mt-0 mb-0"
          type="button"
          disabled={(accountSelected && accountSelected === item.id) || modeViewAccounts === 1}
          onClick={() => {
            setAccountId(item.id);
            setAccountSelected(item.id);
            toast.success(t('apiMessage.messageSetAccountId')(item.id));
          }}
        >
          {t('common:label.select')}
        </button>
      ),
    },
    {
      name: 'id',
      label: 'label.ACCTNo',
    },
    {
      name: 'name',
      label: 'label.name',
    },
    {
      name: 'type',
      label: 'label.accountType',
      render: (colName, item) => {
        const slt = t('selections:accountType')().find(val => val.value === item.type);
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'firstName',
      label: 'label.firstName',
      sortable: true,
      render: (name, item) => {
        return item && item.contacts && item.contacts[0] ? item.contacts[0].firstName : null;
      },
    },
    {
      name: 'lastName',
      label: 'label.lastName',
      sortable: true,
      render: (name, item) => {
        return item && item.contacts && item.contacts[0] ? item.contacts[0].lastName : null;
      },
    },
    {
      name: 'organization',
      label: 'label.organization',
      sortable: true,
      style: { minWidth: '110px' },
      render: (name, item) => {
        return item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null;
      },
    },
    {
      name: 'effectiveDate',
      label: 'label.effectiveDate',
    },
    {
      name: 'taxId',
      label: 'label.taxId',
    },
    {
      name: 'foreignTaxId',
      label: 'label.foreignTaxId',
    },
    {
      name: 'email',
      label: 'label.email',
      render: (name, item) => {
        return item && item.contacts && item.contacts[0] ? item.contacts[0].email : null;
      },
    },
    {
      name: 'city',
      label: 'label.city',
      sortable: true,
      render: (name, item) => {
        return item && item.addresses && item.addresses[0] ? item.addresses[0].city : null;
      },
    },
    {
      name: 'state',
      label: 'label.state',
      sortable: true,
      style: { minWidth: '90px' },
      render: (name, item) => {
        return item && item.addresses && item.addresses[0] ? item.addresses[0].state : null;
      },
    },
    {
      name: 'currency',
      label: 'label.currency',
    },
    {
      name: 'status',
      label: 'label.status',
      render: (colName, item) => {
        const slt = t('selections:status')().find(val => val.value === item.status);
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'reason',
      label: 'label.reason',
      render: (colName, item) => {
        const slt = t('selections:reason')().find(val => val.value === item.reason);
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'salesAgentId',
      label: 'label.salesAgentId',
    },
    {
      name: 'collectionAgent',
      label: 'label.collectionAgentId',
    },
    {
      name: 'userId',
      label: 'label.userId',
    },
    {
      name: 'groupCompany',
      label: 'label.groupCompany',
    },
  ];

  return (
    <div className="manage-page global-page">
      <MainHeader activeTab="search-account" accountSelected={accountSelected} />
      {!!modeViewAccounts && userDetails && userDetails.category === 'AGENT' && (
        <div className="manage-page__form p-3">
          <div className="form-billing">
            <div className="col-md-12 row pt-4 group-input-billing">
              <SearchAccountsForm onSubmit={onSubmitSearch} onHandleResetAdvancedSearch={onHandleResetAdvancedSearch}>
                <div className="col-md-12 p-0 mt-3">
                  {collapsibleAdvancedSearch.map(item => {
                    return (
                      <FormCollapse
                        key={item.title}
                        label="First Name"
                        isActive={activeTab && item.state === activeTab.name && activeTab.isActive}
                        title={t(item.title) || ''}
                        state={item.state}
                        onToggleTab={onToggleTab}
                      >
                        <div className="col-md-12 mt-2 row">
                          <GenericInput
                            value={filter?.invoiceId || null}
                            wrapperClass="col-md-3"
                            label="label.invoiceId"
                            name="invoiceId"
                            disabled={!!filter.paymentId || !!filter.notesId}
                            onChange={onChangeAdvancedFilter}
                          />
                          <GenericInput
                            value={filter?.paymentId || null}
                            wrapperClass="col-md-3"
                            label="label.paymentId"
                            name="paymentId"
                            disabled={!!filter.invoiceId || !!filter.notesId}
                            onChange={onChangeAdvancedFilter}
                          />
                          <GenericInput
                            value={filter?.notesId || null}
                            wrapperClass="col-md-3"
                            label="label.notesId"
                            name="notesId"
                            disabled={!!filter.invoiceId || !!filter.paymentId}
                            onChange={onChangeAdvancedFilter}
                          />
                        </div>
                      </FormCollapse>
                    );
                  })}
                </div>
              </SearchAccountsForm>
            </div>
            <div className="group-input-billing col-md-12">
              <DataTable
                isFixedHeaderTable
                columns={tableColumns}
                data={listCustomers || []}
                onSort={onSortColumn}
                sorted={sorted}
                isLoading={isLoading}
              />
              <div className="mb-30 mt-3">
                <TablePagination
                  pageNumber={page}
                  pageSize={size}
                  totalCount={totalCount}
                  onPageChange={onPageChange}
                  onSizeChange={onSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SearchAccountsPage.propTypes = {};

SearchAccountsPage.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  listCustomers: makeGetListCustomers() || [],
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
  userDetails: makeGetUserDetails() || {},
});

export default connect(mapStateToProps, { searchAccounts, getParentAccountDetail })(SearchAccountsPage);
