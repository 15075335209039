import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../utils/utils';

export default ({ accountId, filter, page, size }) => {
  // const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['folioStatus'])}` : '';
  const getClientAccountStatement = `getClientAccountStatement(page: ${page}, size: ${size}, accountId: "${accountId}",${filterStr})`;
  return gql`
    query {
      ${getClientAccountStatement} {
        accountStatement{
          lineReferenceId
          accountId
          organization
          sellingcompany
          accountType
          accountSubType
          marketSegment
          salesChannel
          lineType
          openingBalance
          amount	
          closingBalance
          lineDate	
          invoiceId
          invoiceDate
          dueDate
          subTotal
          taxes
          status
          currency
          functionalCurrency
          exchangeRate
          amountInFunctionalCurrency
          folioStatus
        }
      }
    }
  `;
};
