import React, { Component } from 'react';
import './styles.scss';
import logodark from '../../assets/images/logo-dark.png';
import planning from '../../assets/images/planning.png';
import { FaEye } from 'react-icons/fa';
class SelectPackage extends Component {
  constructor() {
    super();
    this.state = {};
  }
render() {
  return (
   <div className="container-fluid">
    <div className="row">
        <div className="col-md-12 logoimg">
                  <img src={logodark} className="reglogo" /><br /><br />
            <hr width="50%"/>
            <ul class="list-inline ulcircle">
                <li className="list-inline-item "><div className="circle ">1</div></li>
                <li className="list-inline-item"><div className="circle ">2</div> </li>
                <li className="list-inline-item"><div className="circle active">3</div></li>
                <li className="list-inline-item"><div className="circle">4</div></li></ul>
        <ul class="list-inline uldetails">
                      <li className="list-inline-item" style={{ marginleft: '-15px' }}>Account Details</li>
                      <li className="list-inline-item" style={{ marginleft: '20px' }}>Card Details</li>
            <li className="list-inline-item">&nbsp;&nbsp;&nbsp;Select Package</li>
            <li className="list-inline-item">Create Your Package</li>
        </ul>
            </div>
    </div>

<div className="container">
    
    <div className="row row-margin-bottom">
        <div className="col-md-12 no-padding lib-item" data-category="view">
            <div className="lib-panel">
                <div className="row box-shadow">
                    <div className="col-md-3 bgsp align-items-center justify-content-center">
                                  <center><img className="lib-img-show" src={planning} /></center>
                        <h2>Select Package</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                        book.</p>
                    </div>
                    
                    <div className="col-md-9">
                        <div className="lib-row lib-header ">
                            <div className="row">
                            <ul className="list-inline packageul">
                                          <li class="list-inline-item"><div class="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div></li>
                                          <li className="list-inline-item"><div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div></li>
                                <li className="list-inline-item">
                                    <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                                </li>
                            </ul>
                       
                <ul className="list-inline packageul">
                    <li className="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                    <li className="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                    <li className="list-inline-item">
                                                  <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye /></i></div>
                    </li>
                </ul>

                <ul className="list-inline packageul">
                    <li className="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                    <li className="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                    <li className="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                </ul></div>
<div className="row">
                <ul className="list-inline packageul">
                    <li class="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                    <li class="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                    <li className="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                </ul>

                <ul className="list-inline packageul">
                    <li className="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                    <li class="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                    <li className="list-inline-item">
                        <div className="box-shadow package"><h4>Package</h4>Discription goes here,option<i><FaEye/></i></div>
                    </li>
                </ul>
                    
                         
                        </div>
                        <div className="clearfix"></div>
                        
                        <ul class="list-inline textctrsp">
                            <li className="list-inline-item"><a href="CredidCardDetails"><button className="btn custbtn">Previous</button></a></li>
                            <li className="list-inline-item"><a href="CreatePackage"><button className="btn custbtn">Next</button></a></li>
                        </ul>
                                  </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-1"></div>
        
    </div>
</div>


   
</div>
  );
}
}


export default SelectPackage;


