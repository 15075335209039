import gql from 'graphql-tag';

export default () => gql`
  query {
    getTenantInvoiceTemplates(dummy: "") {
      id
      tenantName
      invoiceTemplateName
    }
  }
`;
