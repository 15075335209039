/*
 *
 * ManageProfilePage actions
 *
 */

import * as types from './constants';

// for get pending bills
export function getPendingBill(payload, cb) {
  return {
    type: types.GET_PENDING_BILL,
    payload,
    cb,
  };
}

export function getPendingBillSuccess(payload) {
  return {
    type: types.GET_PENDING_BILL_SUCCESS,
    payload,
  };
}

export function getOCBill(payload, cb) {
  return {
    type: types.GET_OC_BILL,
    payload,
    cb,
  };
}

export function getOCBillSuccess(payload) {
  return {
    type: types.GET_OC_BILL_SUCCESS,
    payload,
  };
}
