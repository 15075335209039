import gql from 'graphql-tag';

export default id => gql`
  query {
    getPackageById(input: { id: "${id}" }) {
      id
      components{
        bundleId
      }
    }
  }
`;
