import React from 'react';
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelExport = ({ element, multiDataSet, nameSheet, fileName }) => (
  <ExcelFile element={element} filename={fileName || ''}>
    <ExcelSheet dataSet={multiDataSet} name={nameSheet || ''} />
  </ExcelFile>
);

export default ExcelExport;
