import React from 'react';
import PropTypes from 'prop-types';
import { SLIDE_UP } from 'react-ladda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { MainHeader } from '../../components/Header';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { ARActivity, SearchFilterARActivity } from '../../components/ViewARActivity';
import { ButtonCustom, TablePagination, AccountHeaderForm } from '../../components/common';
import { getAccountId } from '../../localStorage';
import { checkPermissionViewTransactions } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare } from '../App/selectors';
import { makeGetArOpsUnitUnit, makePageArOpsUnitParams } from './selectors';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';
import { getArOpsUnit, setArOpsUnitParams, getArOpsUnitById } from './actions';
import { getPageTotalCount } from '../../utils/utils';

export class ViewARActivityPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActiveNext: true,
      isSearching: false,
      page: 0,
      size: 20,
      filter: {},
      sorted: {},
      totalCount: null,
    };
    this.sort = '';
  }

  componentDidMount() {
    this.doGetArOpsUnit();
  }

  doGetArOpsUnit = () => {
    const { page, size, filter, totalCount } = this.state;
    const { getArOpsUnit } = this.props;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter: { ...filter, accountId: getAccountId() },
    };
    getArOpsUnit(params, ({ success, data }) => {
      this.setState({
        isSearching: false,
        page,
        size,
        filter,
        data: success ? data : [],
        isActiveNext: !!(data && data.length === size),
        totalCount: getPageTotalCount({ totalCount: totalCount || data?.length, size, items: data || [], page }),
      });
    });
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: { ...filteredData }, page: 0 }, () => this.doGetArOpsUnit());
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetArOpsUnit());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetArOpsUnit());
  };

  renderContent() {
    const { arOpsUnit, getArOpsUnitById, t } = this.props;
    const { totalCount, page, size } = this.state;

    return (
      <div className="transactions">
        <div className="transactions-form">
          <br />
          <Row>
            <Col md={12}>
              {/* <h4 className="card-title-mb">
                {`${t('label.aRActivity')}`}
                <span className="account-number">
                  <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                  &nbsp;&nbsp;
                  <span className="account-detail">{getAccountId()}</span>
                </span>
              </h4> */}
              <AccountHeaderForm title={t('label.aRActivity')} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12} className="p-0">
              <SearchFilterARActivity onHandleSearch={this.onHandleSearch} isSearching={this.state.isSearching} />
            </Col>
          </Row>
          <br />
          <ARActivity data={arOpsUnit} getArOpsUnitById={getArOpsUnitById} />
          <br />
          {/* <Pagination
            page={page}
            isActivePre={page !== 1}
            isActiveNext={this.state.isActiveNext}
            handlePage={this.handlePage}
          />
          <Sizing handleSize={this.handleSize} size={size} /> */}
          <ButtonCustom
            className="btn-cancel float-right mr-4"
            type="button"
            title={t('label.cancel')}
            datastyle={SLIDE_UP}
            onClick={() => {
              this.props.history.push('/');
            }}
          />
          <br />
          <br />
          <div className="mb-30">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }

  render() {
    const { permissionsSelfCare } = this.props;
    let modeViewTransactions = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewTransactions = checkPermissionViewTransactions({
        listPermission,
      });
    }
    return (
      <div className="view-transactions-page">
        <MainHeader activeTab="Billing Data" />
        {modeViewTransactions !== 0 && (
          <div className="view-transactions-page__form">
            <div className="form-wrapper table-content">{this.renderContent()}</div>
          </div>
        )}
      </div>
    );
  }
}

ViewARActivityPage.propTypes = {
  history: PropTypes.object,
  arOpsUnit: PropTypes.array,
  params: PropTypes.object,
  setArOpsUnitParams: PropTypes.func,
  getArOpsUnit: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  arOpsUnit: makeGetArOpsUnitUnit() || [],
  params: makePageArOpsUnitParams() || {},
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

const withConnect = connect(mapStateToProps, {
  getArOpsUnit,
  setArOpsUnitParams,
  getArOpsUnitById,
});

const withReducer = injectReducer({
  key: 'viewARActivityPageReducer',
  reducer,
});
const withSaga = injectSaga({ key: 'viewARActivityPageSaga', saga });

export default withTranslation('common')(compose(withReducer, withSaga, withConnect)(ViewARActivityPage));
