import { fromJS } from 'immutable';
import { cloneDeep, isEmpty } from 'lodash';
import * as types from './constants';

// The initial state of the App
const initialState = fromJS({
  loading: false,
  error: false,
  currentUser: false,
  userData: {
    repositories: false,
  },
  permissionsApp: {
    selfCareHubModules: [],
    opsHubModules: [],
  },
  s3Config: {},
  logo: null,
  currencyConfig: {},
  accountInfo: {},
  ccpProperty: {},
  listBroadsoftZeroDurationCdrst: [],
  listAllBroadsoftZeroDurationCdrst: [],
  listRawCdrData: [],
  listAllRawCdrData: [],
  listNonBroadsoft: [],
  listAllNonBroadsoft: [],
  listNonBroadsoftZeroDurationCdrs: [],
  listAllNonBroadsoftZeroDurationCdrs: [],
  listRawSmsData: [],
  listAllRawSmsData: [],
  listServiceReportData: [],
  listAllServiceReportData: [],
  listCustomers: [],
  userDetails: {},
  selfCareConfig: {},
  tenantDefault: null,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_REPOS:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['userData', 'repositories'], false);
    case types.LOAD_REPOS_SUCCESS:
      return state
        .setIn(['userData', 'repositories'], action.repos)
        .set('loading', false)
        .set('currentUser', action.username);
    case types.LOAD_REPOS_ERROR:
      return state.set('error', action.error).set('loading', false);

    case types.GET_PERMISSIONS_BY_ID_SUCCESS: {
      const newState = cloneDeep(state).toJS();
      const newPermissionsApp = newState.permissionsApp || {};
      if (isEmpty(newPermissionsApp.selfCareHubModules) && !isEmpty(action.payload.selfCareHubModules)) {
        newPermissionsApp.selfCareHubModules = action.payload.selfCareHubModules;
      }
      if (isEmpty(newPermissionsApp.opsHubModules) && !isEmpty(action.payload.opsHubModules)) {
        newPermissionsApp.opsHubModules = action.payload.opsHubModules;
      }
      return state.set('permissionsApp', newPermissionsApp);
    }

    case types.GET_S3_BUCKET_SUCESS:
      return state.set('s3Config', action.payload);

    case types.GET_LOGO_SUCCESS: {
      return state.set('logo', action.payload);
    }

    case types.GET_CURRENCY_CONFIG_SUCCESS:
      return state.set('currencyConfig', action.payload);

    case types.GET_ACCOUNT_BY_ID_SUCCESS:
      return state.set('accountInfo', action.payload);

    case types.GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS:
      return state.set('listBroadsoftZeroDurationCdrst', action.payload);

    case types.GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS:
      return state.set('listAllBroadsoftZeroDurationCdrst', action.payload);

    case types.GET_CCP_PROPERTIES_CONFIG_SUCCESS:
      return state.set('ccpProperty', action.payload);

    case types.GET_RAW_CDR_DATA_REPORT_SUCCESS:
      return state.set('listRawCdrData', action.payload);

    case types.GET_ALL_RAW_CDR_DATA_REPORT_DETAILS_SUCCESS:
      return state.set('listAllRawCdrData', action.payload);

    case types.GET_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS:
      return state.set('listNonBroadsoft', action.payload);

    case types.GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS:
      return state.set('listAllNonBroadsoft', action.payload);

    case types.GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS:
      return state.set('listNonBroadsoftZeroDurationCdrs', action.payload);

    case types.GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS:
      return state.set('listAllNonBroadsoftZeroDurationCdrs', action.payload);

    case types.GET_RAW_SMS_DATA_REPORT_SUCCESS:
      return state.set('listRawSmsData', action.payload);

    case types.GET_ALL_RAW_SMS_DATA_REPORT_DETAILS_SUCCESS:
      return state.set('listAllRawSmsData', action.payload);

    case types.GET_LIST_ON_DEMAND_SUCCESS:
      return state.set('listServiceReportData', action.payload);

    case types.GET_ALL_LIST_ON_DEMAND_SUCCESS:
      return state.set('listAllServiceReportData', action.payload);

    case types.SEARCH_ACCOUNTS_SUCCESS:
      return state.set('listCustomers', action.payload);

    case types.GET_TENANT_DEFAULT_CONFIG_SUCCESS:
      return state.set('tenantDefault', action.payload);

    case types.GET_PARENT_ACCOUNT_DETAIL_SUCCESS: {
      const newlistCustomers = state.get('listCustomers') || [];
      return state.set(
        'listCustomers',
        newlistCustomers.map(cus => {
          if (cus.parentId === action.payload.id) {
            return { ...cus, addresses: [...action.payload.addresses], contacts: [...action.payload.contacts] };
          }
          return cus;
        })
      );
    }

    case types.GET_ROLE_AND_ROLE_GROUP_BY_ID_SUCCESS: {
      return state.set('userDetails', action.payload);
    }

    case types.RESET_DATA: {
      return state.set('permissionsApp', {
        selfCareHubModules: [],
        opsHubModules: [],
      });
    }

    case types.GET_TENANT_SELF_CARE_CONFIG_SUCCESS:
      return state.set('selfCareConfig', action.payload);

    default:
      return state;
  }
}

export default appReducer;
