import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the manageProfilePage state domain
 */

const selectManageProfilePageDomain = state =>
  state.get('manageProfilePage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageProfilePage
 */

const makeSelectManageProfilePage = () =>
  createSelector(selectManageProfilePageDomain, substate => substate.toJS());

export default makeSelectManageProfilePage;
export { selectManageProfilePageDomain };
