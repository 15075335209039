import { getTypePermission } from '../../utils/utils';

const groupNamePermissions = {
  GET_BROADSOFT_ZERODURATION_CDRS_REPORT: 'GET_BROADSOFT_ZERODURATION_CDRS_REPORT',
  GET_SUSPENDED_FAILED_RECORDS_REPORT: 'GET_SUSPENDED_FAILED_RECORDS_REPORT',
  getRawCdrReport: 'GET_RAW_CDR_REPORT',
  mailReport: 'MAIL_REPORT_DATA',
  downloadReport: 'DOWNLOAD_REPORT_DATA',
  GET_NON_BROADSOFT_RAW_CDR_REPORT: 'GET_NON_BROADSOFT_RAW_CDR_REPORT',
  GET_NONBROADSOFT_ZERODURATION_CDRS_REPORT: 'GET_NONBROADSOFT_ZERODURATION_CDRS_REPORT',
  getRawSmsReport: 'GET_RAW_SMS_REPORT',
  getDataServiceReport: 'DATA_SERVICE_REPORT',
};

export const checkPermissionGetRawCdrReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRawCdrReport });
};

export const checkPermissionGetBroadsoftZeroDurationCdrs = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_BROADSOFT_ZERODURATION_CDRS_REPORT });
};

export const checkPermissionGetNonBroadsoftZeroDurationCdrs = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_NONBROADSOFT_ZERODURATION_CDRS_REPORT });
};

const checkPermissionDownloadReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.downloadReport });
};

const checkPermissionMailReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.mailReport });
};

const checkPermissionGetNonBroadsoftMapReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_NON_BROADSOFT_RAW_CDR_REPORT });
};

const checkPermissionGetRawSmsReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRawSmsReport });
};

const checkPermissionGetDataServiceReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getDataServiceReport });
};

export const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeGetRawCdrReport = 0;
  let modeGetBroadsoftZeroDurationCdrs = 0;
  let modeGetNonBroadsoftZeroDurationCdrs = 0;
  let modeDownloadReport = 0;
  let modeGetMailReport = 0;
  let modeGetNonBroadsoftMapReport = 0;
  let modeGetRawSmsReport = 0;
  let modeGetDataServiceReport = 0;

  modeDownloadReport = checkPermissionDownloadReport({ listPermission });
  modeGetMailReport = checkPermissionMailReport({ listPermission });
  modeGetRawCdrReport = checkPermissionGetRawCdrReport({ listPermission });
  modeGetBroadsoftZeroDurationCdrs = checkPermissionGetBroadsoftZeroDurationCdrs({ listPermission });
  modeGetNonBroadsoftZeroDurationCdrs = checkPermissionGetNonBroadsoftZeroDurationCdrs({ listPermission });
  modeGetNonBroadsoftMapReport = checkPermissionGetNonBroadsoftMapReport({ listPermission });
  modeGetRawSmsReport = checkPermissionGetRawSmsReport({ listPermission });
  modeGetDataServiceReport = checkPermissionGetDataServiceReport({ listPermission });

  return {
    modeGetRawCdrReport,
    modeGetNonBroadsoftZeroDurationCdrs,
    modeGetBroadsoftZeroDurationCdrs,
    modeDownloadReport,
    modeGetMailReport,
    modeGetNonBroadsoftMapReport,
    modeGetRawSmsReport,
    modeGetDataServiceReport,
  };
};
