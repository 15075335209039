/*
 *
 * ManageBalances actions
 *
 */

import * as types from './types';

// for get balance units
export function getBalanceUnit(payload) {
  return {
    type: types.GET_BALANCE_UNIT,
    payload,
  };
}

export function getBalanceUnitSuccess(payload) {
  return {
    type: types.GET_BALANCE_UNIT_SUCCESS,
    payload,
  };
}

export function getBalanceUnitFailure(payload) {
  return {
    type: types.GET_BALANCE_UNIT_FAILURE,
    payload,
  };
}
