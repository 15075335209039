import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { convertBodyPdfFile } from '../exportHelper';

const HeaderTable = ({ t, columnsTable }) => columnsTable.map(val => t(val.exportLabel) || t(val.label));

const convertJson2Pdf = ({ data, t, title, fileName, columnsTable, fontSize, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, columnsTable }));
  const newListData = data.map(item => {
    return convertBodyPdfFile({ item, columnsTable, t });
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: fontSize || 5, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
