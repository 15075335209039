import _ from 'lodash';
import Papa from 'papaparse';
import numeral from 'numeral';
import locations from '../constantsApp/locationsUS.json';
import { cardTokenFields } from '../constantsApp';
import { getRounding } from '../localStorage';

const csvUnparseConfig = {
  quotes: true, // or array of booleans
  quoteChar: '"',
  escapeChar: '"',
  delimiter: ',',
  header: true,
  newline: '\r\n',
  skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
  columns: null, // or array of strings
};

/**
 * convertFilterRequestUrl
 * @param {*} obj : filter object input
 * @param {*} arrNS : list field name will parse not string
 */
export const convertFilterRequestUrl = (obj, arrNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (arrNS.includes(key)) {
      return `${key}: ${value}`;
    }
    return `${key}: "${value}"`;
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};

const filter = _.partial(_.filter, locations);

export function getCities(state) {
  const resultFilter = filter({ state_abbr: state.toUpperCase() });
  return _.uniqBy(resultFilter, 'city');
}

export function getZips(city, state) {
  return filter({ city, state_abbr: state.toUpperCase() });
}

export const handleError = (err, msgCandidate) => {
  if (typeof err === 'string') return err;

  return msgCandidate;
};

const parseObjToString = (value, key, arrNS) => {
  if (arrNS.includes(key)) {
    return `${key}: ${value}`;
  }
  if (!value) {
    return null;
  }
  return `${key}: "${value}"`;
};

const parseArrToString = (value, key, arrNS) => {
  const arr = _.map(value, itemValue => {
    const arrItem = _.map(itemValue, (childValue, childKey) => {
      if (_.isPlainObject(childValue)) {
        return parsePlainObject(childValue, childKey, arrNS);
      }
      if (_.isArray(childValue)) {
        return parseArrToString(childValue, childKey, arrNS);
      }
      return parseObjToString(childValue, childKey, arrNS);
    });
    return `{${arrItem.filter(el => !!el).join(', ')}}`;
  });
  return `${key}: [${arr.filter(el => !!el).join(', ')}]`;
};

const parsePlainObject = (value, key, arrNS) => {
  const arr = _.map(value, (valueChild, keyChild) => {
    if (!value) return null;
    if (_.isPlainObject(valueChild)) {
      return parsePlainObject(valueChild, keyChild, arrNS);
    }
    if (_.isArray(value)) {
      return parseArrToString(valueChild, keyChild, arrNS);
    }
    return parseObjToString(valueChild, keyChild, arrNS);
  });
  const strResult = `${arr.filter(el => !!el).join(', ')}`;
  return `${key}: { ${strResult}}`;
};

/**
 * parseToMutationRequest: used for create or modify
 * @param {*} obj : object input
 * @param {*} arrNS : list field name will parse not string
 */
export const parseToMutationRequest = (obj, arrNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (!value) return null;
    if (_.isPlainObject(value)) {
      return parsePlainObject(value, key, arrNS);
    }
    if (_.isArray(value)) {
      return parseArrToString(value, key, arrNS);
    }
    return parseObjToString(value, key, arrNS);
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};

export const convertString2Obj = url => {
  const index = url.lastIndexOf('id=');
  const arrData = index > 0 ? url.slice(index, url.length).split('&') : '';

  return arrData || '';
};

// export const getDataCardFromURL = (url, key) => {
//   const arrDataFromURL = convertString2Obj(url) || [];
//   let token = '';
//   arrDataFromURL.map(item => {
//     if (item.indexOf(key) > -1) {
//       const string2Rex = new RegExp(key, 'g');
//       token = item.replace(string2Rex, '');
//     }
//     return token;
//   });
//   return token;
// };
export const getDataCardFromURL = (url, key) => {
  const arrDataFromURL = convertString2Obj(url) || [];
  let token = '';
  arrDataFromURL.map(item => {
    if (item.indexOf(key) > -1) {
      const string2Rex = new RegExp(key, 'g');
      if (key === cardTokenFields.expiryDate) {
        token = decodeURIComponent(item.replace(string2Rex, ''));
        const month = token.slice(0, 2);
        token = token.slice(3, token.length);
        token = `${month}/${token}`;
      } else {
        token = item.replace(string2Rex, '');
      }
    }
    return token;
  });
  return token;
};

export const getAddressesForm = ({ country = '', state = ' ', city = '', addresses = {} }) => {
  let addressesField = addresses.addressesNormalForm;
  if (country === 'USA') {
    addressesField = addresses.addressesUSForm;
    _.map(addressesField, (item, index) => {
      if (state && item.name === 'city') {
        addressesField[index].options = getCities(state).map(itemCity => ({
          label: itemCity.city,
          value: itemCity.city,
        }));
      }
      if (state && city && item.name === 'postalCode') {
        addressesField[index].options = getZips(city, state).map(itemZip => ({
          label: itemZip.zipcode,
          value: itemZip.zipcode,
        }));
      }
    });
  }

  return addressesField;
};

export const getPageTotalCount = ({ totalCount, size, items, page }) => {
  if (!totalCount) return size;
  if (!items || items.length < size) return size * (page + 1);
  return size * (page + 1) + 1;
};

export const convertCurrency2Option = currencies => {
  return currencies.map(item => ({
    label: `${item.name} (${item.code})`,
    value: item.code,
  }));
};

export const getBase64 = (url, cb) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => cb(reader.result));
  reader.readAsDataURL(url);
};

export const splitF = pth => {
  let fName = 'file_download';
  if (pth && typeof pth === 'string') {
    fName = pth.substring(pth.lastIndexOf('/') + 1, pth.length);
  }

  return fName;
};

export const getLabelOptions = ({ t, item, fieldName, fieldOption }) => {
  try {
    const slt = t ? t(`selections:${fieldOption || fieldName}`)().find(val => val.value === item[fieldName]) : '';
    return slt ? slt.label : '';
  } catch (error) {
    console.log(error);
  }
};

const parseObjToStringPostMethod = (value, key, arrNS) => {
  if (arrNS.includes(key)) {
    return `${key}: ${value || value === 0 ? value : null}`;
  }
  if (value === null) {
    if (key === 'grants' || key === 'phones') {
      return '';
    }
    return null;
  }
  return `${key}: \\"${value || ''}\\"`;
};

export const convertArrEnumtoStringPostMethod = (value, key) => {
  const arr = _.map(value, itemValue => `${itemValue}`);
  return `${key}:[${arr.filter(el => !!el).join(', ')}]`;
};

const parseArrToStringPostMethod = (value, key, arrNS) => {
  const arr = _.map(value, itemValue => {
    if (!_.isArray(itemValue) && itemValue.length) {
      const temp = [];
      for (const key in itemValue) {
        if (key === 'roles') {
          temp.push(convertArrEnumtoStringPostMethod(itemValue[key], key));
        }
        if (_.isArray(itemValue[key])) {
          temp.push(parseArrToStringPostMethod(itemValue[key], key, arrNS));
        } else {
          temp.push(parseObjToStringPostMethod(itemValue[key], key, arrNS));
        }
      }
      return `{${temp.filter(el => !!el).join(', ')}}`;
    }
    const arrItem = _.map(itemValue, (childValue, childKey) => {
      if (childKey === 'roles') {
        return convertArrEnumtoStringPostMethod(childValue, childKey);
      }

      if (_.isArray(childValue)) {
        return parseArrToStringPostMethod(childValue, childKey, arrNS);
      }
      return parseObjToStringPostMethod(childValue, childKey, arrNS);
    });
    return `{${arrItem.filter(el => !!el).join(', ')}}`;
  });
  return `${key}: [${arr.filter(el => !!el).join(', ')}]`;
};

export const parseToMutationRequestPostMethod = (obj, arrNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (_.isArray(value)) {
      return parseArrToStringPostMethod(value, key, arrNS);
    }
    if (_.isObject(value)) {
      return `${key}: ${parseToMutationRequestPostMethod(value, arrNS)}`;
    }
    return parseObjToStringPostMethod(value, key, arrNS);
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};

export const blobToFile = (theBlob, fileName) => {
  const fileOfBlob = new File([theBlob], fileName, { type: theBlob.type, lastModified: theBlob.lastModified });
  return fileOfBlob;
};

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const blobToCSVFile = ({ fileName, data, columns, t, listOptionFields }) => {
  const newData = [];
  _.map(data, value => {
    const newVal = _.cloneDeep(value);
    const newValConvert = {};
    columns.forEach(cln => {
      newVal[cln.name] = value[cln.name] || value[cln.name] === 0 ? value[cln.name] : '';
      if (listOptionFields && listOptionFields[cln.name] && value[cln.name]) {
        const optionsList = t(`selections:${listOptionFields[cln.name]}`)();
        const itemSelect = optionsList.find(op => op.value === value[cln.name]);
        newVal[cln.name] = itemSelect?.label ? itemSelect.label : '';
      }
      if (typeof value[cln.name] === 'boolean') {
        newVal[cln.name] = value[cln.name] ? 'TRUE' : 'FALSE';
      }
    });
    _.map(newVal, (vl, key) => {
      const itemSelect = columns.find(cl => cl.name === key);
      if (itemSelect && itemSelect.label) {
        newValConvert[t(itemSelect.label)] = vl === 0 ? '0' : vl;
      } else {
        newValConvert[key] = vl === 0 ? '0' : vl;
      }
    });
    newData.push(newValConvert);
  });

  const dataString = Papa.unparse(
    { fields: columns.map(val => t(val.label)), data: newData },
    {
      ...csvUnparseConfig,
    }
  );
  const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
  const fileOfBlob = new File([blob], fileName, { type: blob.type, lastModified: blob.lastModified });
  return fileOfBlob;
};

export const formatMoneyValueToExport = value => {
  if (value === null || (!value && value !== 0)) return value;
  if (typeof value !== 'number' || Number.isNaN(value)) return value;
  return Number.parseFloat(value);
};

export const formatMoneyValue = (value, isNoNeedPrefix = false) => {
  if (value === null) return value;
  if (typeof value !== 'number' || Number.isNaN(value)) return '0.00';
  if (isNoNeedPrefix) {
    return numeral(value || '0').format('0,0');
  }
  if (value % 1 === 0) {
    return numeral(value || '0').format('0,0');
  }
  return numeral(value || '0').format(
    `0,0[.][${_.padStart('', Number.isNaN(getRounding()) ? 2 : getRounding(), '0')}]`
  );
};

export const formatNumberValue = value => {
  if (value === null) return value;
  if (typeof value !== 'number') return value;
  if (value % 1 === 0) {
    return numeral(value || '0').format('0,0');
  }
  return numeral(value || '0').format(
    `0,0[.][${_.padStart('', Number.isNaN(getRounding()) ? 2 : getRounding(), '0')}]`
  );
};

export const redFontExcelFile = { font: { color: { rgb: 'FFFF0000' } } };
export const redFontPdfFile = { textColor: '#D00000' };
export const totalFontPdfFile = { fillColor: '#00A651', textColor: '#FFFFFF', fontStyle: 'bold' };

export const typePermissons = {
  readOnly: 'READ_ONLY',
  readWrite: 'READ_WRITE',
  none: 'NONE',
};

export const getTypePermission = ({ listPermission, type }) => {
  if (listPermission.length) {
    const itemCheck = listPermission.filter(item => item.permission === type)[0];
    if (itemCheck && itemCheck.type === typePermissons.readOnly) return 1;
    if (itemCheck && itemCheck.type === typePermissons.readWrite) return 2;
  }
  return 0;
};

export const compareListFields = ({ columns, responseFields, t }) => {
  return responseFields.map(val => {
    const itemSelect = columns.find(cl => cl.name === val.name);
    if (itemSelect) {
      return itemSelect;
    }
    return {
      label: t && t(`label.${[val.name]}`) !== `label.${val.name}` ? t(`label.${[val.name]}`) : val.name,
      name: val.name,
    };
  });
};

export const configOutputTemplateByType = ({ columns, data, t }) => {
  // const sourceData = data.source || [];
  // const targetData = data.target || [];
  // const allOfFields = [...sourceData, ...targetData];
  // const noColumnsAdded =
  //   columns && columns.length
  //     ? columns.filter(val => allOfFields.findIndex(field => field.name === val.name) === -1)
  //     : [];
  const exportColumns = {
    sourceFields: {
      name: 'label.sourceFields',
      items: compareListFields({
        columns,
        responseFields: data.source ? [...data.source] : [],
        t,
      }),
    },
    targetFields: {
      name: 'label.targetFields',
      items: compareListFields({ columns, responseFields: data.target || [], t }),
    },
  };
  return exportColumns;
};
