import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { MainHeader } from '../../components/Header';
import { DataTable, Pagination, Sizing } from '../../components/common';
import OrdersSearchForm from '../../components/Orders/OrdersSearchForm';
import { getAccountId } from '../../localStorage';
import { checkPermissionViewSubscription } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare } from '../App/selectors';
import { searchOrders } from '../App/actions';
import './styles.scss';

const orderSort = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  userId: {
    asc: 'userId_ASC',
    desc: 'userId_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
};

const tableColumns = [
  {
    name: 'id',
    label: 'common:label.id',
    sortable: true,
    // render: (colName, item) => (
    //   <Link className="text-success" to={RouteNames.prettifyPath(RouteNames.orderDetail.path, item.id)}>
    //     {item.id}
    //   </Link>
    // ),
  },
  {
    name: 'type',
    label: 'common:label.type',
    sortable: true,
    style: { minWidth: '90px' },
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:types')().find(val => val.value === item.type) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountId',
    label: 'common:label.accountId',
  },
  {
    name: 'userId',
    label: 'common:label.userId',
    sortable: true,
  },
  {
    name: 'status',
    label: 'common:label.status',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },

  {
    name: 'effectiveDate',
    label: 'common:label.effectiveDate',
    sortable: true,
    style: { minWidth: '120px' },
  },
  {
    name: 'submittedDate',
    label: 'common:label.submittedDate',
  },
];

export class Orders extends React.Component {
  state = {
    page: 1,
    size: 20,
    filter: {},
    sorted: {},
    totalCount: null,
    isSearching: false,
    isActiveNext: false,
  };

  componentDidMount = () => {
    this.doSearchOffers();
  };

  doSearchOffers = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchOrders } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && orderSort[sorted.sortCol]
        ? orderSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page,
      size,
      filter: { ...filter, accountId: getAccountId() },
    };
    if (sort) {
      params.sort = sort;
    }
    searchOrders(params, ({ success, data }) => {
      this.setState({
        isSearching: false,
        page,
        size,
        filter,
        sort,
        data: success ? data : [],
        isActiveNext: !!(data && data.length === size),
      });
    });
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: { ...filteredData }, page: 1 }, () => this.doSearchOffers());
  };

  onPageChange = i => {
    const { page } = this.state;
    this.setState({ page: page + i }, () => this.doSearchOffers());
  };

  onSizeChange = size => {
    this.setState({ size, page: 1 }, () => this.doSearchOffers());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchOffers());
  };

  render() {
    const { permissionsSelfCare, t } = this.props;
    const { billingData, data, page, isActiveNext, size, sorted } = this.state;
    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="ManageServices" />
        <div className="manage-page__form p-3">
          <div className="form-billing">
            <div className="col-md-12 row pt-4 group-input-billing" onSubmit={this.onSubmit}>
              <OrdersSearchForm onSubmit={this.onSubmitSearch} />
            </div>
            <div className="group-input-billing">
              <DataTable columns={tableColumns} data={data || []} onSort={this.onSortColumn} sorted={sorted} />
              <div className="col-md-12 pl-0 pr-0 pt-3">
                <Pagination
                  page={page}
                  isActivePre={page !== 1}
                  isActiveNext={isActiveNext}
                  handlePage={this.onPageChange}
                />
                <Sizing handleSize={this.onSizeChange} size={size} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Orders.propTypes = {
  searchOrders: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    searchOrders: (data, cb) => dispatch(searchOrders(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common'])(compose(withConnect)(Orders));
