import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../utils/utils';

export default ({ accountId, filter, page, size }) => {
  // const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['folioStatus'])}`
    : '';
  const getAccountStatement = `getAccountStatement(page: ${page}, size: ${size}, accountId: "${accountId}",${filterStr})`;
  return gql`
    query {
      ${getAccountStatement} {
        lineReferenceId
        accountId
        organization
        sellingcompany
        accountType
        accountSubType
        marketSegment
        salesChannel
        lineType
        openingBalance
        amount	
        closingBalance
        lineDate	
        invoiceId
        invoiceDate
        dueDate
        subTotal
        taxes
        status
        folioStatus
      }
    }
  `;
};
