export const HANDLE_CHANGE_PASSWORD = 'HANDLE_CHANGE_PASSWORD';
export const GET_ACCOUNT_INFORMATION = 'GET_ACCOUNT_INFORMATION';
export const GET_ACCOUNT_INFORMATION_SUCCESS = 'GET_ACCOUNT_INFORMATION_SUCCESS';
export default {
  fieldsInfoCard: [
    {
      label: 'label.currentBalance',
      name: 'currentBalance',
      bgColor: '#3ec1d3',
    },
    {
      label: 'label.currentBillTotal',
      name: 'lastBillTotal',
      bgColor: '#669900',
    },
    {
      label: 'label.lastInvoiceDate',
      name: 'invoiceDate',
      bgColor: '#2d4059',
    },
    {
      label: 'label.lastInvoiceTotal',
      name: 'invoiceTotal',
      bgColor: '#43ab92',
    },
    {
      label: 'label.lastInvoiceDueDate',
      name: 'invoiceDueDate',
      bgColor: '#31a1b0',
    },
    {
      label: 'label.lastInvoiceDue',
      name: 'invoiceDue',
      bgColor: '#456308',
    },
    {
      label: 'label.lastPaymentDate',
      name: 'lastPaymentDate',
      bgColor: '#182330',
    },
    {
      label: 'label.lastPaymentAmount',
      name: 'lastPaymentAmount',
      bgColor: '#009999',
    },
    {
      label: 'label.crARSinceLastInvoice',
      name: 'totalCreditAr',
      bgColor: '#30806d',
    },
    {
      label: 'label.drARSinceLastInvoice',
      name: 'totalDebitAr',
      bgColor: '#006600',
    },
  ],
};
