/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import { getAddressesForm } from '../../utils/utils';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import fieldConstant from '../../containers/RegisterNew/constants';
import RequirIcon from '../common/RequirIcon';

const AccountDetails = ({
  data,
  onChange,
  optionCity,
  onFocusSelectCity,
  onBlurSelectCity,
  listValidateStage0,
}) => {
  const {
    selfCareAddress,
    selfCareAddress: { country, state, city },
  } = data;
  const { t } = useTranslation('common');
  return (
    <div className="col-md-12 d-flex flex-wrap no-padding">
      {getAddressesForm({
        country,
        state,
        city,
        addresses: fieldConstant.accountDetailForm,
      }).map(item => {
        if (item.isSelect) {
          let { options } = item;
          if (item.name === 'city') {
            options = optionCity;
          }

          if (options.find(val => val.isMultiLang)) {
            options = options.map(op => {
              const { label, ...rest } = op;
              return { ...rest, label: t(label) };
            });
          }

          return (
            <Col md={6} key={item.name}>
              <FormGroup>
                <Label for="country">
                  {t(item.label)}
                  {item.required && <RequirIcon />}:
                </Label>
                <Select
                  value={
                    item.options
                      ? item.options.find(
                          e => e.value === selfCareAddress[item.name],
                        )
                      : null
                  }
                  options={options}
                  // className="form__form-group-select"
                  className={
                    listValidateStage0.indexOf(item.name) > -1
                      ? 'form__form-group-select isValid'
                      : 'form__form-group-select'
                  }
                  inputProps={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'off',
                  }}
                  onChange={evt =>
                    onChange({
                      name: item.name,
                      value: evt.value,
                      subFieldName: item.subField ? item.subField : '',
                    })
                  }
                  onFocus={onFocusSelectCity}
                  onBlur={onBlurSelectCity}
                />
              </FormGroup>
            </Col>
          );
        }

        return (
          <Col md={6} key={item.name}>
            <FormGroup>
              <Label for={item.name}>
                {t(item.label)}
                {item.required && <RequirIcon />}:
              </Label>
              <Input
                type={item.isPassword ? 'password' : 'text'}
                className={
                  listValidateStage0.indexOf(item.name) > -1 ? 'isValid' : ''
                }
                name={item.name}
                id={item.name}
                value={
                  (item.subField && data[item.subField]
                    ? data[item.subField][item.name]
                    : data[item.name]) || ''
                }
                onChange={evt =>
                  onChange({
                    name: evt.target.name,
                    value: evt.target.value,
                    subFieldName: item.subField ? item.subField : '',
                  })
                }
              />
            </FormGroup>
          </Col>
        );
      })}
    </div>
  );
};

AccountDetails.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default AccountDetails;
