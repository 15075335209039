/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getAccountId } from '../../../localStorage';
import { selectInvoiceId } from '../../../containers/App/actions';
import ModalSelector from '../ModalSelector';
import InvoiceSearchForm from './InvoiceSearchForm';

class ModalInvoice extends PureComponent {
  searchHanldeInvoiceId = (payload, cb) => {
    const { selectInvoiceId } = this.props;
    const newPayload = { ...payload };
    if (newPayload.filter) {
      newPayload.filter.accountId = getAccountId() || '';
      newPayload.filter.due = 0;
    }
    selectInvoiceId(newPayload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'type',
          label: 'label.type',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:invoiceUnitType')().find(val => val.value === item.type) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'status',
          label: 'label.status',
          tOptions: 'selections:invoiceStatus',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:invoiceStatus')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'invoiceType',
          label: 'label.invoiceType',
          tOptions: 'selections:invoiceType',
          type: 'select',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:invoiceType')().find(val => val.value === item.invoiceType) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'invoiceDate',
          label: 'label.invoiceDate',
        },
        {
          name: 'dueDate',
          // label: 'Due Date',
          label: 'label.dueDate',
        },
        {
          name: 'due',
          label: 'label.due',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.selectInvoice')}
        isOpen={isOpen}
        SearchForm={InvoiceSearchForm}
        searchItems={this.searchHanldeInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
      />
    );
  }
}

ModalInvoice.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectInvoiceId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalInvoice.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectInvoiceId })(ModalInvoice));
