import React, { Component } from 'react';
import './styles.scss';
import logodark from '../../assets/images/logo-dark.png';
import planning from '../../assets/images/planning.png';
class CredidCardDetails extends Component {
  constructor() {
    super();
    this.state = {};
  }
render() {
  return (
  
<div className="container-fluid">
    <div className="row">
        <div className="col-md-12 logoimg">
                  <img src={logodark} className="reglogo" /><br /><br />
            <hr width="50%"/>
            <ul className="list-inline ulcircle">
                <li className="list-inline-item "><div className="circle ">1</div></li>
                <li className="list-inline-item"><div className="circle active">2</div> </li>
                <li className="list-inline-item"><div className="circle">3</div></li>
                <li className="list-inline-item"><div className="circle">4</div></li></ul>
        <ul className="list-inline uldetails">
                      <li className="list-inline-item" style={{ marginleft: '-15px' }}>Account Details</li>
                      <li className="list-inline-item" style={{ marginleft: '20px' }}>Card Details</li>
            <li className="list-inline-item">&nbsp;&nbsp;&nbsp;Select Package</li>
            <li className="list-inline-item">Create Your Package</li>
        </ul>
            </div>
    </div>

<div className="container">
    
    <div className="row row-margin-bottom">
        <div className="col-md-12 no-padding lib-item" data-category="view">
            <div className="lib-panel">
                <div className="row box-shadow">
                    <div className="col-md-3 bgcc align-items-center justify-content-center">
                                  <center><img className="lib-img-show" src={planning} /></center>    
                        <h2>Credit Card Details</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                        book.</p>
                    </div>
                    
                    <div className="col-md-9">
                        <div className="lib-row lib-header "><br/>
                                      <form role="form">
                            <div className="row">
                      
                            <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                                <label for="exampleInputEmail1">Credit Card Token</label>
                                <input type="email" className="form-control" id=""/>
                            </div>
                            <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                                <label for="exampleInputEmail1">Credid Card CVV</label>
                                <input type="text" className="form-control" id="" />
                            </div>
                         </div>
                                          <div className="row">
                            <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                                <label for="exampleInputPassword1">Credit Card Expiry</label>
                                <input type="password" className="form-control" id="" placeholder=""/>
                            </div>
                          
                        <div className="form-group col-xs-10 col-sm-4 col-md-6 col-lg-6">
                            <ul className="list-inline">
                            
                                <li className="list-inline-item"><button className="btn ccardbtn">Add Credit Card</button></li>
                            </ul>
                        </div>
                                          </div>
                            </form>
                                     
                        </div>
                        <div className="clearfix"></div>
                        
                        <ul className="list-inline textctrcc">
                            <li className="list-inline-item"><a href="AccountDetails"><button className="btn custbtn">Previous</button></a></li>
                            <li className="list-inline-item"><a href="SelectPackage"><button className="btn custbtn">Next</button></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-1"></div>
        
    </div>
</div>


   
</div>
  );
}
}



export default CredidCardDetails;
