import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { isEmpty, cloneDeep } from 'lodash';
import { SLIDE_UP } from 'react-ladda';
import { MainHeader } from '../../components/Header';
import OnDemandSearchForm from './OnDemandSearchForm';
import { makeGetAccountInfo, makeGetListServiceReportData, makeGetListAllServiceReportData } from '../App/selectors';
import {
  TablePagination,
  DataTable,
  SwitchExport,
  ButtonExport,
  ExcelExport,
  MultiInputGroup,
  AccountHeaderForm,
  ModalWithItem,
  ButtonCustom,
} from '../../components/common';
import { checkPermissionViewTransactions } from '../../utils/CheckPermissions';
import {
  uploadMultiPartFiles,
  getDataServiceReportData,
  getListAllDataServiceReportData,
  onResetDefaultData,
  getOutputTemplateByType,
  getDataServiceReportDetails,
  getAllDataServiceReportDetails,
} from '../App/actions';
import './styles.scss';
import {
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  getPageTotalCount,
  configOutputTemplateByType,
} from '../../utils/utils';
import convertJson2Sheet from '../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../utils/PdfHelper/exportPdfFile';

const sortOnDemand = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  ipAddress: {
    asc: 'iPAddress_ASC',
    desc: 'iPAddress_DESC',
  },
  quantity: {
    asc: 'quantity_ASC',
    desc: 'quantity_DESC',
  },
};

const tabelColumnExportOnDemandDetails = [
  {
    name: 'provisioningId',
    label: 'label.ipAddress',
  },
  {
    name: 'recordDate',
    label: 'label.recordDate',
  },
  {
    name: 'batchId',
    label: 'label.fileName',
  },
  {
    name: 'uploadVolume',
    label: 'label.uploadVolume',
  },
  {
    name: 'downloadVolume',
    label: 'label.downloadVolume',
  },
];

const tableColumns = [
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'ipAddress',
    label: 'label.ipAddress',
    sortable: true,
  },
  {
    name: 'volumeUpload',
    label: 'label.volumeUpload',
  },
  {
    name: 'volumeDownload',
    label: 'label.volumeDownload',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'recordCount',
    label: 'label.recordCount',
  },
];

let tableColumnsOutput = null;
let fileNameExport = '';

export class OnDemand extends React.PureComponent {
  buttonRef = React.createRef();

  buttonDetailsRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActivePdf: false,
      page: 0,
      size: 20,
      filter: {},
      sort: '',
      sorted: {},
      totalCount: null,
      isSearching: false,
      listOnDemand: [],
      listDataServiceReportDetails: [],
      listAllDataServiceReportDetails: [],
      isOpenModalDetails: false,
      isActivePdfDetails: false,
      itemSelect: null,
      pageDetails: 0,
      sizeDetails: 20,
      totalCountDetails: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      listOnDemand: props.listOnDemand,
      totalCount: getPageTotalCount({ ...state, items: props.listOnDemand }),
    };
  }

  componentDidMount() {
    const { permissions } = this.props;
    const { modeGetDataServiceReport } = permissions || {};
    if (modeGetDataServiceReport) {
      this.doGetOutputTemplateByType();
      onResetDefaultData('listOnDemand');
    }
  }

  onHandleSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListOnDemand();
    });
  };

  doGetListOnDemand = () => {
    const { filter, page, size, sorted } = this.state;
    const { getDataServiceReportData, accountInfo } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.startDate) {
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.endDate) {
      newFilter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    // if (!newFilter.switchName) {
    //   newFilter.switchName = 'SMS';
    // }
    if (accountInfo?.organization) {
      newFilter.organization = accountInfo?.organization;
    }
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) && sortOnDemand[sorted.sortCol] ? sortOnDemand[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    getDataServiceReportData(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistOnDemand = cb => {
    const { filter, sorted } = this.state;
    const { getListAllDataServiceReportData, accountInfo } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.startDate) {
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.endDate) {
      newFilter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    // if (!newFilter.switchName) {
    //   newFilter.switchName = 'SMS';
    // }
    if (accountInfo?.organization) {
      newFilter.organization = accountInfo?.organization;
    }
    const payload = {
      page: 1,
      size: 10000000,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortOnDemand[sorted.sortCol][sorted.sortDir] : null,
    };
    getListAllDataServiceReportData(payload, () => {
      if (cb) cb();
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListOnDemand();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListOnDemand());
  };

  onPageChangeDetails = pageChange => {
    const { pageDetails } = this.state;
    if (pageDetails === pageChange) return '';
    return this.setState({ pageDetails: pageChange }, () => this.doGetDataServiceReportDetails());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListOnDemand());
  };

  onSizeChangeDetails = size => {
    this.setState({ sizeDetails: size, pageDetails: 0 }, () => this.doGetDataServiceReportDetails());
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onChangeSwitchDetails = () => {
    const { isActivePdfDetails } = this.state;
    this.setState({ isActivePdfDetails: !isActivePdfDetails });
  };

  onSizeChangeDetails = size => {
    this.setState({ sizeDetails: size, pageDetails: 0 }, () => this.doGetDataServiceReportDetails());
  };

  onExport = () => {
    this.doGetAlllistOnDemand(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllOnDemand, t } = this.props;
        convertJson2Pdf({
          data: listAllOnDemand,
          t,
          title: t('label.onDemand').toLocaleUpperCase(),
          fileName: `${t('label.onDemand')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = () => {
    this.doGetAlllistOnDemand(() => {
      const { listAllOnDemand, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'DATA_SERVICE_REPORT',
          subject: `Embrix Report ${t('label.onDemand')}`,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('label.onDemand')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllOnDemand,
            t,
            columns: tableColumnsOutput,
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllOnDemand,
          t,
          title: t('label.onDemand').toLocaleUpperCase(),
          fileName: `${t('label.onDemand')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('label.onDemand')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('DATA_CUSTOMER_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumns,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  doGetDataServiceReportDetails = () => {
    const { pageDetails, sizeDetails, itemSelect, totalCountDetails } = this.state;
    const { getDataServiceReportDetails } = this.props;
    const payload = {
      page: pageDetails + 1,
      size: sizeDetails,
      filter: {
        provisioningId: itemSelect?.ipAddress,
        recordDate: itemSelect?.lineDate,
      },
    };
    this.setState({ isSearching: true });
    getDataServiceReportDetails(payload, ({ success, data }) => {
      this.setState({
        listDataServiceReportDetails: success ? data : [],
        isSearching: false,
        totalCountDetails: getPageTotalCount({
          page: pageDetails,
          size: sizeDetails,
          totalCount: totalCountDetails || data.length,
          items: data,
        }),
      });
    });
  };

  doGetAlllistOnDemandDetails = cb => {
    const { itemSelect } = this.state;
    const { getAllDataServiceReportDetails } = this.props;
    const payload = {
      page: 1,
      size: 10000000,
      filter: {
        provisioningId: itemSelect?.ipAddress,
        recordDate: itemSelect?.lineDate,
      },
    };
    getAllDataServiceReportDetails(payload, ({ success, data }) => {
      this.setState({ listAllDataServiceReportDetails: success ? data : [], isSearching: false }, () => {
        if (cb) cb();
      });
    });
  };

  onToggleModalDetails = item => {
    const { isOpenModalDetails } = this.state;
    if (!isOpenModalDetails) {
      this.setState(
        {
          isOpenModalDetails: !isOpenModalDetails,
          itemSelect: item,
          totalCountDetails: null,
          pageDetails: 0,
          sizeDetails: 20,
          listDataServiceReportDetails: [],
          listAllDataServiceReportDetails: [],
          isActivePdfDetails: false,
        },
        () => {
          this.doGetDataServiceReportDetails();
          fileNameExport = item?.ipAddress || '';
        }
      );
    } else this.setState({ isOpenModalDetails: !isOpenModalDetails, itemSelect: null });
  };

  onExportDetails = () => {
    this.doGetAlllistOnDemandDetails(() => {
      const { isActivePdfDetails, listAllDataServiceReportDetails } = this.state;
      if (isActivePdfDetails) {
        const { t } = this.props;
        convertJson2Pdf({
          data: listAllDataServiceReportDetails,
          t,
          title: fileNameExport?.toLocaleUpperCase(),
          fileName: `${fileNameExport?.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
            'YYYY_MM_DD'
          )}`,
          columnsTable: tabelColumnExportOnDemandDetails,
          fontSize: 7,
        });
      } else {
        this.buttonDetailsRef.current.click();
      }
    });
  };

  renderContent() {
    const { t, listAllOnDemand, permissions } = this.props;
    const {
      listOnDemand,
      page,
      size,
      isActivePdf,
      emails,
      totalCount,
      sorted,
      isSearching,
      exportColumnsSave,
      isOpenModalDetails,
      isActivePdfDetails,
      listAllDataServiceReportDetails,
      listDataServiceReportDetails,
      pageDetails,
      sizeDetails,
      totalCountDetails,
    } = this.state;
    const { modeGetDataServiceReport, modeDownloadReport, modeGetMailReport } = permissions || {};

    if (!modeGetDataServiceReport) return '';
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];

    const newTableColumns = [
      ...tableColumnsOutput,
      {
        name: 'details',
        label: 'common:label.details',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={evt => {
              this.onToggleModalDetails(item);
            }}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];
    return (
      <div className="transactions">
        <div className="col-md-12 p-0">
          <br />
          <Row>
            <Col md={12}>
              <AccountHeaderForm title={t('label.onDemand')} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12} className="p-0">
              <OnDemandSearchForm onSubmit={this.onHandleSearch} isSearching={this.state.isSearching} />
            </Col>
            <Col md={12} className="p-0">
              <div className="mt-3 row float-right mb-4 pt-3 pb-3">
                {!!modeDownloadReport && (
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                    />
                  </div>
                )}
                {!!modeDownloadReport && (
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet={t('label.onDemand').toLocaleUpperCase()}
                      multiDataSet={convertJson2Sheet({
                        data: listAllOnDemand,
                        t,
                        title: t('label.onDemand').toLocaleUpperCase(),
                        columnsTable: tableColumnsOutput,
                      })}
                      fileName={`${t('label.onDemand')
                        .toLocaleLowerCase()
                        .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                    />
                  </>
                )}
                {!!modeGetMailReport && (
                  <div className="email-group">
                    <MultiInputGroup
                      label={t('label.emails')}
                      wrapperClass="email-form"
                      value={emails || []}
                      inputProps={{
                        placeholder: t('label.addAEmail'),
                      }}
                      onChange={this.onChangeEmail}
                    />
                    <button
                      type="submit"
                      onClick={() => this.sendEmail({ columns: tableColumnsOutput })}
                      disabled={!emails || !emails.length}
                      className="ladda-button btn btn-submit x-small mr-3 mt-0 float-right btn-default-height"
                    >
                      {t('label.email')}
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <br />
          <DataTable
            isFixedHeaderTable
            tableClass="table table-hover"
            columns={newTableColumns}
            data={listOnDemand && listOnDemand.length ? listOnDemand : [{}]}
            onSort={this.onSortColumn}
            sorted={sorted}
            isLoading={isSearching}
          />
          <br />
          <br />
          <div className="mb-30">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
        </div>
        <br />
        <br />
        <ModalWithItem
          onToggle={this.onToggleModalDetails}
          modalTitle={fileNameExport?.toLocaleUpperCase()}
          isOpen={isOpenModalDetails}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isSupportTopBackButton
        >
          <div className="d-flex float-right pb-3">
            <div className="ml-auto mt-2 mb-auto mr-3">
              <SwitchExport
                onChange={this.onChangeSwitchDetails}
                wrapperClass={isActivePdfDetails ? 'switch-active' : 'switch-non-active'}
                title={t('label.excel')}
                checked={isActivePdfDetails}
                rightTitle={t('label.pdf')}
                name="isActivePdfDetails"
              />
            </div>
            <>
              <ButtonExport
                onExport={() => this.onExportDetails({ colums: tabelColumnExportOnDemandDetails, fileNameExport })}
              />
              <ExcelExport
                element={<button type="button" className="display-none" ref={this.buttonDetailsRef} />}
                nameSheet={fileNameExport?.toLocaleUpperCase()}
                multiDataSet={convertJson2Sheet({
                  data: listAllDataServiceReportDetails || [],
                  t,
                  title: fileNameExport?.toLocaleUpperCase(),
                  columnsTable: tabelColumnExportOnDemandDetails,
                })}
                fileName={`${fileNameExport?.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
                  'YYYY_MM_DD'
                )}`}
              />
            </>
          </div>

          <DataTable
            isFixedHeaderTable
            columns={tabelColumnExportOnDemandDetails}
            data={listDataServiceReportDetails || []}
            onSort={this.onSortColumn}
            isLoading={isSearching}
          />
          <br />
          <div className="mb-15">
            <TablePagination
              pageNumber={pageDetails}
              pageSize={sizeDetails}
              totalCount={totalCountDetails}
              onPageChange={this.onPageChangeDetails}
              onSizeChange={this.onSizeChangeDetails}
            />
          </div>
          <ButtonCustom
            className="ml-auto mt-auto mb-auto mr-2 btn-cancel float-right"
            type="button"
            title={t('label.cancel')}
            datastyle={SLIDE_UP}
            onClick={this.onToggleModalDetails}
          />
        </ModalWithItem>
      </div>
    );
  }

  render() {
    const { permissionsSelfCare } = this.props;
    let modeViewTransactions = 2;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewTransactions = checkPermissionViewTransactions({
        listPermission,
      });
    }
    return (
      <div className="view-transactions-page">
        <MainHeader activeTab="Reports" />
        {modeViewTransactions !== 0 && (
          <div className="view-transactions-page__form">
            <div className="form-wrapper table-content">{this.renderContent()}</div>
          </div>
        )}
      </div>
    );
  }
}

OnDemand.propTypes = {
  history: PropTypes.object,
  arOpsUnit: PropTypes.array,
  params: PropTypes.object,
  getAccountStatement: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  // permissionsSelfCare: makeGetPermissionSelfCare() || {},
  listOnDemand: makeGetListServiceReportData() || [],
  listAllOnDemand: makeGetListAllServiceReportData() || [],
  accountInfo: makeGetAccountInfo() || {},
});

const withConnect = connect(mapStateToProps, {
  getDataServiceReportData,
  getListAllDataServiceReportData,
  onResetDefaultData,
  uploadMultiPartFiles,
  getOutputTemplateByType,
  getDataServiceReportDetails,
  getAllDataServiceReportDetails,
});

export default withTranslation('common')(compose(withConnect)(OnDemand));
