import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { map, groupBy, sortBy } from 'lodash';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { SLIDE_UP } from 'react-ladda';
import { MainHeader } from '../../components/Header';
import { GenericInput, ButtonCustom, FormCollapse, AccountHeaderForm } from '../../components/common';
import { getAccountId } from '../../localStorage';
import { makeGetPermissionSelfCare } from '../App/selectors';
import { getCustomAttributesByObjectType, getAccountById } from '../App/actions';
import './styles.scss';

export class CustomAttributes extends React.Component {
  state = {
    customAttributesByObjectType: [],
    activeTab: {},
  };

  componentDidMount = () => {
    const { getAccountById, getCustomAttributesByObjectType } = this.props;
    getAccountById(
      {
        id: getAccountId(),
      },
      ({ success, data }) => {
        if (success && data && data.customAttributes) {
          getCustomAttributesByObjectType(res => {
            if (res.data) {
              this.setState(
                {
                  customAttributesByObjectType: res.data && res.data.attributes ? res.data.attributes : [],
                },
                () => {
                  this.setValue(data.customAttributes || []);
                }
              );
            }
          });
        }
      }
    );
  };

  setValue = value => {
    const { customAttributesByObjectType } = this.state;
    const sef = this;
    let tempAttributes = [];
    tempAttributes = customAttributesByObjectType.map(item => {
      const temp = sef.getValueFromName(item.name, value);
      return { ...item, value: temp };
      // return item;
    });
    this.setState({
      customAttributesByObjectType: tempAttributes,
    });
  };

  getValueFromName = (name, value) => {
    let result = '';
    value.map(item => {
      if (item.name === name) {
        result = item.value;
      }
    });
    return result;
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const { permissionsSelfCare, t } = this.props;
    // let modeViewSubscription = 0;
    // if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
    //   const listPermission = permissionsSelfCare.selfCareModulePermissions;
    //   modeViewSubscription = checkPermissionViewSubscription({
    //     listPermission,
    //   });
    // }
    const { customAttributesByObjectType, activeTab } = this.state;
    let isPPD = false;
    let isPUE = false;
    if (customAttributesByObjectType && customAttributesByObjectType.length) {
      const metodoPagoItem = customAttributesByObjectType.find(
        val =>
          (val && val.displayName && val.displayName.toLowerCase() === 'Metodo Pago'.toLowerCase()) ||
          (val && val.name && val.name.toLowerCase() === 'Metodo Pago'.toLowerCase()) ||
          (val && val.name && val.name.toLowerCase() === 'Método de Pago'.toLowerCase()) ||
          (val && val.displayName && val.displayName.toLowerCase() === 'Método de Pago'.toLowerCase())
      );
      if (metodoPagoItem && metodoPagoItem.value === 'PPD') {
        isPPD = true;
      }
      if (metodoPagoItem && metodoPagoItem.value === 'PUE') {
        isPUE = true;
      }
    }
    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="AccountData" />
        <div className="manage-page__form p-3">
          <div className="form-billing">
            <form className="col-md-12 row pt-4 group-input-billing">
              <div className="col-md-12 p-0">
                <Row>
                  {/* <div className="col-md-12 pl-0 pr-0 pt-0 pb-3">
                    <h4 className="card-title-mb">
                      {t('label.customAttributes')}
                      <span className="account-number">
                        <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                        &nbsp;&nbsp;
                        <span className="account-detail">{getAccountId()}</span>
                      </span>
                    </h4>
                  </div> */}
                  <AccountHeaderForm title={t('label.customAttributes')} />
                </Row>
              </div>
              {/* {!customAttributesByObjectType ||
                (!customAttributesByObjectType.length && (
                  <div className="text-center">
                    {t('message.noRecord')}
                    <br />
                    <br />
                  </div>
                ))} */}
              {customAttributesByObjectType &&
                customAttributesByObjectType.length > 0 &&
                map(
                  groupBy(
                    sortBy(customAttributesByObjectType, val => val.category),
                    item => {
                      return item.category;
                    }
                  ),
                  (listAttribute, key) => {
                    return (
                      <FormCollapse
                        key={key}
                        title={key}
                        isActive={!!(key === activeTab.name && activeTab.isActive)}
                        onToggleTab={this.onToggleTab}
                        state={key}
                        wrapperClass="col-md-12"
                      >
                        <div className="col-md-12 row pb-2 pt-2 pl-0 pr-0">
                          {listAttribute &&
                            listAttribute.length > 0 &&
                            map(listAttribute, (item, index) => {
                              if (item.type === 'BOOLEAN') {
                                return (
                                  <GenericInput
                                    key={item.index}
                                    required={item.mandatory === 'Y'}
                                    value={item.value === 'true' || item.value === true ? 'TRUE' : 'FALSE'}
                                    label={`${item.displayName || ' '}`}
                                    subLabel="Boolean(true/false)"
                                    name={item.name}
                                    onChange={({ value }) => {}}
                                    wrapperClass="form-group col-md-4"
                                    type="select"
                                    tOptions="selections:selectBool"
                                    tooltipDetails={item.description}
                                    readOnly
                                    disabled
                                  />
                                );
                              }
                              let options = item?.attributesLOVs?.length
                                ? item?.attributesLOVs?.map(val => ({
                                    label: `${val.attributeId} : ${val.attributeValue}`,
                                    value: val.attributeId,
                                  }))
                                : [];
                              if (
                                isPPD &&
                                (item.displayName.toLowerCase() === 'Tipo De Pago'.toLowerCase() ||
                                  item.name === 'Tipo De Pago'.toLowerCase())
                              ) {
                                options = options.filter(val => val.value === 'NO IDENTIFICADO');
                              }
                              if (
                                isPUE &&
                                (item.displayName.toLowerCase() === 'Tipo De Pago'.toLowerCase() ||
                                  item.name === 'Tipo De Pago'.toLowerCase())
                              ) {
                                options = options.filter(val => val.value !== 'NO IDENTIFICADO');
                              }
                              if (
                                isPUE &&
                                (item.displayName.toLowerCase() === 'Forma Pago'.toLowerCase() ||
                                  item.name === 'Forma Pago'.toLowerCase() ||
                                  item.displayName.toLowerCase() === 'Forma De Pago'.toLowerCase() ||
                                  item.name.toLowerCase() === 'Forma De Pago'.toLowerCase())
                              ) {
                                options = [];
                              }
                              return (
                                <GenericInput
                                  key={item.index}
                                  value={item.value || ''}
                                  label={item.displayName || ' '}
                                  required={item.mandatory === 'Y'}
                                  subLabel={`${
                                    item.type
                                      ? item.type.charAt(0).toLowerCase() + item.type.toLowerCase().slice(1)
                                      : ''
                                  }${item.type === 'VARCHAR' ? '(255)' : ''}`}
                                  type={item.type === 'ENUM' ? 'select' : item.type === 'VARCHAR' ? 'text' : 'number'}
                                  options={options}
                                  onChange={({ value }) => this.onHandleChange(value, item.index)}
                                  name={item.name}
                                  wrapperClass="form-group col-md-4"
                                  tooltipDetails={item.description}
                                  readOnly
                                  disabled
                                />
                              );
                            })}
                        </div>
                      </FormCollapse>
                    );
                  }
                )}
              <div className="col-md-12 p-0">
                <Row className="mt-sm-0 mx-0 row">
                  <Col sm={{ size: 12, offset: 8 }} className="row float-right">
                    <ButtonCustom
                      // loading={isSubmitting}
                      className="ml-auto mt-auto mb-auto mr-2 btn-cancel"
                      type="button"
                      title={t('label.cancel')}
                      titleloading="Modifying"
                      datastyle={SLIDE_UP}
                      onClick={() => {
                        this.props.history.push('/');
                      }}
                    />
                    {/* <ButtonCustom
                      className="btn-submit"
                      type="submit"
                      title={t('label.submit')}
                      // disabled={modeModifyUser === 1}
                      datastyle={SLIDE_UP}
                    /> */}
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CustomAttributes.propTypes = {
  getAccountById: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    getCustomAttributesByObjectType: (data, cb) => dispatch(getCustomAttributesByObjectType(data, cb)),
    getAccountById: (data, cb) => dispatch(getAccountById(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common'])(compose(withConnect)(CustomAttributes));
