// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from '../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['bundles']);
  const processNewAccount = `processNewAccount(input: ${dataCreate})`;
  return gql`
  mutation {
    ${processNewAccount} {
      accountId
      orderId
    }
  }
`;
};
