import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the dashBoardPage state domain
 */

const selectDashBoardPageDomain = state => state.get('dashBoardPageReducer', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by DashBoardPage
 */

const makeSelectDashBoardPage = () => createSelector(selectDashBoardPageDomain, substate => substate.toJS());
const makeGetAccountInformation = () =>
  createSelector(selectDashBoardPageDomain, items =>
    items.get('accountInformation') ? items.get('accountInformation') : null
  );

export default makeSelectDashBoardPage;
export { selectDashBoardPageDomain, makeGetAccountInformation };
