import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { isEmpty, cloneDeep } from 'lodash';
import { MainHeader } from '../../components/Header';
import BroadsoftZeroDurationCdrsSearchForm from './BroadsoftZeroDurationCdrsSearchForm';
import {
  TablePagination,
  DataTable,
  SwitchExport,
  ButtonExport,
  ExcelExport,
  MultiInputGroup,
  AccountHeaderForm,
} from '../../components/common';
import { checkPermissionViewTransactions } from '../../utils/CheckPermissions';
import {
  makeGetListBroadsoftZeroDurationCdrst,
  makeGetListAllBroadsoftZeroDurationCdrst,
  makeGetAccountInfo,
} from '../App/selectors';
import {
  uploadMultiPartFiles,
  getBroadsoftZeroDurationCdrsReport,
  getAllBroadsoftZeroDurationCdrsReport,
  onResetDefaultData,
  getOutputTemplateByType,
} from '../App/actions';
import './styles.scss';
import {
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  getPageTotalCount,
  configOutputTemplateByType,
} from '../../utils/utils';
import convertJson2Sheet from '../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../utils/PdfHelper/exportPdfFile';

const sortBroadsoftZeroDurationCdrs = {
  answerTime: {
    asc: 'answerTime_ASC',
    desc: 'answerTime_DESC',
  },
  releaseTime: {
    asc: 'releaseTime_ASC',
    desc: 'releaseTime_DESC',
  },
  extDurationMinutes: {
    asc: 'durationMinutes_ASC',
    desc: 'durationMinutes_DESC',
  },
  extDurationSeconds: {
    asc: 'durationSeconds_ASC',
    desc: 'durationSeconds_DESC',
  },
};

const tableColumns = [
  {
    name: 'serviceProvider',
    label: 'label.sServiceProvider',
  },
  {
    name: 'userNumber',
    label: 'label.sUserNumber',
  },
  {
    name: 'groupNumber',
    label: 'label.sGroupNumber',
  },
  {
    name: 'direction',
    label: 'label.sDirection',
  },
  {
    name: 'callingNumber',
    label: 'label.sCallingNumber',
  },
  {
    name: 'calledNumber',
    label: 'label.sCalledNumber',
  },
  {
    name: 'startTime',
    label: 'label.sStartTime',
  },
  {
    name: 'answerIndicator',
    label: 'label.sAnswerIndicator',
  },
  {
    name: 'answerTime',
    label: 'label.sAnswerTime',
    sortable: true,
  },
  {
    name: 'releaseTime',
    label: 'label.sReleaseTime',
    sortable: true,
  },
  {
    name: 'terminationCause',
    label: 'label.sTerminationCause',
  },
  {
    name: 'dailedDigits',
    label: 'label.sDailedDigits',
  },
  {
    name: 'callCategory',
    label: 'label.sCallCategory',
  },
  {
    name: 'networkCallType',
    label: 'label.sNetworkCallType',
  },
  {
    name: 'networkTranslatedNumber',
    label: 'label.sNetworkTranslatedNumber',
  },
  {
    name: 'releasingParty',
    label: 'label.sReleasingParty',
  },
  {
    name: 'route',
    label: 'label.sRoute',
  },
  {
    name: 'networkCallId',
    label: 'label.sNetworkCallId',
  },
  {
    name: 'codec',
    label: 'label.sCodec',
  },
  {
    name: 'accessDeviceAddress',
    label: 'label.sAccessDeviceAddress',
  },
  {
    name: 'group',
    label: 'label.sGroup',
  },
  {
    name: 'department',
    label: 'label.sDepartment',
  },
  {
    name: 'authorizationCode',
    label: 'label.sAuthorizationCode',
  },
  {
    name: 'originalCalledNumber',
    label: 'label.sOriginalCalledNumber',
  },
  {
    name: 'originalCalledPresentationIndicator',
    label: 'label.sOriginalCalledPresentationIndicator',
  },
  {
    name: 'originalCalledReason',
    label: 'label.sOriginalCalledReason',
  },
  {
    name: 'redirectingNumber',
    label: 'label.sRedirectingNumber',
  },
  {
    name: 'redirectingPresentationIndicator',
    label: 'label.sRedirectingPresentationIndicator',
  },
  {
    name: 'redirectingReason',
    label: 'label.sRedirectingReason',
  },
  {
    name: 'chargeIndicator',
    label: 'label.sChargeIndicator',
  },
  {
    name: 'voicePortalInvocationTime',
    label: 'label.sVoicePortalInvocationTime',
  },
  {
    name: 'extAnswerDateTime',
    label: 'label.dExtAnswerDateTime',
  },
  {
    name: 'extDurationMinutes',
    label: 'label.nExtDurationMinutes',
    sortable: true,
  },
  {
    name: 'extDurationSeconds',
    label: 'label.nExtDurationSeconds',
    sortable: true,
  },
  {
    name: 'sreserved',
    label: 'label.sReserved',
  },
  {
    name: 'suserid',
    label: 'label.sUserid',
  },
  {
    name: 'sotherPartyName',
    label: 'label.sOtherPartyName',
  },
];

let tableColumnsOutput = null;

export class BroadsoftZeroDurationCdrs extends React.PureComponent {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActivePdf: false,
      page: 0,
      size: 20,
      filter: {},
      sort: '',
      sorted: {},
      totalCount: null,
      isSearching: false,
      listBroadsoftZeroDurationCdrs: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      listBroadsoftZeroDurationCdrs: props.listBroadsoftZeroDurationCdrs,
      totalCount: getPageTotalCount({ ...state, items: props.listBroadsoftZeroDurationCdrs }),
    };
  }

  componentDidMount() {
    this.doGetOutputTemplateByType();
    onResetDefaultData('listBroadsoftZeroDurationCdrs');
  }

  onHandleSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListBroadsoftZeroDurationCdrs();
    });
  };

  doGetListBroadsoftZeroDurationCdrs = () => {
    const { filter, page, size, sorted } = this.state;
    const { getBroadsoftZeroDurationCdrsReport, accountInfo } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.startDate) {
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.endDate) {
      newFilter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.switchName) {
      newFilter.switchName = 'BROADSOFT';
    }
    if (accountInfo?.organization) {
      newFilter.clientName = accountInfo?.organization;
    }
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort:
        !isEmpty(sorted) && sortBroadsoftZeroDurationCdrs[sorted.sortCol]
          ? sortBroadsoftZeroDurationCdrs[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getBroadsoftZeroDurationCdrsReport(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistBroadsoftZeroDurationCdrs = cb => {
    const { filter, sorted } = this.state;
    const { getAllBroadsoftZeroDurationCdrsReport, accountInfo } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.startDate) {
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.endDate) {
      newFilter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.switchName) {
      newFilter.switchName = 'BROADSOFT';
    }
    if (accountInfo?.organization) {
      newFilter.clientName = accountInfo?.organization;
    }
    const payload = {
      page: 1,
      size: 10000000,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortBroadsoftZeroDurationCdrs[sorted.sortCol][sorted.sortDir] : null,
    };
    getAllBroadsoftZeroDurationCdrsReport(payload, () => {
      if (cb) cb();
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListBroadsoftZeroDurationCdrs();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListBroadsoftZeroDurationCdrs());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListBroadsoftZeroDurationCdrs());
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistBroadsoftZeroDurationCdrs(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllBroadsoftZeroDurationCdrs, t } = this.props;
        convertJson2Pdf({
          data: listAllBroadsoftZeroDurationCdrs,
          t,
          title: t('label.rawBroadsoftZeroDurationCDR').toLocaleUpperCase(),
          fileName: `${t('label.rawBroadsoftZeroDurationCDR')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = () => {
    this.doGetAlllistBroadsoftZeroDurationCdrs(() => {
      const { listAllBroadsoftZeroDurationCdrs, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'RAW_BROADSOFT_ZERO_DURATION_CDR_REPORT',
          subject: `Embrix Report ${t('label.rawBroadsoftZeroDurationCDR')}`,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('label.rawBroadsoftZeroDurationCDR')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllBroadsoftZeroDurationCdrs,
            t,
            columns: tableColumnsOutput,
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllBroadsoftZeroDurationCdrs,
          t,
          title: t('label.rawBroadsoftZeroDurationCDR').toLocaleUpperCase(),
          fileName: `${t('label.rawBroadsoftZeroDurationCDR')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('label.rawBroadsoftZeroDurationCDR')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('RAW_BROADSOFT_ZERO_DURATION_CDR_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumns,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  renderContent() {
    const { t, listAllBroadsoftZeroDurationCdrs, permissions } = this.props;
    const {
      listBroadsoftZeroDurationCdrs,
      page,
      size,
      isActivePdf,
      emails,
      totalCount,
      sorted,
      isSearching,
      exportColumnsSave,
    } = this.state;
    const { modeGetBroadsoftZeroDurationCdrs, modeDownloadReport, modeGetMailReport } = permissions || {};

    if (!modeGetBroadsoftZeroDurationCdrs) return '';
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    return (
      <div className="transactions">
        <div className="col-md-12 p-0">
          <br />
          <Row>
            <Col md={12}>
              <AccountHeaderForm title={t('label.rawBroadsoftZeroDurationCDR')} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12} className="p-0">
              <BroadsoftZeroDurationCdrsSearchForm
                onSubmit={this.onHandleSearch}
                isSearching={this.state.isSearching}
              />
            </Col>
            <Col md={12} className="p-0">
              <div className="mt-3 row float-right mb-4 pt-3 pb-3">
                {!!modeDownloadReport && (
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                    />
                  </div>
                )}
                {!!modeDownloadReport && (
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet={t('label.rawBroadsoftZeroDurationCDR').toLocaleUpperCase()}
                      multiDataSet={convertJson2Sheet({
                        data: listAllBroadsoftZeroDurationCdrs,
                        t,
                        title: t('label.rawBroadsoftZeroDurationCDR').toLocaleUpperCase(),
                        columnsTable: tableColumnsOutput,
                      })}
                      fileName={`${t('label.rawBroadsoftZeroDurationCDR')
                        .toLocaleLowerCase()
                        .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                    />
                  </>
                )}
                {!!modeGetMailReport && (
                  <div className="email-group">
                    <MultiInputGroup
                      label={t('label.emails')}
                      wrapperClass="email-form"
                      value={emails || []}
                      inputProps={{
                        placeholder: t('label.addAEmail'),
                      }}
                      onChange={this.onChangeEmail}
                    />
                    <button
                      type="submit"
                      onClick={() => this.sendEmail({ columns: tableColumnsOutput })}
                      disabled={!emails || !emails.length}
                      className="ladda-button btn btn-submit x-small mr-3 mt-0 float-right btn-default-height"
                    >
                      {t('label.email')}
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <br />
          <DataTable
            isFixedHeaderTable
            tableClass="table table-hover"
            columns={tableColumnsOutput}
            data={
              listBroadsoftZeroDurationCdrs && listBroadsoftZeroDurationCdrs.length ? listBroadsoftZeroDurationCdrs : []
            }
            onSort={this.onSortColumn}
            sorted={sorted}
            isLoading={isSearching}
          />
          <br />
          <br />
          <div className="mb-30">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }

  render() {
    const { permissionsSelfCare } = this.props;
    let modeViewTransactions = 2;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewTransactions = checkPermissionViewTransactions({
        listPermission,
      });
    }
    return (
      <div className="view-transactions-page">
        <MainHeader activeTab="Reports" />
        {modeViewTransactions !== 0 && (
          <div className="view-transactions-page__form">
            <div className="form-wrapper table-content">{this.renderContent()}</div>
          </div>
        )}
      </div>
    );
  }
}

BroadsoftZeroDurationCdrs.propTypes = {
  history: PropTypes.object,
  arOpsUnit: PropTypes.array,
  params: PropTypes.object,
  getAccountStatement: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  // permissionsSelfCare: makeGetPermissionSelfCare() || {},
  listBroadsoftZeroDurationCdrs: makeGetListBroadsoftZeroDurationCdrst() || [],
  listAllBroadsoftZeroDurationCdrs: makeGetListAllBroadsoftZeroDurationCdrst() || [],
  accountInfo: makeGetAccountInfo() || {},
});

const withConnect = connect(mapStateToProps, {
  getBroadsoftZeroDurationCdrsReport,
  getAllBroadsoftZeroDurationCdrsReport,
  onResetDefaultData,
  uploadMultiPartFiles,
  getOutputTemplateByType,
});

export default withTranslation('common')(compose(withConnect)(BroadsoftZeroDurationCdrs));
