import { createSelector } from 'reselect';

/**
 * Direct selector to the manageProfilePage state domain
 */

const selectViewArOpsUnits = state => state.get('viewARActivityPageReducer');

const makeGetArOpsUnitUnit = () =>
  createSelector(selectViewArOpsUnits, ArOpsUnit => (ArOpsUnit ? [...ArOpsUnit.get('arOpsUnit')] : []));

const makePageArOpsUnitParams = () =>
  createSelector(selectViewArOpsUnits, customers => (customers ? customers.get('paramsArOpsUnit').toJS() : {}));

export { makeGetArOpsUnitUnit, makePageArOpsUnitParams };
