import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the manageProfilePage state domain
 */

const selectManagePaymentDomain = state =>
  state.get('manageInvoices', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageProfilePage
 */

const makeSelectManagePayment = () =>
  createSelector(selectManagePaymentDomain, substate => substate.toJS());

export default makeSelectManagePayment;
export { selectManagePaymentDomain };
