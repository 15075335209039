import { createSelector } from 'reselect';

const selectViewInvoices = state => state.get('viewInvoicesPageReudcer');

const makeGetPendingBills = () =>
  createSelector(selectViewInvoices, invoices => (invoices ? [...invoices.get('pendingBills')] : []));

const makeGetOCBills = () => createSelector(selectViewInvoices, invoices => (invoices ? invoices.get('ocBills') : {}));

export { makeGetPendingBills, makeGetOCBills };
