import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import GenericInput from '../GenericInput';

export const PageSize = ({ pageSize, onSizeChange }) => (
  <div className="page-size-box text-right">
    <GenericInput
      name="pageSize"
      type="select"
      // label={t('label.pageSize')}
      wrapperClass="page-size-select"
      value={pageSize || 20}
      options={[
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
      ]}
      onChange={e => onSizeChange(e ? e.value : 20)}
    />
  </div>
);
PageSize.propTypes = {
  pageSize: PropTypes.number,
  onSizeChange: PropTypes.func,
};
PageSize.defaultProps = {
  pageSize: 20,
  onSizeChange: () => {},
};

export const Pagination = ({
  totalCount,
  pageSize,
  handlePageChange,
  pageNumber,
}) => (
  <nav className="pagination" aria-label="pagination">
    <ReactPaginate
      previousLabel={(
        <svg
          transform="rotate(90)"
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
        >
          <path
            fill="#8891A5"
            fillRule="nonzero"
            d="M5 3.586L8.293.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4A1 1 0 0 1 1.707.293L5 3.586z"
          />
        </svg>
      )}
      nextLabel={(
        <svg
          transform="rotate(-90)"
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
        >
          <path
            fill="#8891A5"
            fillRule="nonzero"
            d="M5 3.586L8.293.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4A1 1 0 0 1 1.707.293L5 3.586z"
          />
        </svg>
      )}
      breakLabel="..."
      pageClassName="pagination__item page-item"
      previousClassName="pagination__item page-item prev-page"
      previousLinkClassName="page-link"
      breakClassName="pagination__item page-item"
      nextClassName="pagination__item page-item next-page"
      nextLinkClassName="page-link"
      breakLinkClassName="page-link"
      pageLinkClassName="pagination__link page-link"
      pageCount={Math.ceil(totalCount / Math.max(pageSize, 1))}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={handlePageChange}
      containerClassName="pagination"
      subContainerClassName="pages pagination"
      activeClassName="active"
      forcePage={pageNumber}
      initialPage={0}
    />
  </nav>
);
Pagination.propTypes = {
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  handlePageChange: PropTypes.func,
  pageNumber: PropTypes.number,
};
Pagination.defaultProps = {
  pageSize: 20,
  totalCount: 0,
  handlePageChange: () => {},
  pageNumber: 0,
};

class TablePagination extends React.PureComponent {
  handlePageChange = e => {
    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(e.selected);
    }
  };

  render() {
    const { pageNumber, pageSize, totalCount, onSizeChange } = this.props;
    return (
      <div className="row">
        <div className="col-md-6 text-left">
          <Pagination
            pageNumber={pageNumber}
            pageSize={pageSize}
            totalCount={totalCount}
            handlePageChange={this.handlePageChange}
          />
        </div>
        <div className="col-md-6 text-right">
          <PageSize pageSize={pageSize} onSizeChange={onSizeChange} />
        </div>
      </div>
    );
  }
}
TablePagination.propTypes = {
  onPageChange: PropTypes.func,
  onSizeChange: PropTypes.func,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
};

TablePagination.defaultProps = {
  pageNumber: 1,
  pageSize: 20,
  totalCount: 0,
  onPageChange: () => {},
  onSizeChange: () => {},
};
export default TablePagination;
