import gql from 'graphql-tag';

export default type => {
  const getOutputTemplateByType = `getOutputTemplateByType(input: "${type}")`;
  return gql`
    query {
      ${getOutputTemplateByType} {
        userId
        type
        id
        source {
          name
          index
        }
        target {
          name
          index
        }
      }
    }
  `;
};
