/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ButtonToolbar, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { ButtonCustom } from '../common';

const listFieldsSearchForm = [
  // {
  //   placeholder: 'Id',
  //   name: 'id',
  //   type: 'text',
  // },
  {
    placeholder: 'label.itemId',
    label: 'label.itemId',
    name: 'itemId',
    type: 'text',
  },
  {
    placeholder: 'label.endDate',
    label: 'label.endDate',
    name: 'endDate',
    type: 'date',
  },
  {
    placeholder: 'label.aRType',
    label: 'label.aRType',
    name: 'arType',
    type: 'select',
    tOptions: 'selections:arOpsType',
  },
  {
    placeholder: 'label.arTaxRule',
    label: 'label.arTaxRule',
    name: 'arTaxRule',
    type: 'select',
    tOptions: 'selections:taxRule',
  },
  {
    placeholder: 'label.arCrDrType',
    label: 'label.arCrDrType',
    name: 'arCrDrType',
    type: 'select',
    tOptions: 'selections:CRDRType',
  },
  {
    placeholder: 'label.invoiceUnitId',
    label: 'label.invoiceUnitId',
    name: 'invoiceUnitId',
    type: 'text',
  },
];

export class SearchFilterARActivity extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      dataSearch: {
        startDate: null,
      },
      isOpenStartDate: false,
      isOpenEndDate: false,
    };
  }

  onHandleSearch = evt => {
    evt.preventDefault();
    const {
      arType,
      itemId,
      endDate,
      id,
      arTaxRule,
      arCrDrType,
      invoiceUnitId,
    } = this.state.dataSearch;
    const filter = {
      arType: arType || null,
      id: id || null,
      itemId: itemId || null,
      arTaxRule: arTaxRule || null,
      arCrDrType: arCrDrType || null,
      invoiceUnitId: invoiceUnitId || null,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
    };
    this.props.onHandleSearch(filter);
  };

  onChangeValueForm = (key, value) => {
    const { dataSearch } = this.state;
    dataSearch[key] = value;
    this.setState({
      ...dataSearch,
    });
  };

  onChangeDate = (key, date) => {
    const { dataSearch } = this.state;
    dataSearch[key] = date;
    this.setState({
      ...dataSearch,
    });
  };

  onHandleClear = () => {
    this.setState({
      dataSearch: {
        startDate: null,
      },
    });
  };

  render() {
    const { isSearching, t } = this.props;
    const {
      dataSearch,
      dataSearch: { endDate },
      isOpenStartDate,
      isOpenEndDate,
    } = this.state;
    return (
      <div className="search-filter">
        <form className="form" onSubmit={evt => this.onHandleSearch(evt)}>
          <div className="col-md-12 p-0 mb-3 row">
            {listFieldsSearchForm.map(item => {
              if (item.type === 'select') {
                return (
                  <div className="col-md-3 mt-3">
                    <Label for="">{t(item.label)}</Label>
                    <Select
                      value={
                        t(item.tOptions)().find(
                          el => el.value === dataSearch[item.name],
                        ) || null
                      }
                      onChange={val =>
                        this.onChangeValueForm(item.name, val ? val.value : '')
                      }
                      className="form__form-group-select"
                      isDisabled={false}
                      isClearable
                      {...item}
                      options={t(item.tOptions)()}
                      placeholder={t(item.placeholder)}
                    />
                  </div>
                );
              }
              if (item.type === 'date') {
                return (
                  <div className="col-md-3 mt-3">
                    <Label for="">{t(item.label)}</Label>
                    <div className="d-flex">
                      <div
                        className="date-picker"
                        onClick={() => this.setState({ isOpenEndDate: true })}
                      >
                        <DatePicker
                          className="form__form-group-datepicker"
                          popperPlacement="bottom-start"
                          popperModifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                            },
                          }}
                          open={isOpenEndDate}
                          onClickOutside={() =>
                            this.setState({ isOpenEndDate: false })
                          }
                          selected={endDate}
                          onChange={date => this.onChangeDate('endDate', date)}
                          dateFormat="yyyy-mm-dd"
                          // minDate={this.props.isMinDate ? moment() : null}
                          autoComplete="off"
                          isClearable
                          disabled={false}
                          {...item}
                          placeholderText={t(item.placeholder)}
                        />
                      </div>

                      <div
                        className="form__form-group-icon"
                        onClick={() => {
                          if (!isOpenEndDate)
                            this.setState({ isOpenEndDate: true });
                        }}
                      >
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div className=" mt-3 col-md-3 ml-0">
                  <Label for="">{t(item.label)}</Label>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      value={dataSearch[item.name] || ''}
                      onChange={evt =>
                        this.onChangeValueForm(item.name, evt.target.value)
                      }
                      {...item}
                      placeholder={t(item.placeholder)}
                    />
                  </div>
                </div>
              );
            })}

            <ButtonToolbar className="ml-auto form__button-toolbar mt-5 mr-3">
              <ButtonCustom
                // loading={isSearching}
                onClick={this.onHandleClear}
                className="btn btn-cancel mr-0"
                type="button"
                title={t('label.clear')}
                titleloading="Searching"
              />
              <ButtonCustom
                loading={isSearching}
                className="btn btn-submit"
                type="submit"
                title={t('label.search')}
                titleloading="Searching"
              />
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}

SearchFilterARActivity.propTypes = {
  onHandleSearch: PropTypes.func,
  isSearching: PropTypes.bool,
};

export default withTranslation('common')(SearchFilterARActivity);
