import gql from 'graphql-tag';

export default id => gql`
  query {
    getNotesUnitById(input: { id: "${id}" }) {
      id
      type
      accountId
      invoiceDelivery
      company
      status
      notesDate
      total
      taxId
      message
      customerAddress{
        type
        street
        extraLine
        landmark
        city
        state
        country
        postalCode
  		}
      returnAddress {
        type
        street
        extraLine
        landmark
        city
        state
        country
        postalCode
      }
      notesSummaryList {
        id
        index
        amount
        type
        reason
        notesType
        taxRule
      }
      invoiceSummaryTotal {
        taxSubTotal
        summarySubTotal
        total
        sofaTotal
      }
      invoiceTaxList {
        index
        city
        state
        jurisdiction
        taxableAmount
        exemptAmount
        amount
        taxRate
      }
    }
  }
`;
