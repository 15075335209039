import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import styleTotalBottom from './styleTotalBottom';
import { getLabelOptions, redFontExcelFile } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t, customerInfo, title }) => {
  let columnsList = [];
  let headerCurrency = [];
  let headerList = [];
  if (customerInfo?.type === 'CLIENT') {
    columnsList = [columns.common, columns.common, columns.common, columns.common];
    headerCurrency = [
      {
        value: t('label.invoiceCurrency'),
        style: styleHeader,
      },
    ];
    headerList = [
      {
        value: t('label.totalFunctionalCurrencyExport'),
        style: styleHeader,
      },
      {
        value: t('label.functionalCurrency'),
        style: styleHeader,
      },
      {
        value: t('label.exchangeRate'),
        style: styleHeader,
      },
    ];
  }
  return [
    {
      xSteps: 6,
      columns: [],
      data: [[{ value: title || 'ACCOUNT STATEMENT', style: { font: { sz: '20', bold: true } } }]],
    },
    {
      columns: [
        columns.accountId,
        columns.common, // documnet type
        columns.invoiceId,
        columns.invoiceDate,
        columns.common, // sub total
        columns.common, // Taxes
        columns.total,
        columns.dueDate,
        columns.common, // Folio Id
        columns.common, // Folio Date
        columns.common, // folioStatus
        columns.status,
        columns.common, // Opening Balance
        columns.common, // Closing Balance
        ...columnsList,
      ],
      data: [
        [
          {
            value: t('label.accountId'),
            style: styleHeader,
          },
          {
            value: t('label.documentType'),
            style: styleHeader,
          },
          {
            value: t('label.invoiceId'),
            style: styleHeader,
          },
          {
            value: t('label.invoiceDate'),
            style: styleHeader,
          },
          ...headerCurrency,
          {
            value: t('label.subTotal'),
            style: styleHeader,
          },
          {
            value: t('label.taxes'),
            style: styleHeader,
          },
          {
            value: t('label.total'),
            style: styleHeader,
          },
          ...headerList,
          {
            value: t('label.dueDate'),
            style: styleHeader,
          },
          {
            value: t('label.folioId'),
            style: styleHeader,
          },
          {
            value: t('label.folioDate'),
            style: styleHeader,
          },
          {
            value: t('label.folioStatus'),
            style: styleHeader,
          },
          {
            value: t('label.status'),
            style: styleHeader,
          },
          {
            value: t('label.openingBalance'),
            style: styleHeader,
          },
          {
            value: t('label.closingBalance'),
            style: styleHeader,
          },
        ],
      ],
    },
  ];
};

const convertJson2Sheet = ({ data, t, title, customerInfo }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title, customerInfo }));
  let totalAmount = 0;
  let totalFunctionalCurrency = 0;
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.accountId ? item.accountId : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'lineType', fieldOption: 'accountStatementLineType' }),
      });
      newItem.push({ value: item.invoiceId ? item.invoiceId : '' });
      newItem.push({ value: item.invoiceDate ? item.invoiceDate : '' });
      if (customerInfo?.type === 'CLIENT') {
        newItem.push({ value: item.currency ? item.currency : '' });
      }
      newItem.push({ value: item.subTotal ? item.subTotal : '', style: item.subTotal < 0 ? redFontExcelFile : null });
      newItem.push({ value: item.taxes ? item.taxes : '', style: item.taxes < 0 ? redFontExcelFile : null });
      newItem.push({ value: item.amount ? item.amount : '', style: item.amount < 0 ? redFontExcelFile : null });
      totalAmount += Number.parseFloat(item.amount || 0);
      if (customerInfo?.type === 'CLIENT') {
        newItem.push({
          value: item.amountInFunctionalCurrency ? item.amountInFunctionalCurrency : '',
          style: item.amountInFunctionalCurrency < 0 ? redFontExcelFile : null,
        });
        totalFunctionalCurrency += Number.parseFloat(item.amountInFunctionalCurrency || 0);
        newItem.push({ value: item.functionalCurrency ? item.functionalCurrency : '' });
        newItem.push({
          value: item.exchangeRate ? item.exchangeRate : '',
          style: item.exchangeRate < 0 ? redFontExcelFile : null,
        });
      }
      newItem.push({ value: item.dueDate ? item.dueDate : '' });
      newItem.push({ value: item.lineReferenceId ? item.lineReferenceId : '' });
      newItem.push({ value: item.lineDate ? item.lineDate : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'folioStatus', fieldOption: 'folioStatus' }),
      });
      newItem.push({ value: item.status ? item.status : '' });
      if (customerInfo?.type === 'CLIENT') {
        newItem.push({ value: '' });
        newItem.push({ value: '' });
      } else {
        newItem.push({
          value: item.openingBalance ? item.openingBalance : '',
          style: item.openingBalance < 0 ? redFontExcelFile : null,
        });
        newItem.push({
          value: item.closingBalance ? item.closingBalance : '',
          style: item.closingBalance < 0 ? redFontExcelFile : null,
        });
      }
    }
    return newItem;
  });
  if (newListData && newListData.length && customerInfo?.type === 'CLIENT') {
    const newTotalItem = newListData[0].map((val, index) => {
      if (customerInfo?.type === 'CLIENT' && index === 7) {
        return { value: totalAmount, style: styleTotalBottom };
      }
      if (customerInfo?.type === 'CLIENT' && index === 8) {
        return { value: totalFunctionalCurrency, style: styleTotalBottom };
      }
      return { value: '' };
    });
    newListData.push(newTotalItem);
  }

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
