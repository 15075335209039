import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { DataTable, GenericInput } from '../common';

const tableCurrencyArOpsDataId = [
  {
    name: 'type',
    label: 'Type',
  },
  {
    name: 'currencyId',
    label: 'Currency',
  },
  {
    name: 'unitPrice',
    label: 'Unit Price',
  },
  {
    name: 'amount',
    label: 'Amount',
  },
];

const tableOpsTaxData = [
  {
    name: 'index',
    label: 'Index',
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'state',
    label: 'State',
  },
  {
    name: 'country',
    label: 'Country',
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
  },
  {
    name: 'code',
    label: 'Code',
  },
  {
    name: 'taxCode',
    label: 'Tax Code',
  },
  {
    name: 'jurisdiction',
    label: 'Jurisdiction',
  },
  {
    name: 'taxableAmount',
    label: 'Taxable Amount',
  },
  {
    name: 'exemptAmount',
    label: 'Exempt Amount',
  },
  {
    name: 'percent',
    label: 'Percent',
  },
  {
    name: 'taxAmount',
    label: 'Tax Amount',
  },
];

const dataFieldArOpsDataId = [
  {
    name: 'reason',
    label: 'Reason',
  },
  {
    name: 'taxCode',
    label: 'Tax Code',
  },
  {
    name: 'arCrDrType',
    label: 'Ar Cr Dr Type',
    type: 'select',
    tOptions: 'selections:arCrDrType',
  },
  {
    name: 'taxRule',
    label: 'Tax Rule',
    type: 'select',
    tOptions: 'selections:taxRule',
  },
  {
    name: 'itemId',
    label: 'Item Id',
  },
  {
    name: 'numberOfTransactions',
    label: 'Number Of Transactions',
  },
  {
    name: 'amount',
    label: 'Amount',
  },
  {
    name: 'percent',
    label: 'Percent',
    type: 'number',
  },
  // {
  //   name: 'appliedStart',
  //   label: 'Applied Start',
  // },
  // {
  //   name: 'appliedEnd',
  //   label: 'Applied End',
  // },
];

const heads = [
  'label.id',
  'label.endDate',
  'Amount',
  'Type',
  'Description',
  'Source',
  'Invoice Id',
  'Item Id',
  'AR CR Dr Type',
  'AR Tax Rule',
  'Reason',
  'View',
];
class AccountStatement extends Component {
  state = {
    isOpenModal: false,
    isSearchDataModal: false,
    itemData: {},
    activeTab: {
      // name: 'Recurring Data',
      // isActive: true,
    },
  };

  renderRow = row => {
    const { t } = this.props;
    return (
      <tr key={row.id}>
        <td>
          <button
            type="button"
            className="no-border btn-link"
            onClick={() => this.doGetArOpsUnitById(row.id)}
          >
            {row.id}
          </button>
        </td>
        <td>{row.endDate}</td>
        <td>{row.amount}</td>
        <td>{row.type}</td>
        <td>{row.description}</td>
        <td>{row.source}</td>
        <td>{row.invoiceId}</td>
        <td>{row.itemId}</td>
        <td>{row.arCrDrType}</td>
        <td>{row.arTaxRule}</td>
        <td>{row.reason}</td>
        <td>
          <button
            type="button"
            className="btn ml-0 btn-outline-success btn-sm"
            onClick={() => this.doGetArOpsUnitById(row.id)}
          >
            {t('label.view')}
          </button>
        </td>
      </tr>
    );
  };

  renderHeader = header => {
    const { t } = this.props;
    return (
      <th key={header} scope="col" className="w-25 p-3">
        {t(header)}
      </th>
    );
  };

  renderBody(data) {
    if (!data.length) {
      const { t } = this.props;
      return (
        <tbody>
          <tr>
            <td colSpan={12}>{t('message.noRecord')}</td>
          </tr>
        </tbody>
      );
    }
    return <tbody>{data.map(this.renderRow)}</tbody>;
  }

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  doGetArOpsUnitById = id => {
    const { getArOpsUnitById } = this.props;
    this.setState({ isOpenModal: true, isSearchDataModal: true });
    getArOpsUnitById(id, ({ data, success }) =>
      this.setState({
        itemData: success ? data : {},
        isSearchDataModal: false,
      }),
    );
  };

  renderItem = item => {
    const { t } = this.props;
    const { itemData } = this.state;
    switch (item.state) {
      case 'AR Ops Data':
        return (
          <div className="row col-md-12 p-2">
            {dataFieldArOpsDataId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.arData &&
                  itemData.arData[0] &&
                  itemData.arData[0][val.name]
                    ? itemData.arData[0][val.name]
                    : null
                }
                disabled
                readOnly
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Tax Data':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableOpsTaxData}
              data={itemData.taxData ? itemData.taxData : []}
            />
          </div>
        );
      case 'Currency':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableCurrencyArOpsDataId}
              data={itemData.balances ? itemData.balances : []}
            />
          </div>
        );

      default:
        return null;
    }
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const { data } = this.props;
    const { isSearchDataModal, itemData, isOpenModal, activeTab } = this.state;

    return (
      <Card>
        <table className="table table-hover">
          <thead>
            <tr>{heads.map(item => this.renderHeader(item))}</tr>
          </thead>
          {this.renderBody(data)}
        </table>
      </Card>
    );
  }
}

AccountStatement.propTypes = {
  data: PropTypes.array,
  // history: PropTypes.object,
};

export default withTranslation('common')(AccountStatement);
