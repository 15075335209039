import { createSelector } from 'reselect';

/**
 * Direct selector to the manageProfilePage state domain
 */

const selectViewAccountStatement = state => state.get('ViewAccountStatementReducer');

const makeGetArOpsUnitUnit = () =>
  createSelector(selectViewAccountStatement, ArOpsUnit => [...ArOpsUnit.get('arOpsUnit')]);

const makePageArOpsUnitParams = () =>
  createSelector(selectViewAccountStatement, customers => customers.get('paramsArOpsUnit').toJS());

const makeGetListAllAccountStatement = () =>
  createSelector(selectViewAccountStatement, items => {
    return items && items.get('allAccountStatement') ? items.get('allAccountStatement') : [];
  });

export { makeGetArOpsUnitUnit, makePageArOpsUnitParams, makeGetListAllAccountStatement };
