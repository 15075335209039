/*
 *
 * ViewInvoices constants
 *
 */

export const GET_TRANSACTION_UNIT =
  'app/ViewTransactionsPage/GET_TRANSACTION_UNIT';
export const GET_TRANSACTION_UNIT_SUCCESS =
  'app/ViewTransactionsPage/GET_TRANSACTION_UNIT_SUCCESS';

export const SET_TRANSACTION_PARAMS =
  'app/ViewTransactionsPage/SET_TRANSACTION_PARAMS';

export const GET_TRANSACTION_UNIT_BY_ID =
  'app/ViewTransactionsPage/GET_TRANSACTION_UNIT_BY_ID';
