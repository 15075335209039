import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import 'react-datepicker/dist/react-datepicker.css';
import { FaStar } from 'react-icons/fa';

import './index.scss';

class DateGroup extends React.PureComponent {
  state = { isOpenDatePicker: false };

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.value) return null;
    return {
      value: props.value ? moment(props.value).toDate() : null,
      oldValue: props.value,
    };
  }

  onHandleOpenDatePicker = () => {
    const { isOpenDatePicker } = this.state;
    if (!isOpenDatePicker) this.setState({ isOpenDatePicker: true });
  };

  onHandleCloseDatePicker = () => {
    const { isOpenDatePicker } = this.state;
    if (isOpenDatePicker) this.setState({ isOpenDatePicker: false });
  };

  handleChange = value => {
    this.setState({ value, isOpenDatePicker: false });
    const { onChange } = this.props;
    if (onChange) {
      if (value) {
        onChange(moment(value).format('YYYY-MM-DD'));
        return;
      }
      onChange(null);
    }
  };

  render() {
    const { isOpenDatePicker } = this.state;
    const { label, wrapperClass, disabled, ...restProps } = this.props;
    return (
      <div className={`form__form-group ${wrapperClass}`}>
        <Label>
          {label}
          {label && restProps.required && <FaStar className="fa fa-star is-required" />}
        </Label>
        <div className="form__form-group-field">
          <div className="date-picker" onClick={() => this.setState({ isOpenDatePicker: true })}>
            <DatePicker
              className="form__form-group-datepicker"
              open={isOpenDatePicker}
              id="from-date"
              onFocus={this.onHandleOpenDatePicker}
              onBlur={this.onHandleCloseDatePicker}
              onClickOutside={this.onHandleCloseDatePicker}
              selected={this.state.value}
              disabled={disabled}
              isClearable
              autoComplete="off"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                },
              }}
              {...restProps}
              onChange={this.handleChange}
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={20}
              scrollableYearDropdown
              dropdownMode="select"
            />
          </div>
          <div
            className="form__form-group-icon"
            onClick={() => {
              if (!isOpenDatePicker) this.setState({ isOpenDatePicker: true });
            }}
          >
            <CalendarBlankIcon />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

DateGroup.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.any,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
};

DateGroup.defaultProps = {
  label: '',
  placeholder: 'Select Date',
  wrapperClass: 'col-md-3',
};

export default DateGroup;
