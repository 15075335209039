import React from 'react';
import PropsType from 'prop-types';
import { SLIDE_UP } from 'react-ladda';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonCustom from './ButtonCustom';

class ModalAccept extends React.Component {
  onAccept = () => {
    const { onAcceptDelete, onToggle } = this.props;
    onToggle();
    onAcceptDelete();
  };

  render() {
    const { isOpen, onToggle, title } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={onToggle}>
        <ModalBody>{title}</ModalBody>
        <ModalFooter>
          <ButtonCustom
            className="btnback"
            type="button"
            title="Yes"
            titleloading="Modifying"
            datastyle={SLIDE_UP}
            onClick={this.onAccept}
          />
          <ButtonCustom
            className="btnmodify"
            type="button"
            title="No"
            titleloading="Modifying"
            datastyle={SLIDE_UP}
            onClick={onToggle}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

ModalAccept.propTypes = {
  onAcceptDelete: PropsType.func.isRequired,
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
};

ModalAccept.defaultProps = {
  title: '',
};

export default ModalAccept;
