import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

const TableInfo = ({ title, heads, children, subTilte, subDetail }) => {
  const { t } = useTranslation('common');
  return (
    <div className="table-info">
      {title && !subTilte && <div className="table-info__header ">{title}</div>}
      {subTilte && (
        <Row className="table-info__header mx-0 px-0">
          <Col md={12} className=" mx-0 ">
            <h3 className="font-weight-bold">
              {title} &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="font-weight-normal">{subTilte}</span>
              &nbsp;&nbsp;
              <span className="font-weight-normal">{subDetail}</span>
            </h3>
          </Col>
        </Row>
      )}

      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <table className="table table-hover">
              <thead>
                <tr>
                  {heads.map(item => (
                    <th key={item.key}>{t(item.name)}</th>
                  ))}
                </tr>
              </thead>
              {children}
            </table>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};
TableInfo.propTypes = {
  title: PropTypes.string,
  heads: PropTypes.array,
  children: PropTypes.any,
  subTilte: PropTypes.string,
  subDetail: PropTypes.string,
};
export default TableInfo;
