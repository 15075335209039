import { call, takeLatest, put } from 'redux-saga/effects';
import { queryRequest, mutationRequest } from '../../utils/request';

import { manageAccount, getAccountById } from '../../api';
import { resetNotification, setNotification } from '../Notification/actions';
import { isConnecting, isEndConnected } from '../Loader/actions';
import { NotificationTypes } from '../../constantsApp';

import * as types from './types';

// callback when create failed
export function* getAccountByIdSage({ payload, cb }) {
  if (!payload || !payload.id || payload.id === 'null') return;
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(queryRequest, getAccountById(payload.id));
    if (response.getAccountById) {
      yield put(isEndConnected());
      yield cb({ success: true, data: response.getAccountById });
    } else {
      yield put(isEndConnected());
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: 'get account detail failure!',
        })
      );
      yield cb({ success: false });
    }
  } catch (err) {
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `get account detail failure! ${err}`,
      })
    );
    yield put(isEndConnected());
    yield cb({ success: false });
  }
}
export function* manageAccountSage({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, manageAccount(payload));
    if (response.manageAccount) {
      yield put(isEndConnected());
      yield put(
        setNotification({
          type: NotificationTypes.success,
          message: 'modify Account successfully!',
        })
      );
      yield cb({ success: true, data: response.manageAccount });
    } else {
      yield put(isEndConnected());
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: 'modify Account failure!',
        })
      );
      yield cb({ success: false });
    }
  } catch (err) {
    yield put(isEndConnected());
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `modify Account failure! ${err}`,
      })
    );
    yield cb({ success: false });
  }
}
export default function* loginPageSaga() {
  yield takeLatest(types.GET_ACCOUNT_BY_ID, getAccountByIdSage);
  yield takeLatest(types.MANAGE_ACCOUNT, manageAccountSage);
}
