/* eslint-disable linebreak-style */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import fieldConstant from '../../containers/RegisterNew/constants';
import Cards from 'react-credit-cards';
import { Tooltip } from 'reactstrap';
import { ButtonCustom } from '../../components/common';
import { SLIDE_UP } from 'react-ladda';
import { urlGetCreditCard } from '../../constantsApp';
import { FaAngleLeft, FaAngleRight, FaEye, FaCheck } from 'react-icons/fa';
import {
  IoIosCheckmarkCircle,
  IoMdAddCircle,
  IoMdRemoveCircle,
} from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';

const dataDefaluPackage = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const dataTestBundle = [1, 2, 3, 4, 5];

class SelectPackage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isSearching: false,
      listPackage: [],
      tooltipOpen: '',
    };
  }

  componentDidMount() {
    this.doSearchOffers();
  }

  handlePage = i => {
    const { page } = this.state;
    this.setState({ page: page + i, listPackage: [] }, () =>
      this.doSearchOffers(),
    );
  };

  doSearchOffers = () => {
    const { getSelfCareOffers, data, searchPackage } = this.props;
    const { page } = this.state;
    this.setState({ isSearching: true });
    searchPackage({ page }, res => {
      if (res.success) {
        this.setState({ listPackage: res.data, isSearching: false });
      }
    });
    // getSelfCareOffers({ page, userId: data.userId ? data.userId : '' }, res => {
    //   if (res.success) {
    //     this.setState({ listPackage: res.data.packages, isSearching: false });
    //   }
    // });
  };

  toggle = packageIdHover => {
    this.setState({
      tooltipOpen:
        this.state.tooltipOpen === packageIdHover ? '' : packageIdHover,
    });
  };

  render() {
    const {
      data,
      onChange,
      onSelectPackage,
      packageNameSelected,
      t,
    } = this.props;
    const { listPackage, page, isSearching, tooltipOpen } = this.state;

    return (
      <div className="col-md-12 no-padding select-package-from">
        <div className="col-md-12 no-padding d-flex form-input mt-3">
          <div className="no-padding arrow-handle-page">
            <button
              className="icon-handle-page no-border-focus"
              type="button"
              disabled={page === 1}
              onClick={() => this.handlePage(-1)}
            >
              <span className="icon-arrow">
                <FaAngleLeft />
              </span>
            </button>
          </div>
          <div className="col-md-11 row package-container">
            {dataDefaluPackage.map(item => {
              if (!isSearching && !listPackage[item]) return '';
              return (
                <div
                  key={item}
                  className={`col-md-4 row group-item-package ${
                    listPackage[item] && listPackage[item].id === data.packageId
                      ? 'item-acitve'
                      : ''
                  }`}
                >
                  <div
                    className="col-md-10 item-package"
                    onClick={() =>
                      onSelectPackage(
                        listPackage[item].id,
                        listPackage[item].name,
                      )
                    }
                  >
                    {listPackage[item] ? (
                      <div className="package-name mt-2">
                        {listPackage[item].name}
                        {listPackage[item].name === packageNameSelected && (
                          <FaCheck
                            color="#1e7e34"
                            style={{ marginLeft: 5, marginBottom: 5 }}
                          />
                        )}
                      </div>
                    ) : (
                      <Skeleton height={50} duration={1} />
                    )}

                    {/* <div className="description-bundle">
                      {listPackage[item] ? (
                        `Description: ${listPackage[item].description}`
                      ) : (
                        <Skeleton height={13} duration={1} />
                      )}
                    </div> */}
                  </div>
                  <button
                    className="col-md-2 no-padding group-handle-description no-border-focus"
                    type="button"
                    id={`view-description-${item}`}
                    onClick={
                      () => null
                      // onSelectPackage(listPackage[item].id)
                    }
                  >
                    {/* {listPackage[item] &&
                    listPackage[item].id === data.packageId ? (
                        <div className="icon-show-description add">
                          <IoMdRemoveCircle color="#FE0123" />
                        </div>
                      ) : (
                        <div className="icon-show-description remove">
                          <IoMdAddCircle />
                        </div>
                      )} */}
                    <div className="icon-show-description">
                      <FaEye />
                    </div>
                    <Tooltip
                      placement="top"
                      style={{ background: '#0f9d20de' }}
                      isOpen={
                        listPackage[item] &&
                        tooltipOpen === listPackage[item].id
                      }
                      autohide={false}
                      target={`view-description-${item}`}
                      toggle={() =>
                        this.toggle(
                          listPackage[item] && listPackage[item].id
                            ? listPackage[item].id
                            : '',
                        )
                      }
                    >
                      {listPackage[item] && listPackage[item].description}
                    </Tooltip>
                  </button>
                </div>
              );
            })}
          </div>
          <div className="no-padding arrow-handle-page">
            <button
              className="icon-handle-page no-border-focus"
              type="button"
              disabled={listPackage.length < 9}
              onClick={() => this.handlePage(1)}
            >
              <span className="icon-arrow">
                <FaAngleRight />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-12 bundle-container">
          <h1 className="title-bunlde-selected">
            {t('label.packageSelected')}
          </h1>
          <div className="col-md-12 row group-bundle-selected">
            <div className="col-md-12 row">
              {/* {data &&
                data.bundles &&
                data.bundles.map(item => (
                  <div className="col-md-3" key={item.bundleId}>
                    <span className="ic-remove-bundle">
                      <IoIosCheckmarkCircle color="#09c083" />
                    </span>
                    {item.bundleId}
                  </div>
                ))} */}
              {packageNameSelected && (
                <div className="col-md-3">
                  <span className="ic-remove-bundle">
                    <IoIosCheckmarkCircle color="#09c083" />
                  </span>
                  {packageNameSelected}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectPackage.propTypes = {
  data: PropTypes.object,
  stateCallBackFromURL: PropTypes.object,
  onChange: PropTypes.func,
  focusCard: PropTypes.string,
};

export default withTranslation('common')(SelectPackage);
