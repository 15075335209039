/*
 *
 * DashBoardPage reducer
 *
 */

import { fromJS } from 'immutable';
import * as types from './constants';

export const initialState = fromJS({
  accountInformation: null,
});

function dashBoardPageReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ACCOUNT_INFORMATION_SUCCESS:
      return state.set('accountInformation', action.payload);

    default:
      return state;
  }
}

export default dashBoardPageReducer;
