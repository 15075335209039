import { call, put, takeLatest } from 'redux-saga/effects';
import { queryRequest } from '../../utils/request';
import { searchBillUnits, searchBillInvoice } from '../../api';
import { resetNotification, setNotification } from '../Notification/actions';
import { NotificationTypes } from '../../constantsApp';
import * as types from './constants';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as actions from './actions';

export function* getPendingBillSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchBillUnits({ page, size, filter, sort }));
    if (response.searchBillUnits) {
      yield put(actions.getPendingBillSuccess(response.searchBillUnits));
    } else {
      if (cb) {
        cb({ success: false });
      }
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: 'Search Pending Bills failed ',
        })
      );
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `Search Pending Bills failed ${err}`,
      })
    );
    yield put(isEndConnected());
  }
}

export function* getOCBillSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchBillInvoice({ page, size, filter, sort }));
    if (response.searchBillInvoice) {
      yield put(actions.getOCBillSuccess(response.searchBillInvoice));
      if (cb) {
        cb({ success: true, data: response.searchBillInvoice });
      }
    } else {
      if (cb) {
        cb({ success: false, data: null });
      }
      // yield put(
      //   setNotification({
      //     type: NotificationTypes.error,
      //     message: 'Search Open/Close Bills failed ',
      //   })
      // );
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false, data: null  });
    }
    // yield put(
    //   setNotification({
    //     type: NotificationTypes.error,
    //     message: 'Search Open/Close Bills failed',
    //   })
    // );
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* viewInvoicesPageSaga() {
  yield takeLatest(types.GET_PENDING_BILL, getPendingBillSaga);
  yield takeLatest(types.GET_OC_BILL, getOCBillSaga);
}
