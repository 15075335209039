/*
 *
 * ViewInvoices constants
 *
 */

export const GET_COLLECTION_UNIT = 'GET_COLLECTION_UNIT';
export const GET_COLLECTION_UNIT_SUCCESS = 'GET_COLLECTION_UNIT_SUCCESS';
export const SET_COLLECTION_PARAMS = 'SET_COLLECTION_PARAMS';
export const GET_COLLECTION_UNIT_BY_ID = 'GET_COLLECTION_UNIT_BY_ID';
