/*
 *
 * DashBoardPage actions
 *
 */
import { HANDLE_CHANGE_PASSWORD, GET_ACCOUNT_INFORMATION, GET_ACCOUNT_INFORMATION_SUCCESS } from './constants';
// change password first time
export function handleChangePassword(newPassword, cb) {
  return {
    type: HANDLE_CHANGE_PASSWORD,
    newPassword,
    cb,
  };
}

// for get Account Information
export function getAccountInformation(payload, cb) {
  return {
    type: GET_ACCOUNT_INFORMATION,
    payload,
    cb,
  };
}

export function getAccountInformationSuccess(payload, cb) {
  return {
    type: GET_ACCOUNT_INFORMATION_SUCCESS,
    payload,
    cb,
  };
}
