import gql from 'graphql-tag';

export default id => gql`
  query {
    getObjectFileById(input: "${id}") {
      objectId
      fileType
      path
    }
  }
`;
