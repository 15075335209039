import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../components/common/SearchComponent';

class BroadsoftZeroDurationCdrsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.switchName',
      name: 'switchName',
      type: 'select',
      tOptions: 'selections:switchNameRawSmsFile',
      isClearable: true,
      defaultValue: 'SMS',
      required: true,
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'datetime',
      required: true,
      defaultValue: moment(). startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'datetime',
      required: true,
      defaultValue: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.client',
      name: 'client',
    },
    {
      label: 'label.aPartyNumber',
      name: 'aPartyNumber',
    },
    {
      label: 'label.bPartyNumber',
      name: 'bPartyNumber',
    },
    {
      label: 'label.aPartyNumberStartRange',
      name: 'aPartyNumberStartRange',
    },
    {
      label: 'label.aPartyNumberEndRange',
      name: 'aPartyNumberEndRange',
    },
    {
      label: 'label.bPartyNumberStartRange',
      name: 'bPartyNumberStartRange',
    },
    {
      label: 'label.bPartyNumberEndRange',
      name: 'bPartyNumberEndRange',
    },
    {
      label: 'label.messageDeliveryStatus',
      name: 'messageDeliveryStatus',
    },
    {
      label: 'label.networkId',
      name: 'networkId',
    },
    {
      label: 'label.origNetworkId',
      name: 'origNetworkId',
    },
  ];

  btnSpan = 'col-md-9';
}

BroadsoftZeroDurationCdrsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BroadsoftZeroDurationCdrsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BroadsoftZeroDurationCdrsSearchForm);
