export const GET_SERVICE_UNITS_AC = 'app/customers/get_service_units_ac';
export const GET_SERVICE_UNITS_AC_SUCCESS =
  'app/customers/get_service_units_ac_success';
export const GET_SERVICE_UNITS_AC_FAILURE =
  'app/customers/get_service_units_ac_failure';

export const GET_SUBSCRIPTION = 'app/customers/get_subscription';
export const GET_SUBSCRIPTION_SUCCESS =
  'app/customers/get_subscription_success';
export const GET_SUBSCRIPTION_FAILURE =
  'app/customers/get_subscription_failure';
