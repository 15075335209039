import { call, put, takeLatest } from 'redux-saga/effects';
import { queryRequest } from '../../utils/request';
// import { push } from 'react-router-redux';
import { getCollectionUnitsById, searchCollectionHistory } from '../../api';
import { resetNotification, setNotification } from '../Notification/actions';
import { NotificationTypes } from '../../constantsApp';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './constants';
import * as actions from './actions';

export function* searchCollectionHistorySaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchCollectionHistory({ page, size, filter, sort }));
    yield put(actions.getCollectionUnitSuccess(response.searchCollectionHistory));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchCollectionHistory });
  } catch (err) {
    yield put(isEndConnected());
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `Search Collection failed ${err}`,
      })
    );
    if (cb) cb({ success: false, data: null });
  }
}

export function* getCollectionUnitByIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getCollectionUnitsById(payload));
    if (response.getCollectionUnitsById) {
      if (cb) cb({ success: true, data: response.getCollectionUnitsById });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: null });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* viewTransactionsPageSaga() {
  yield takeLatest(types.GET_COLLECTION_UNIT, searchCollectionHistorySaga);
  yield takeLatest(types.GET_COLLECTION_UNIT_BY_ID, getCollectionUnitByIdSaga);
}
