/*
 *
 * ManageProfilePage actions
 *
 */

import * as types from './constants';
// for get COLLECTION unit
export function getCollectionUnit(payload, cb) {
  return {
    type: types.GET_COLLECTION_UNIT,
    payload,
    cb,
  };
}

export function getCollectionUnitSuccess(payload) {
  return {
    type: types.GET_COLLECTION_UNIT_SUCCESS,
    payload,
  };
}

export function setCollectionParams(payload) {
  return {
    type: types.SET_COLLECTION_PARAMS,
    payload,
  };
}

export function getCollectionUnitById(payload, cb) {
  return {
    type: types.GET_COLLECTION_UNIT_BY_ID,
    payload,
    cb,
  };
}
