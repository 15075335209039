/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import fieldConstant from '../../containers/RegisterNew/constants';
import Cards from 'react-credit-cards';
import { ButtonCustom } from '../../components/common';
import { SLIDE_UP } from 'react-ladda';
import { urlGetCreditCard } from '../../constantsApp';
import RequirIcon from '../common/RequirIcon';

const CredidCardDetails = ({
  data,
  onChange,
  focusCard,
  stateCallBackFromURL,
  listValidateStage1,
}) => {
  const { creditCard } = data;
  const { t } = useTranslation('common');
  return (
    <div className="col-md-12 d-flex flex-wrap form-input no-padding">
      <div className="col-md-6 no-padding">
        {fieldConstant.creditCardDetailForm.map(item => {
          if (item.name === 'cardToken') {
            return (
              <Col md={12} key={item.name}>
                <FormGroup>
                  <Label for={item.name}>
                    {t(item.label)}
                    {item.required && <RequirIcon />}:
                  </Label>
                  <div className="row">
                    <Input
                      type="text"
                      name={item.name}
                      id={item.name}
                      // className="col-md-7"
                      className={
                        listValidateStage1.indexOf(item.name) > -1
                          ? 'col-md-7 isValid'
                          : 'col-md-7'
                      }
                      value={creditCard[item.name] || ''}
                      onChange={evt =>
                        onChange({
                          name: evt.target.name,
                          value: evt.target.value,
                          subFieldName: 'creditCard',
                        })
                      }
                    />
                    <div className="col-md-5">
                      <ButtonCustom
                        className="btn-submit pl-2"
                        type="button"
                        style={{ paddingLeft: 3 }}
                        title={t('label.addCreditCard')}
                        titleloading="Modifying"
                        datastyle={SLIDE_UP}
                        onClick={() => {
                          localStorage.setItem(
                            'stateCallback',
                            JSON.stringify(stateCallBackFromURL),
                          );
                          window.open(
                            `${urlGetCreditCard.URL}${window.location}`,
                            '_self',
                          );
                        }}
                      />
                    </div>
                  </div>
                </FormGroup>
              </Col>
            );
          }
          return (
            <Col md={12} key={item.name}>
              <FormGroup>
                <Label for={item.name}>
                  {t(item.label)} {item.required && <RequirIcon />}:
                </Label>
                <Input
                  type="text"
                  name={item.name}
                  id={item.name}
                  className={
                    listValidateStage1.indexOf(item.name) > -1 ? 'isValid' : ''
                  }
                  value={creditCard[item.name] || ''}
                  onChange={evt =>
                    onChange({
                      name: evt.target.name,
                      value: evt.target.value,
                      subFieldName: 'creditCard',
                    })
                  }
                />
              </FormGroup>
            </Col>
          );
        })}
      </div>
      <div className="col-md-6 mt-4">
        <Cards
          cvc={creditCard.last4CC || ''}
          expiry={creditCard.cardExpiry || ''}
          focused={focusCard}
          name={`Token: ${creditCard.cardToken || ''}`}
          number=""
        />
      </div>
    </div>
  );
};

CredidCardDetails.propTypes = {
  data: PropTypes.object,
  stateCallBackFromURL: PropTypes.object,
  onChange: PropTypes.func,
  focusCard: PropTypes.string,
};

export default CredidCardDetails;
